import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    List,
    ListItem,
    ListItemText,
    withStyles,
    Card,
    CardContent
} from '@material-ui/core';
import {
    DatosGenerales,
    Facturacion,
    ProductosSinDimensiones,
    DimensionesExcesivas,
    ObtenerDominios,
    DesactivarMedioEnvio,
    RecuperarCategorias
} from './';
import {
    ToggleUI
} from '../../common'

class FilterSwitch_ extends Component
{
    
    render()
    {
        let { id_tienda, filtro_tienda, classes, isLoading } = this.props;
        return (
            <Fragment>
                <ToggleUI show={id_tienda==='' || filtro_tienda===-1}>
                    <Card className={classes.card}>
                        <CardContent className={classes.card_content_no_padding}>
                            <List>
                                <ListItem>
                                    <ListItemText 
                                        primary="No se han encontrado datos"
                                        secondary="Por favor, ingrese un ID Tienda y un filtro"
                                    />
                                </ListItem> 
                            </List>
                        </CardContent>
                    </Card>
                </ToggleUI>
                <ToggleUI show={id_tienda!=='' && filtro_tienda===0}>
                    <DatosGenerales id_tienda={id_tienda} isLoading={isLoading}/>
                </ToggleUI>
                <ToggleUI show={id_tienda!=='' && filtro_tienda===1}>
                    <Facturacion id_tienda={id_tienda} isLoading={isLoading}/>
                </ToggleUI>
                <ToggleUI show={id_tienda!=='' && filtro_tienda===2}>
                    <ProductosSinDimensiones id_tienda={id_tienda} isLoading={isLoading}/>
                </ToggleUI>
                <ToggleUI show={id_tienda!=='' && filtro_tienda===3}>
                    <DimensionesExcesivas id_tienda={id_tienda} isLoading={isLoading}/>
                </ToggleUI>
                <ToggleUI show={id_tienda!=='' && filtro_tienda===4}>
                    <ObtenerDominios id_tienda={id_tienda} isLoading={isLoading}/>
                </ToggleUI>
                <ToggleUI show={id_tienda!=='' && filtro_tienda===5}>
                    <DesactivarMedioEnvio id_tienda={id_tienda} isLoading={isLoading}/>
                </ToggleUI>
                <ToggleUI show={id_tienda!=='' && filtro_tienda===6}>
                    <RecuperarCategorias id_tienda={id_tienda} isLoading={isLoading}/>
                </ToggleUI>
            </Fragment>
        );
    }
}

const styles = theme => ({
    span: {
        display: 'block',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    list: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '0',
        '&:last-child': {
            borderBottom: '0px solid transparent'
        }
    },
    list_text: {
        paddingRight: '100px'
    },
    card_content_no_padding: {
        ...theme.card.card_content,
        padding: '0px !important',
        overflowX: 'auto'
    }
});

const mapStateToProps = null;

const mapDispatchToProps = null;

FilterSwitch_.propTypes = {
    
    id_tienda: PropTypes.string.isRequired,

    isLoading: PropTypes.func.isRequired,

    filtro_tienda: PropTypes.number.isRequired

};

export const FilterSwitch = withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(FilterSwitch_));

