import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { 
    Grid,
    Icon,
    LinearProgress,
    withStyles
 } from '@material-ui/core';
import { 
    PrivateLayout, 
    AlertUI, 
    ModuleTitleUI,
    ButtonUI,
    ToggleUI,
    InputUI,
    SelectUI
} from '../common';
import { 
    TiendasList
} from './subcomponents';
import { Validate, Regex, RegexExtra } from '../../other';
import { buscarUltimasTiendas } from '../../store/actions';
 
class AuditarTiendas_ extends Component 
{
    state={
        filter: {
            search: {
                auth: false,
                disabled: false,
                validate: true,
                required: true,
                error: false,
                type: 'text',
                label: 'Buscar',
                id: 'search',
                name: 'search',
                change_param: 'filter',
                regex: Regex.STRING_GENERICO_2_45,
                value: '',
                default_value: '',
                placeholder: 'Buscar...',
                messages: {
                    error: 'Por favor, ingrese datos válidos',
                    error_extra: RegexExtra.STRING_GENERICO_2_45
                }
            },
            t_activa: {
                validate: false,
                required: true,
                error: false,
                label: 'Filtrar',
                id: 't_activa',
                name: 't_activa',
                change_param: 'filter',
                value: -1,
                messages: {
                    error: ''
                },
            },
            page: 0
        },
        load_more: {
            loading: false,
            show: false
        },
        selects: {
            t_activa: [
                {
                    value: -1,
                    label: 'Todas'
                },
                {
                    value: 0,
                    label: 'Bloqueadas'
                },
                {
                    value: 1,
                    label: 'Activas'
                }
            ]
        },
        loading: {
            init_tiendas: true,
            tiendas: true,
            error: false
        },
        alert: {
            open: false,
            type: 'error',
            message: ''
        }
    };

    debounceSearch = null;

    obtenerParametros = () => {
        let filter = this.state.filter;
        let body = { 
            page: this.state.filter.page
        };

        if(filter.t_activa.value!==-1) body = {...body, t_activa: filter.t_activa.value};
        if(filter.search.value!=="") body = {...body, search: filter.search.value};

        return body;
    }

    componentDidMount()
    {
        this.debounceMethod = debounce(this.cargarTiendas, 200);

        this.cargarTiendas();
    }

    cargarTiendas = () => {

        this.setState({
            loading: {
                ...this.state.loading,
                tiendas: true
            },
            load_more: {
                loading: this.state.filter.page>0 ? true : false,
                show: this.state.filter.page>0 ? true : false,
            }
        });

        this.props.buscarUltimasTiendas(this.obtenerParametros(),(err,resp) => {

            let cantidad_resultados = resp.data.length;

            this.setState({
                loading: {
                    ...this.state.loading,
                    init_tiendas: false,
                    tiendas: false,
                    error: (this.state.loading.error || err) ? true : false
                },
                load_more: {
                    loading: false,
                    show: cantidad_resultados===25
                },
                filter: {
                    ...this.state.filter,
                    page: cantidad_resultados===25 ? (this.state.filter.page+1) : 0
                }
            });
        });

    }

    handleChange = (e,blur,aditional) => {

        if(!blur)
        {
            let value = e.target.value;
            let name = e.target.name;
    
            this.setState({
                [aditional]: {
                    ...this.state[aditional],
                    [name]: {
                        ...this.state[aditional][name],
                        value: value
                    },
                    page: 0
                }
            },() => this.debounceMethod());
        }

    }
    
    handleChangeSelect = (e,aditional) => {

        let value = Validate.trim_int(e.target.value);
        let name = e.target.name;

        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                },
                page: 0
            },
        }, this.debounceMethod());

    }

    //cierra las alertas
    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }

    render() 
    {
        const { classes } = this.props;

        return (
            <PrivateLayout 
                error={this.state.loading.error} 
                url={this.props.match.url}
            >
                <AlertUI
                    open={this.state.alert.open}
                    message={this.state.alert.message}
                    type={this.state.alert.type}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <ModuleTitleUI 
                    title="Auditoría de tiendas"
                    subtitles={[
                        'Aquí podrás auditar las tiendas'
                    ]}
                />
                <div style={{ paddingBottom: 12 }}>
                    <Grid container alignItems="center" spacing={24}>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputUI
                                handleChange={this.handleChange}
                                input={this.state.filter.search}
                            />
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <SelectUI
                                data={this.state.filter.t_activa}
                                options={this.state.selects.t_activa}
                                handleChangeSelect={this.handleChangeSelect}
                            />
                        </Grid>
                    </Grid>
                </div> 
                <div className={classes.tiendas_box}>
                    {
                        (this.state.filter.page===0 && this.state.loading.tiendas) ? (
                            <LinearProgress/>
                        ) : (
                            <TiendasList modalHandler={this.modalHandler}/>
                        )
                    } 
                </div>
                <ToggleUI show={this.state.load_more.show}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <ButtonUI
                                type="callf"
                                label={<div className={classes.icon_center}>VER MÁS <Icon className={classes.icon_badge_show_more}>keyboard_arrow_down</Icon></div>}
                                fullWidth={false}
                                onClickFunc={() => this.cargarTiendas()}
                                isLoading={this.state.load_more.loading}
                            />
                        </Grid>
                    </Grid>
                </ToggleUI>
            </PrivateLayout>
        );
    }
}

const styles = theme => ({
    tiendas_box: {
        margin: '30px 0px'
    },
    icon_center: {
        display: 'flex',
        alignItems: 'center',
        padding: '3px 0px'
    },
    icon_badge_show_more: {
        margin: '0',
        padding: '0'
    },
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
    return {
        buscarUltimasTiendas: (data,callback) => dispatch(buscarUltimasTiendas(data,callback)),
    }
}

// AuditarTiendas_.propTypes = {

// };

export const AuditarTiendas = withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(AuditarTiendas_));
