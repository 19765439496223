import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { ModalLiteUI,ToggleUI, ModuleCardTitleUI } from '../../../../../common';
import { Validate } from '../../../../../../other';

const ModalDestino = (props) => {

    let { 
        ver_destino,
        modalHandler,
        modal_state
    } = props;

    const ShippingWrapper = ({children}) => {
        return (
            <div style={{backgroundColor: '#f5f5f5', padding: '10px', margin: '10px 0px'}}>
                {children}
            </div>
        );
    }

    const sucursal_destino = (ver_destino) => {
        if(ver_destino.offices && ver_destino.offices.length>0)
        {
            return ver_destino.offices.filter( offices => {

                if(offices.office_type === 1)
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }).map((office, index) => {
                return(
                    <List key={index}>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Nombre: '}</strong>{Validate.trim_ucwords(office.office_name)}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Calle: '}</strong>{Validate.trim_ucwords(office.office_street)}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Número: '}</strong>{Validate.trim_ucwords(office.office_street_number)}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Piso: '}</strong>{(office.office_floor ? Validate.trim_ucwords(office.office_floor) : '-')}<strong>{' Dpto: '}</strong>{(office.office_apartment ? Validate.trim_ucwords(office.office_apartment) : '-')}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Ciudad: '}</strong>{Validate.trim_ucwords(office.office_city)}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Provincia: '}</strong>{Validate.trim_ucwords(office.office_state)}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Código postal: '}</strong>{office.office_zipcode}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'País: '}</strong>{(office.office_country ? Validate.trim_ucwords(office.office_country) : 'Argentina')}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Teléfono: '}</strong>{office.office_phone}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Horario: '}</strong>{office.office_worktime}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Latitud: '}</strong>{office.office_latitude}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Longitud: '}</strong>{office.office_longitude}</span>}/>
                        </ListItem>
                    </List>
                );
            });
        }
        else
        {
            return ;
        }
    }
    
    return (
        <ModalLiteUI 
            open={modal_state}
            title="Información de destino"
            id="ver_destino"
            modalHandler={modalHandler}
            aditional_param="ver_destino"
        >
            <ModuleCardTitleUI
                title="Domicilio"
            />
            <ShippingWrapper>
                <List>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Nombre: '}</strong>{Validate.trim_ucwords(ver_destino.shipment_to_name)}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Apellido: '}</strong>{Validate.trim_ucwords(ver_destino.shipment_to_surname)}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Email: '}</strong>{ver_destino.shipment_to_email}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Teléfono: '}</strong>{(ver_destino.shipment_to_phone ? ver_destino.shipment_to_phone : '-')}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{ver_destino.shipment_to_identification_type+': '}</strong>{ver_destino.shipment_to_identification}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Calle: '}</strong>{Validate.trim_ucwords(ver_destino.shipment_to_street)}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Número: '}</strong>{(ver_destino.shipment_to_street_number ? Validate.trim_ucwords(ver_destino.shipment_to_street_number) : '-')}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Piso: '}</strong>{(ver_destino.shipment_to_floor ? Validate.trim_ucwords(ver_destino.shipment_to_floor) : '-')}<strong>{' Dpto: '}</strong>{(ver_destino.shipment_to_apartment ? Validate.trim_ucwords(ver_destino.shipment_to_apartment) : '-')}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Ciudad: '}</strong>{(ver_destino.shipment_to_city ? Validate.trim_ucwords(ver_destino.shipment_to_city) : '-')}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Provincia: '}</strong>{(ver_destino.shipment_to_state ? Validate.trim_ucwords(ver_destino.shipment_to_state) : '-')}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Código postal: '}</strong>{ver_destino.shipment_to_zipcode}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'País: '}</strong>{(ver_destino.shipment_to_country ? Validate.trim_ucwords(ver_destino.shipment_to_country) : 'Argentina')}</span>}/>
                    </ListItem>
                </List>
            </ShippingWrapper>

            <ToggleUI show={ver_destino.shipment_submethod===3}>
                <ModuleCardTitleUI
                    title="Sucursal de destino"
                />
                <ShippingWrapper>
                    {sucursal_destino(ver_destino)} 
                </ShippingWrapper>
            </ToggleUI>
        </ModalLiteUI>
    );
}

ModalDestino.propTypes = {
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    //true if the modal is open
    modal_state: PropTypes.bool.isRequired,

    //The object initialized in state 
    ver_destino: PropTypes.shape({
        idShipment:                             PropTypes.number.isRequired,
        shipment_submethod:                     PropTypes.number.isRequired,
        shipment_to_name:                       PropTypes.string.isRequired, 
        shipment_to_surname:                    PropTypes.string.isRequired,
        shipment_to_email:                      PropTypes.string.isRequired,
        shipment_to_identification:             PropTypes.string.isRequired,
        shipment_to_identification_type:        PropTypes.string.isRequired, 
        shipment_to_phone:                      PropTypes.string.isRequired,
        shipment_to_street:                     PropTypes.string.isRequired,
        shipment_to_street_number:              PropTypes.string.isRequired,
        shipment_to_apartment:                  PropTypes.string.isRequired,
        shipment_to_city:                       PropTypes.string.isRequired,
        shipment_to_state:                      PropTypes.string.isRequired,
        shipment_to_zipcode:                    PropTypes.string.isRequired,
        shipment_to_country:                    PropTypes.string.isRequired,
        offices:                                PropTypes.array.isRequired,
    })
};

export { ModalDestino };
