import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { ModalLiteUI,ToggleUI, ModuleCardTitleUI } from '../../../../../common';
import { Validate } from '../../../../../../other';

const ModalOrigen = (props) => {

    let { 
        ver_origen,
        modalHandler,
        modal_state
    } = props;

    const ShippingWrapper = ({children}) => {
        return (
            <div style={{backgroundColor: '#f5f5f5', padding: '10px', margin: '10px 0px'}}>
                {children}
            </div>
        );
    }

    const sucursal_origen = (ver_origen) => {
        if(ver_origen.offices && ver_origen.offices.length>0)
        {
            return ver_origen.offices.filter( offices => {

                if(offices.office_type === 0)
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }).map((office, index) => {
                return(
                    <List key={index}>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Nombre: '}</strong>{Validate.trim_ucwords(office.office_name)}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Calle: '}</strong>{Validate.trim_ucwords(office.office_street)}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Número: '}</strong>{Validate.trim_ucwords(office.office_street_number)}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Piso: '}</strong>{(office.office_floor ? Validate.trim_ucwords(office.office_floor) : '-')}<strong>{' Dpto: '}</strong>{(office.office_apartment ? Validate.trim_ucwords(office.office_apartment) : '-')}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Ciudad: '}</strong>{Validate.trim_ucwords(office.office_city)}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Provincia: '}</strong>{Validate.trim_ucwords(office.office_state)}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Código postal: '}</strong>{office.office_zipcode}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'País: '}</strong>{(office.office_country ? Validate.trim_ucwords(office.office_country) : 'Argentina')}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Teléfono: '}</strong>{office.office_phone}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Horario: '}</strong>{office.office_worktime}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Latitud: '}</strong>{office.office_latitude}</span>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<span><strong>{'Longitud: '}</strong>{office.office_longitude}</span>}/>
                        </ListItem>
                    </List>
                );
            });
        }
        else
        {
            return ;
        }
    }
    
    return (
        <ModalLiteUI 
            open={modal_state}
            title="Información de origen"
            id="ver_origen"
            modalHandler={modalHandler}
            aditional_param="ver_origen"
        >
            <ModuleCardTitleUI
                title="Domicilio"
            />
            <ShippingWrapper>
                <List>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Nombre: '}</strong>{Validate.trim_ucwords(ver_origen.shipment_from_name)}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Apellido: '}</strong>{Validate.trim_ucwords(ver_origen.shipment_from_surname)}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Email: '}</strong>{ver_origen.shipment_from_email}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Teléfono: '}</strong>{(ver_origen.shipment_from_phone ? ver_origen.shipment_from_phone : '-')}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{ver_origen.shipment_from_identification_type+': '}</strong>{ver_origen.shipment_from_identification}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Calle: '}</strong>{Validate.trim_ucwords(ver_origen.shipment_from_street)}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Número: '}</strong>{(ver_origen.shipment_from_street_number ? Validate.trim_ucwords(ver_origen.shipment_from_street_number) : '-')}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Piso: '}</strong>{(ver_origen.shipment_from_floor ? Validate.trim_ucwords(ver_origen.shipment_from_floor) : '-')}<strong>{' Dpto: '}</strong>{(ver_origen.shipment_from_apartment ? Validate.trim_ucwords(ver_origen.shipment_from_apartment) : '-')}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Ciudad: '}</strong>{(ver_origen.shipment_from_city ? Validate.trim_ucwords(ver_origen.shipment_from_city) : '-')}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Provincia: '}</strong>{(ver_origen.shipment_from_state ? Validate.trim_ucwords(ver_origen.shipment_from_state) : '-')}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Código postal: '}</strong>{ver_origen.shipment_from_zipcode}</span>}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'País: '}</strong>{(ver_origen.shipment_from_country ? Validate.trim_ucwords(ver_origen.shipment_from_country) : 'Argentina')}</span>}/>
                    </ListItem>
                </List>
            </ShippingWrapper>

            <ToggleUI show={ver_origen.shipment_submethod===3 || ver_origen.shipment_submethod===4}>
                <ModuleCardTitleUI
                    title="Sucursal de origen"
                />
                <ShippingWrapper>
                    {sucursal_origen(ver_origen)} 
                </ShippingWrapper>
            </ToggleUI>
        </ModalLiteUI>
    );
}

ModalOrigen.propTypes = {
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    //true if the modal is open
    modal_state: PropTypes.bool.isRequired,

    //The object initialized in state 
    ver_origen: PropTypes.shape({
        idShipment:                             PropTypes.number.isRequired,
        shipment_submethod:                     PropTypes.number.isRequired,
        shipment_from_name:                     PropTypes.string.isRequired, 
        shipment_from_surname:                  PropTypes.string.isRequired,
        shipment_from_email:                    PropTypes.string.isRequired,
        shipment_from_identification:           PropTypes.string.isRequired,
        shipment_from_identification_type:      PropTypes.string.isRequired, 
        shipment_from_phone:                    PropTypes.string.isRequired,
        shipment_from_street:                   PropTypes.string.isRequired,
        shipment_from_street_number:            PropTypes.string.isRequired,
        shipment_from_apartment:                PropTypes.string.isRequired,
        shipment_from_city:                     PropTypes.string.isRequired,
        shipment_from_state:                    PropTypes.string.isRequired,
        shipment_from_zipcode:                  PropTypes.string.isRequired,
        shipment_from_country:                  PropTypes.string.isRequired,
        offices:                                PropTypes.array.isRequired,
    })

}

export { ModalOrigen };
