import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    withStyles,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid
} from '@material-ui/core';
import { recuperarCategoriasEliminadas } from '../../../store/actions';
import { ButtonUI, AlertUI } from '../../common';

class RecuperarCategorias_ extends Component
{   
    state={
        id_tienda: 0,
        alert: {
            open: false,
            type: 'error',
            message: ''
        },
        button_loading: false
    };

    componentDidMount() 
    {
        this.handleChangeId();
    }

    componentDidUpdate() 
    {
        this.handleChangeId();
    }

    handleChangeId = () => {
        if(this.state.id_tienda !== this.props.id_tienda)
        {
            this.setState({
                id_tienda: this.props.id_tienda,
                loading: {
                    ...this.state.loading,
                    tienda: true,
                    error: false
                }
            });
        }
    }

    handleSubmitRecuperarCategorias = () => {
        
        this.setState({
            button_loading: true
        });

        this.props.recuperarCategoriasEliminadas({
            xIdTienda: this.state.id_tienda
        }, (err,resp) => {

            this.setState({
                button_loading: false,
                alert: {
                    open: true,
                    message: resp.message,
                    type: err ? 'error' : 'success'
                }
            });

        });

    }

    //cierra las alertas
    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }   

    render()
    {
        let {classes} = this.props;
        
        return (
            <Card className={classes.card}>
                <CardHeader
                    title="Recuperar categorías eliminadas"
                    className={classes.card_header}
                    classes={{action: classes.card_action_button}}
                />
                <Divider/>
                <AlertUI
                    open={this.state.alert.open}
                    message={this.state.alert.message}
                    type={this.state.alert.type}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <CardContent className={classes.card_content}>
                    <Grid container alignItems="center" spacing={24}>
                        <Grid item xs={12}>
                            <p>
                                Esta función va a recuperar <strong>TODAS</strong> las categorías que tenga borradas la tienda #{this.state.id_tienda} y para que el cliente decida si volver a borrarlas, editarlas para mantenerla o utilizarlas para mover sus productos a otra/s:
                            </p>
                            <ol>
                                <li className={classes.li_example}><u>Ejemplo:</u> Si una categoría se llama "Remeras", pasará a llamarse "Remeras recuperada 123645" donde el último número va a ser el identificador único de cada categoría (siempre es diferente), esto es para evitar que dos o más categorías tengan el mismo link (por ejemplo, si tenía 2 categorías borradas con el mismo nombre) y adicionalmente ayuda a que el cliente pueda identificar cual categoría es la que realmente quiere recuperar.</li>
                            </ol>
                        </Grid>
                        <Grid item xs={12}>
                            <ButtonUI
                                type="callf"
                                label="Recuperar categorías"
                                isLoading={this.state.button_loading}
                                fullWidth={false}
                                onClickFunc={() => this.handleSubmitRecuperarCategorias()}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}

const styles = theme => ({
    span: {
        display: 'block',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    card: {
        ...theme.card.card,
        padding: '0px !important',
        marginBottom: '25px'
    },
    card_content: theme.card.card_content,
    li_example: {
        fontSize: '0.95rem',
        lineHeight: '1.3rem'
    }
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
    return {
        recuperarCategoriasEliminadas: (data,callback) => dispatch(recuperarCategoriasEliminadas(data,callback))
    }
}

RecuperarCategorias_.propTypes = {

    //Parámetro de búsqueda que recibe el componente por props
    id_tienda: PropTypes.string.isRequired

};

export const RecuperarCategorias = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(RecuperarCategorias_));
