
import React from 'react';  
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PublicRoute_ = ({ component: Component, ...rest }) =>  {

    let auth = rest.autenticado;

    if(!auth)
    {
        return (
            <Route {...rest} render={props => <Component {...props} /> } />
        );
    }
    else
    {
        return (
            <Route {...rest} render={props => <Redirect to={{ pathname: '/', state: { from: props.location }}} /> } />
        );
    }
    
}

const mapStateToProps = (state) => {
    return {
        autenticado: state.auth.autenticado
    }
}
const mapDispatchToProps = null;

export const PublicRoute = connect(mapStateToProps,mapDispatchToProps)(PublicRoute_);