import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    IconButton,
    Icon,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    withStyles
} from '@material-ui/core';


const AlianzasList_ = (props) => {
    let {alianzas, classes, modalHandler, filtro} = props;
    const renderizarAlianzas = (alianzas,modalHandler,classes) => {
        if(alianzas.length>0)
        {
            return alianzas.filter( alianzas => {
                let {a_nombre,a_email,a_telefono} = alianzas;
                let busqueda = filtro.toLowerCase();
                if(
                    (a_nombre !== null && a_nombre.toLowerCase().includes(busqueda)) ||
                    (a_email !== null && a_email.toLowerCase().includes(busqueda)) ||
                    (a_telefono !== null && a_telefono.includes(busqueda))
                )
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }).map((alianza,index) => {
                return (
                    <ListItem divider={index<(alianzas.length-1)} key={alianza.idAlianzas} className={classes.list_item} >
                        <ListItemText
                            primary={alianza.a_nombre}
                            classes={{root: classes.list_text}}
                            secondary={
                                <span className={classes.span}>
                                    <span className={classes.span}>{alianza.a_email}</span>
                                    <span className={classes.span}>{alianza.a_telefono}</span>
                                    <span className={classes.span}>CBU: {alianza.a_cbu ? alianza.a_cbu : 'N/A'}</span>
                                </span>
                            }
                        />
                        <ListItemSecondaryAction>
                            <IconButton aria-label="Cupones" component={(props) => <Link to={`/cupon-alianza/${alianza.idAlianzas}`} {...props}/>}>
                                <Icon>local_offer</Icon>
                            </IconButton>
                            <IconButton aria-label="Editar" onClick={(e) => modalHandler(true,'editar',alianza)}>
                                <Icon>edit</Icon>
                            </IconButton>
                            <IconButton aria-label="Borrar" onClick={(e) => modalHandler(true,'borrar',alianza)}>
                                <Icon>delete</Icon>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            });
        }
        else
        {
            return (
                <ListItem>
                    <ListItemText 
                        primary="No se han encontrado alianzas activas"
                        secondary="Puedes agregar una haciendo click en Agregar"
                    />
                </ListItem>
            );
        }
    }

    return (
        <List classes={{root: classes.list}}>
            {renderizarAlianzas(alianzas,modalHandler,classes)}
        </List>
    );
}

const styles = theme => ({
    span: {
        display: 'block',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    list: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '0',
        '&:last-child': {
            borderBottom: '0px solid transparent'
        }
    },
    list_text: {
        paddingRight: '100px'
    }
});

const mapStateToProps = (state) => {
    return {
        alianzas: state.alianzas,
        auth: state.auth,
    };
}

const mapDispatchToProps = null;

AlianzasList_.propTypes = {
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    filtro: PropTypes.string.isRequired

};

export const AlianzasList = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(AlianzasList_));
