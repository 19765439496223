import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';

const ToggleUI = (props) => {
    let {
        show,
        children,
        loading = false,
    } = props;
    if(show && !loading)
    {
        return children;
    }
    else
    {
        if(loading)
        {
            return <LinearProgress />;
        }
        else
        {
            return '';
        }
    }
}

ToggleUI.propTypes = {
    show: PropTypes.oneOfType([PropTypes.bool,PropTypes.number]).isRequired,
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool
};

export { ToggleUI };
