import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    withStyles,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    CardHeader,
    Divider,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow
} from '@material-ui/core';
import { 
    obtenerDimensionesExcesivas
} from '../../../store/actions';
import {
    ToggleUI
} from '../../common';

class DimensionesExcesivas_ extends Component
{   
    state={
        id_tienda: 0,
        data_response: {
           
        },
        loading: {
            tienda: true,
            error: false
        },
    };

    componentDidMount() 
    {
        this.handleChangeId();
    }

    componentDidUpdate() 
    {
        this.handleChangeId();
    }

    handleChangeId = () => {

        if(this.state.id_tienda !== this.props.id_tienda)
        {
            this.setState({
                id_tienda: this.props.id_tienda,
                loading: {
                    ...this.state.loading,
                    tienda: true,
                    error: false
                }
            }, () => {
                this.props.isLoading(true);
                this.props.obtenerDimensionesExcesivas({
                    xIdTienda: this.state.id_tienda,
                }, (err,resp) => {
                    this.props.isLoading(false);
                    this.setState({
                        data_response: resp.data,
                        loading: {
                            ...this.state.loading,
                            tienda: false,
                            error: (this.state.loading.error || err) ? true : false
                        }
                    });
                });
            });
        }
    }

    render()
    {
        let {classes} = this.props;
        let atributos_body;
        let atributos_head;
        let body;
        let row;
        if(this.state.loading.error===false && this.state.loading.tienda===false)
        {
            if(this.state.data_response.length>0) 
            {   
                //Para cada producto que devuelva la response, hago una tabla
                body = this.state.data_response.map((producto,index) => {
                    let table_class = (this.state.data_response.length-1)===index ? classes.table_cell_last : classes.table_cell;                
                
                    //Para cada variante de un mismo producto que tenga stock, hago una fila
                    row =  producto.stock.map((elemento, id) => {
                        if(producto.p_atributos===1) 
                        {
                            //Si tiene atributos extra, agrego las columnas y valores correspondientes
                            atributos_head = elemento.valoratributo.map((atributo,idx) => {
                                let table_class = (this.state.data_response.length-1)===idx ? classes.table_cell_last : classes.table_cell;                
                                return (
                                    <TableCell key={idx} classes={{root: table_class}}>{atributo.at_nombre}</TableCell>
                                );
                            });
                            atributos_body = elemento.valoratributo.map((atributo,idx) => {
                                let table_class = (this.state.data_response.length-1)===idx ? classes.table_cell_last : classes.table_cell;                
                                return (
                                        <TableCell key={idx} classes={{root: table_class}}>{atributo.valor.vat_valor}</TableCell>
                                );
                            });
                        }
                        
                        return (
                            <TableRow hover key={id}>
                                {atributos_body}
                                <TableCell classes={{root: table_class}}>{elemento.s_alto}cm</TableCell>
                                <TableCell classes={{root: table_class}}>{elemento.s_ancho}cm</TableCell>
                                <TableCell classes={{root: table_class}}>{elemento.s_profundidad}cm</TableCell>
                                <TableCell classes={{root: table_class}}>{elemento.s_peso}kg</TableCell>
                            </TableRow>
                        );
                    })
                    
                    return(
                        <Card className={classes.card} key={index}>
                            <CardHeader
                                title={producto.p_nombre}
                                className={classes.card_header}
                                classes={{action: classes.card_action_button}}
                            />
                            <Divider/>
                            <CardContent className={classes.card_content_no_padding}>
                                <Table className={classes.table} padding="dense">
                                    <TableHead>
                                        <TableRow>
                                            {atributos_head}
                                            <TableCell classes={{root: table_class}}>Alto</TableCell>
                                            <TableCell classes={{root: table_class}}>Ancho</TableCell>
                                            <TableCell classes={{root: table_class}}>Profundidad</TableCell>
                                            <TableCell classes={{root: table_class}}>Peso</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    );
                }) 
            }
        } 

        return (
            <Fragment>
                <ToggleUI show={this.state.loading.error}>
                    <Card className={classes.card}>
                        <CardHeader
                            title="Tienda"
                            className={classes.card_header}
                            classes={{action: classes.card_action_button}}
                        />
                        <Divider/>
                        <CardContent className={classes.card_content_no_padding}>
                            <List>
                                <ListItem>
                                    <ListItemText 
                                        primary="No se han encontrado datos"
                                        secondary="Por favor, ingrese un ID Tienda y un filtro"
                                    />
                                </ListItem> 
                            </List>
                        </CardContent>
                    </Card>
                </ToggleUI>
                <ToggleUI show={this.state.loading.error===false && this.state.data_response.length===0 && this.state.loading.tienda===false}>
                    <Card className={classes.card}>
                        <CardHeader
                            title="Tienda"
                            className={classes.card_header}
                            classes={{action: classes.card_action_button}}
                        />
                        <Divider/>
                        <CardContent className={classes.card_content_no_padding}>
                            <List>
                                <ListItem>
                                    <ListItemText primary="No se han encontrado datos para la tienda seleccionada" />
                                </ListItem> 
                            </List>
                        </CardContent>
                    </Card>
                </ToggleUI>
                <ToggleUI show={this.state.data_response.length>0 && this.state.loading.tienda===false}>
                    <Fragment>
                        {body}
                    </Fragment>        
                </ToggleUI>
            </Fragment>
        );
    }
}

const styles = theme => ({
    span: {
        display: 'block',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    list: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '0',
        '&:last-child': {
            borderBottom: '0px solid transparent'
        }
    },
    list_text: {
        paddingRight: '100px'
    },
    table: {
        minWidth: '100%',
    },    
    card: {
        ...theme.card.card,
        padding: '0px !important',
        marginBottom: '25px'
    },
    card_content: theme.card.card_content,
    card_content_no_padding: {
        ...theme.card.card_content,
        padding: '0px !important',
        overflowX: 'auto'
    }
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
    return {
        obtenerDimensionesExcesivas: (data,callback) => dispatch(obtenerDimensionesExcesivas(data,callback)),
    }
}

DimensionesExcesivas_.propTypes = {

    //Función que hace la request a la api
    obtenerDimensionesExcesivas: PropTypes.func.isRequired,

    isLoading: PropTypes.func.isRequired,

    //Parámetro de búsqueda que recibe el componente por props
    id_tienda: PropTypes.string.isRequired

};

export const DimensionesExcesivas = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(DimensionesExcesivas_));
