import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { ModalUI } from '../../../../common';

const ModalAbonarMasivo = (props) => {

    let { 
        marcar_abonado,
        handleSubmit,
        modalHandler,
        modal_state,
        form_state
    } = props;
  
    return (
        <ModalUI 
            open={modal_state}
            id="marcar_abonado"
            title="Marcar como abonado"
            modalHandler={modalHandler}
            aditional_param="marcar_abonado"
            handleSubmit={handleSubmit}
            button_label="OK"
            button_disabled={false}
            button_loading={form_state}
        >
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Typography>
                        ¿Estás seguro de que quieres marcar como abonado el/los lead/s de {marcar_abonado.a_nombre}? 
                    </Typography>
                </Grid>
            </Grid>
        </ModalUI>
    );
}

ModalAbonarMasivo.propTypes = {

    //The form submit function
    //Called: handleSubmit(event,id)
    handleSubmit: PropTypes.func.isRequired,
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    //true if the modal is open
    modal_state: PropTypes.bool.isRequired,

    //true if the modal is open
    form_state: PropTypes.bool.isRequired,

    //The object initialized in state 
    marcar_abonado: PropTypes.shape({
        a_nombre:       PropTypes.string.isRequired,
    
    })
};

export { ModalAbonarMasivo };
