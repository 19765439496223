export default class Reducers
{
    /********/
    /* Auth */
    /********/
    static AUTH_SET = 'AUTH_SET'; 
    static AUTH_UNSET = 'AUTH_UNSET';

    /********/
    /* Alianzas */
    /********/
    static ALIANZA_GUARDAR = 'ALIANZA_GUARDAR'; 
    static ALIANZA_GUARDAR_ID = 'ALIANZA_GUARDAR_ID'; 
    static ALIANZA_AGREGAR = 'ALIANZA_AGREGAR';
    static ALIANZA_EDITAR = 'ALIANZA_EDITAR';
    static ALIANZA_BORRAR = 'ALIANZA_BORRAR';

    /********/
    /* Cupones */
    /********/
    static CUPON_ALIANZA_GUARDAR = 'CUPON_ALIANZA_GUARDAR';
    static CUPON_ALIANZA_AGREGAR = 'CUPON_ALIANZA_AGREGAR';
    static CUPON_ALIANZA_EDITAR = 'CUPON_ALIANZA_EDITAR';
    static CUPON_ALIANZA_BORRAR = 'CUPON_ALIANZA_BORRAR';
    static CUPON_LIQUIDACION_MASIVA = 'CUPON_LIQUIDACION_MASIVA';
    static CUPON_ABONADO_MASIVO = 'CUPON_ABONADO_MASIVO';
    static CUPON_TODO_ABONADO = 'CUPON_TODO_ABONADO';

    /********/
    /* Leads */
    /********/
    static CUPON_LEADS_GUARDAR = 'CUPON_LEADS_GUARDAR';
    static CUPON_LEADS_ABONADO = 'CUPON_LEADS_ABONADO';

    /********/
    /* Emprepack */
    /********/
    static SOLICITUDES_GUARDAR = 'SOLICITUDES_GUARDAR';
    static STATUS_MODIFICADO = 'STATUS_MODIFICADO';

    /********/
    /* Tiendas */
    /********/
    static TIENDAS_GUARDAR = 'TIENDAS_GUARDAR';
    static TIENDAS_GUARDAR_NO_CLEAN = 'TIENDAS_GUARDAR_NO_CLEAN';


    /********/
    /* Base Conocimiento */
    /********/
    static CONOCIMIENTO_GUARDAR = 'CONOCIMIENTO_GUARDAR'; 
    static CONOCIMIENTO_ORDENAR = 'CONOCIMIENTO_ORDENAR'; 

}