/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const alianzasReducer = (state = initState,action) => {
    let alianzas;
    switch(action.type)
    {
        case Reducers.ALIANZA_GUARDAR:

            alianzas = action.data;     
            return alianzas;

        break;

        case Reducers.ALIANZA_GUARDAR_ID:

            let alianza = action.data;
            alianzas = [
                alianza
            ];
            return alianzas;

        break;

        case Reducers.ALIANZA_AGREGAR:

            let nueva_alianza = action.data;
            alianzas = [
                nueva_alianza,
                ...state
            ];      
            return alianzas;

        break;

        case Reducers.ALIANZA_EDITAR:

            let alianza_editado = action.data;
            alianzas = state.map((alianza) => {
                if(alianza.idAlianzas === alianza_editado.idAlianzas)
                {
                    return alianza_editado;
                }
                else
                {
                    return alianza;
                }
            });    
            return alianzas;

        break;

        case Reducers.ALIANZA_BORRAR: 

            let alianza_borrado = action.data;
            alianzas = state.filter((alianza) => {
                return alianza.idAlianzas !== alianza_borrado.idAlianzas;
            });   
            return alianzas;

        break;
        
        case Reducers.AUTH_UNSET:

            return initState;

        break;

        default: 

            return state;

        break;
    }
}

export { alianzasReducer };