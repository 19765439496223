import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    IconButton,
    Icon,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    withStyles
} from '@material-ui/core';


const SolicitudesList_ = (props) => {
    let {solicitudes, classes, modalHandler, handleSubmitDescargarArchivo, filtro} = props;
    const renderizarSolicitudes = (solicitudes,modalHandler,classes) => {
        if(solicitudes.length>0)
        {
            return solicitudes.filter( solicitudes => {
                let {customer_email,customer_identification} = solicitudes;
                let customer_name = solicitudes.customer_name + ' ' + solicitudes.customer_surname;
                let busqueda = filtro.toLowerCase();
                if(
                    (customer_name !== null && customer_name.toLowerCase().includes(busqueda)) ||
                    (customer_email !== null && customer_email.toLowerCase().includes(busqueda)) ||
                    (customer_identification !== null && customer_identification.includes(busqueda))
                )
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }).map((solicitud,index) => {

                const docs = solicitud.verifications.map((doc, index) => {
                    return (
                        <a key={index} href="#!" onClick={(e) => handleSubmitDescargarArchivo(e,doc)} >{`Documento-${doc.idVerification} `}</a> 
                    );
                });

                return (
                    <ListItem divider={index<(solicitudes.length-1)} key={solicitud.idCustomer} className={classes.list_item} >
                        <ListItemText
                            primary={`${solicitud.customer_name} ${solicitud.customer_surname}`}
                            classes={{root: classes.list_text}}
                            secondary={
                                <span className={classes.span}>
                                    <span className={classes.span}>{`${solicitud.customer_identification_type}:  ${solicitud.customer_identification}`}</span>
                                    <span className={classes.span}>{`Tel:  ${solicitud.customer_phone}`}</span>
                                    <span className={classes.span}>{`Email:  ${solicitud.customer_email}`}</span>
                                    <span className={classes.span}>{`Dirección: ${solicitud.customer_street} ${solicitud.customer_street_number} ${solicitud.customer_floor} ${solicitud.customer_apartment}, ${solicitud.customer_city}, ${solicitud.customer_state}, CP: ${solicitud.customer_zipcode}`}</span>
                                    <span className={classes.span}>Documentos:</span>
                                    <span className={classes.span}>{docs}</span>
                                </span>
                            }
                        />
                        <ListItemSecondaryAction>
                            <IconButton aria-label="Aprobar" onClick={(e) => modalHandler(true,'marcar_aprobado',solicitud)}>
                                <Icon>check</Icon>
                            </IconButton>
                            <IconButton aria-label="Rechazar" onClick={(e) => modalHandler(true,'marcar_rechazado',solicitud)}>
                                <Icon>clear</Icon>
                            </IconButton> 
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            });
        }
        else
        {
            return (
                <ListItem>
                    <ListItemText 
                        primary="No se han encontrado solicitudes pendientes"
                    />
                </ListItem>
            );
        }
    }

    return (
        <List classes={{root: classes.list}}>
            {renderizarSolicitudes(solicitudes,modalHandler,classes)}
        </List>
    );
}

const styles = theme => ({
    span: {
        display: 'block',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    list: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '0',
        '&:last-child': {
            borderBottom: '0px solid transparent'
        }
    },
    list_text: {
        paddingRight: '100px'
    }
});

const mapStateToProps = (state) => {
    return {
        solicitudes: state.solicitudes,
        auth: state.auth,
    };
}

const mapDispatchToProps = null;

SolicitudesList_.propTypes = {
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    filtro: PropTypes.string.isRequired

};

export const SolicitudesList = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(SolicitudesList_));
