import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { adminLogout } from '../../store/actions';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { 
    AppBar, 
    Drawer,
    Collapse, 
    Hidden, 
    Button,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Icon,
    Toolbar,
    Grid,
    Menu,
    Typography,
    MenuItem,
    Divider,
    LinearProgress,
    withStyles
} from '@material-ui/core';
import { ErrorLayout, ToggleUI } from './';
import {Validate} from '../../other';

const is_mobile = Validate.is_mobile();

class PrivateLayout_ extends Component 
{
    state = {
        nestedMenu: {
            clientes_emprepack: false,
            base_de_conocimiento: false
        },
        mobileOpen: false,
        anchorEl: null,
        profileMenuOpen: false
    };

    //abre y cierra el menu lateral
    handleDrawerToggle = () => this.setState(state => ({ mobileOpen: !state.mobileOpen }));

    //abre y cierra los submenus del menu lateral
    handleNestedMenu = (menu) => {

        let nestedMenu = this.state.nestedMenu;
        Object.keys(this.state.nestedMenu).forEach((key) => {
            if(key===menu)
            {
                nestedMenu[key] = nestedMenu[menu] ? false : true; 
            }
            else
            {
                nestedMenu[key] = false; 
            }
        });
        this.setState({
            nestedMenu: nestedMenu
        });
        
    }

    //abre y cierra el menu del icono de admin
    handleProfileMenu = (e,action) => {
        if(action==='open')
        {
            this.setState({ anchorEl: e.currentTarget, profileMenuOpen: true });
        }
        else
        {
            this.setState({ anchorEl: null, profileMenuOpen: false });
        }
    }

    //abre y cierra los submenus del menu lateral
    handleNestedMenu = (menu) => {

        let nestedMenu = this.state.nestedMenu;
        Object.keys(this.state.nestedMenu).forEach((key) => {
            if(key===menu)
            {
                nestedMenu[key] = nestedMenu[menu] ? false : true; 
            }
            else
            {
                nestedMenu[key] = false; 
            }
        });
        this.setState({
            nestedMenu: nestedMenu
        });
        
    }

    //cierra la sesion
    logout = (e) => {
        e.preventDefault();
        this.props.adminLogout();
    }

    render() 
    {
        const { classes, loading = false, error = false, url = '/', auth } = this.props;
        //menu lateral
        const drawer = (
            <div>
                <div className={classes.toolbar_sidenav}>
                    <IconButton color="inherit" aria-label="Open drawer" onClick={this.handleDrawerToggle}>
                        <Icon>chevron_left</Icon>
                    </IconButton>
                </div>
                <div style={{padding:'10px'}}>
                    <Grid container direction="row" justify="space-between" alignItems="center" spacing={0}>
                        <Grid item xs={10}>
                            <Typography variant="subtitle1" color="inherit" gutterBottom>
                                {this.props.auth.as_nombre_completo}
                            </Typography>
                            <Typography variant="caption" gutterBottom noWrap>
                                {this.props.auth.as_email}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Divider />
                <List>
                    <ListItem button component={(props) => <Link to="/alianza" {...props}/>} >
                        <ListItemIcon className={classes.list_icon}><Icon>account_tree</Icon></ListItemIcon>
                        <ListItemText inset className={classes.list_item_menu} primary="Alianzas" />
                    </ListItem>
                    <ToggleUI show={auth.rol===0}>
                        <ListItem button component={(props) => <Link to="/estadisticas" {...props}/>} >
                            <ListItemIcon className={classes.list_icon}><Icon>trending_up</Icon></ListItemIcon>
                            <ListItemText inset className={classes.list_item_menu} primary="Estadísticas" />
                        </ListItem>
                    </ToggleUI>
                    <ListItem button component={(props) => <Link to="/administracion" {...props}/>} >
                        <ListItemIcon className={classes.list_icon}><Icon>folder_special</Icon></ListItemIcon>
                        <ListItemText inset className={classes.list_item_menu} primary="Administración" />
                    </ListItem>
                    <ListItem button component={(props) => <Link to="/buscar-tienda" {...props}/>} >
                        <ListItemIcon className={classes.list_icon}><Icon>search</Icon></ListItemIcon>
                        <ListItemText inset className={classes.list_item_menu} primary="Buscar tienda" />
                    </ListItem>
                    <ListItem button component={(props) => <Link to="/buscar-blacklist" {...props}/>} >
                        <ListItemIcon className={classes.list_icon}><Icon>cancel_schedule_send</Icon></ListItemIcon>
                        <ListItemText inset className={classes.list_item_menu} primary="Buscar en blacklist" />
                    </ListItem>
                    <ListItem button component={(props) => <Link to="/auditar-tiendas" {...props}/>} >
                        <ListItemIcon className={classes.list_icon}><Icon>verified_user</Icon></ListItemIcon>
                        <ListItemText inset className={classes.list_item_menu} primary="Auditoría de tiendas" />
                    </ListItem>
                    <ListItem button onClick={(e) => this.handleNestedMenu("clientes_emprepack")}>
                        <ListItemIcon className={classes.list_icon}><Icon>local_shipping</Icon></ListItemIcon>
                        <ListItemText inset className={classes.list_item_menu} primary="Clientes Emprepack" />
                        {this.state.nestedMenu.clientes_emprepack ? <Icon className={classes.list_item_menu_icon}>expand_less</Icon> : <Icon className={classes.list_item_menu_icon}>expand_more</Icon>}
                    </ListItem>
                    <Collapse in={this.state.nestedMenu.clientes_emprepack} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button component={(props) => <Link to="/solicitudes-pendientes" {...props}/>} className={classes.nested}>
                                <ListItemText inset primary="Solicitudes de alta pendientes" />
                            </ListItem>
                        </List>
                        <List component="div" disablePadding>
                            <ListItem button component={(props) => <Link to="/buscar-cliente" {...props}/>} className={classes.nested}>
                                <ListItemText inset primary="Buscar cliente" />
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem button onClick={(e) => this.handleNestedMenu("base_de_conocimiento")}>
                        <ListItemIcon className={classes.list_icon}><Icon>menu_book</Icon></ListItemIcon>
                        <ListItemText inset className={classes.list_item_menu} primary="Base de conocimiento" />
                        {this.state.nestedMenu.base_de_conocimiento ? <Icon className={classes.list_item_menu_icon}>expand_less</Icon> : <Icon className={classes.list_item_menu_icon}>expand_more</Icon>}
                    </ListItem>
                    <Collapse in={this.state.nestedMenu.base_de_conocimiento} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button component={(props) => <Link to="/base-conocimiento/1" {...props}/>} className={classes.nested}>
                                <ListItemText inset primary="Tutoriales" />
                            </ListItem>
                        </List>
                        <List component="div" disablePadding>
                            <ListItem button component={(props) => <Link to="/base-conocimiento/2" {...props}/>} className={classes.nested}>
                                <ListItemText inset primary="Preguntas frecuentes" />
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </div>
        );

        //menu de usuario
        const user_menu = (
            <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={this.state.profileMenuOpen}
                onClose={(e) => this.handleProfileMenu(e,"close")}
            >
                <MenuItem onClick={this.logout}>Salir</MenuItem>
            </Menu>
        );

        return (
            <div className={classes.root}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton color="inherit" aria-label="Open drawer" onClick={this.handleDrawerToggle} className={classes.menuButton}>
                            <Icon>menu</Icon>
                        </IconButton>
                        <Link to="/">
                            <img src="https://dk0k1i3js6c49.cloudfront.net/landingpage/images/v4/empretienda-green-logo.png" alt="Logo panel" className={classes.logo}/>
                        </Link>
                        <div className={classes.grow} />
                        <div className={classes.sectionUser}>
                            <ToggleUI show={is_mobile}>
                                <IconButton
                                    component={(props) => <Link to="/tutoriales" {...props}/>}
                                    color="primary"
                                >
                                    <Icon>help_outline</Icon>
                                </IconButton>
                                <IconButton
                                    aria-owns={this.state.mobileOpen ? 'menu-appbar' : undefined}
                                    aria-haspopup="true"
                                    onClick={(e) => this.handleProfileMenu(e,"open")}
                                    color="primary"
                                >
                                    <Icon>account_circle</Icon>
                                </IconButton>
                            </ToggleUI>
                            <ToggleUI show={!is_mobile}>
                                <Button color="inherit" classes={{root: classes.navbarButton }} onClick={(e) => this.handleProfileMenu(e,"open")}>
                                    <Icon style={{marginRight: 5}}>person</Icon> Cuenta
                                </Button>
                            </ToggleUI> 
                            { user_menu }
                        </div>
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer}>
                    <Hidden mdUp implementation="css">
                        <Drawer
                            container={this.props.container}
                            variant="temporary"
                            anchor='left'
                            open={this.state.mobileOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                        {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden smDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                            >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    </nav>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {
                        loading ? <LinearProgress /> : (error ? <ErrorLayout url={url}/> : this.props.children)
                    }
                </main>
            </div>
        );
    }
}

const styles = theme => ({
    root: {
        display: 'flex'
    },
    badge: {
        background: '#ec268f'
    },
    logo: {
        width: 'auto',
        height: '20px',
        marginLeft: '-10px',
        margin: 'auto',
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            height: '28px',
        }
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: '300px',
            flexShrink: 0,
        }
    },
    nested: {
        paddingLeft: 0
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto',
        }
    },
    grow: {
        flexGrow: 1
    },
    navbarButton: {
        textTransform: 'none',
        color: theme.texts.navbar_buttons
    },
    toolbar: theme.mixins.toolbar,
    toolbar_sidenav: {
        ...theme.mixins.toolbar,
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        padding: '0px 5px',
        display:'flex',
        justifyContent:'flex-end',
        alignItems: 'center'
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    appBar: {
        backgroundColor: '#fff',
        boxShadow: '0 0.07em 0.125em 0 rgba(0,0,0,.15)',
        zIndex: theme.zIndex.drawer+2
    },
    menuButton: {
        marginRight: 20,
        color: theme.texts.menu_hambuger,
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },
    drawerPaper: {
        width: '300px',
        zIndex: theme.zIndex.drawer,
        border: '0px solid transparent'
    },
    content: {
        flexGrow: 1,
        maxWidth: '100vw',
        [theme.breakpoints.up('md')]: {
            maxWidth: 'calc(100vw - 300px)',
        },
        boxSizing: 'border-box',
        padding: theme.spacing.unit * 3
    },
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200
        }
    },
    sectionUser: {
        display: 'flex'
    },
    list_icon: {
        marginRight: '3px',
        color: theme.texts.navbar_buttons
    },
    list_item_menu: {
        color: theme.texts.navbar_buttons,
        [theme.breakpoints.up('md')]: {
            padding: '7.5px'
        }
    },
    list_item_menu_icon: {
        color: theme.texts.navbar_buttons
    }
});

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        adminLogout: () => dispatch(adminLogout())
    }
};

export const PrivateLayout = withStyles(styles, { withTheme: true })(connect(mapStateToProps,mapDispatchToProps)(PrivateLayout_));