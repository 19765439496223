import React from 'react';
import { Typography, Icon, withStyles } from '@material-ui/core';

const ModuleTitleUI_ = (props) => {

    const {
        classes,
        title = '',
        subtitles = [],
        image = null,
        inline_image = null,
        image_size = '75px',
        button = ''
    } = props;

    let subtitles_html = subtitles.map((subtitle,index) => {
        return (
            <Typography className={classes.module_subtitle} key={index}>
                <Icon className={classes.module_subtitle_icon}>chevron_right</Icon> 
                {subtitle}
            </Typography>
        );
    });

    let title_html = image ? (
        <img src={image} style={{height: image_size}} alt="Imagen de modulo"/>
    ) : (
        <Typography className={classes.module_title}>
            {inline_image ? <span className={classes.module_title_image_box}><img src={inline_image} className={classes.module_inline_image} alt="Imagen de modulo"/>{title}</span> : title}
        </Typography>
    );

    return (
        <div className={classes.module_box}>
            <div className={classes.module_title_box}>
                {title_html}
                {button}
            </div>
           {subtitles_html}
        </div>
    );
}

const styles = theme => ({
    module_box: {
        marginBottom: '15px'
    },
    module_title_box: {
        marginBottom: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    module_title: {
        color: theme.texts.module_title,
        fontSize: '1.4rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.7rem',
        }
    },
    module_subtitle: {
        padding: 0,
        margin: '5px 0px 0px 0px',
        color: theme.texts.module_subtitle,
        fontSize: '0.8rem'
    },
    module_subtitle_icon: {
        fontSize: 'inherit',
        lineHeight: 'inherit'
    },
    module_inline_image: {
        height: '2rem',
        marginRight: '5px'
    },
    module_title_image_box: {
        display: 'flex',
        alignItems: 'center'
    }
});

export const ModuleTitleUI = withStyles(styles)(ModuleTitleUI_);
