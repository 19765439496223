/*
    Author: Luciano Ghione  
    Scope: Web
    Version: 1.0.0
    AlertUI is a react function component based on Material UI which is used to handle 
    all kind of user alerts.
*/
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Snackbar, SnackbarContent, IconButton, withStyles } from '@material-ui/core';
import { CheckCircle, Close, Warning, Error, Info } from '@material-ui/icons';
import { green, amber } from '@material-ui/core/colors';

const variantIcon = {
    success: CheckCircle,
    warning: Warning,
    error: Error,
    info: Info,
};

const AlertUI_ = (props) => {
    
    let {
        classes, 
        type, 
        message, 
        id="snackbar", 
        handleCloseAlert, 
        open, 
        hideSeconds = 5000
    } = props;

    let Icon = variantIcon[type];

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={open}
            autoHideDuration={hideSeconds}
            onClose={handleCloseAlert}
        >
            <SnackbarContent
                classes={{root: classes[type]}}
                aria-describedby={id+'-snackbar'}
                message={
                    <span id={id+'-snackbar'} className={classes.message}>
                        <Icon className={classNames(classes.icon, classes.iconVariant)}/>
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleCloseAlert}
                    >
                        <Close className={classes.icon} />
                    </IconButton>
                ]}
            />
        </Snackbar>
    );
}

const styles = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

AlertUI_.propTypes = {

    //Object used to add some styling with withStyles
    classes: PropTypes.object,
    
    //Type of the alert, it makes
    type: PropTypes.oneOf(['success','warning','error', 'info']).isRequired, 
    
    //The message of the alert
    message: PropTypes.string.isRequired, 

    //The id of the alert
    //Default: snackbar
    id: PropTypes.string,
    
    //The function which makes the alert close
    handleCloseAlert: PropTypes.func.isRequired, 

    //true if we want to show the alert
    open: PropTypes.bool.isRequired,
    
    //The time in miliseconds to hide the alert
    //Default: 2000
    hideSeconds: PropTypes.number
};

export const AlertUI = withStyles(styles)(AlertUI_);