import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    withStyles,
    Card,
    CardContent,
    Table,
    TableCell,
    TableBody,
    TableRow,
    List,
    ListItem,
    ListItemText,
    CardHeader,
    Divider,
    IconButton,
    Icon
} from '@material-ui/core';
import { 
    AlertUI,
    DropdownUI,
    MenuItemUI
} from '../../../common';
import { 
    identificarCliente,
    modificarStatus,
    editarCliente
} from '../../../../store/actions';
import { ModalStatus } from './ModalStatus';
import { ModalEditar } from './ModalEditar'
import { Validate, Regex, RegexExtra } from '../../../../other';

class ResultadoCliente_ extends Component
{   
    state={
        data_response: '',
        data_busqueda: '',
        loading: {
            cliente: true,
            error: false
        },
        alert: {
            open: false,
            type: 'error',
            message: ''
        },
        //campos para modal editar status
        status: {
            tipo_status: {
                auth: true,
                validate: false,
                required: true,
                error: false,
                label: 'Estado de cliente',
                id: 'tipo_status',
                name: 'tipo_status',
                change_param: 'status',
                value: -1,
                default_value: -1,
                messages: {
                    error: ''
                },
            }
        },
        //campos para modal cliente
        editar: {
            idCustomer: {
                validate: false,
                value: ''
            },
            customer_name: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Nombre',
                id: 'editar_customer_name',
                name: 'customer_name',
                change_param: 'editar',
                regex: Regex.NOMBRE_2_45,
                value: '',
                default_value: '',
                placeholder: 'Nombre',
                messages: {
                    error: 'Por favor, ingrese un nombre válido',
                    error_extra: RegexExtra.NOMBRE_2_45
                }
            },
            customer_surname: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Apellido',
                id: 'editar_customer_surname',
                name: 'customer_surname',
                change_param: 'editar',
                regex: Regex.NOMBRE_2_45,
                value: '',
                default_value: '',
                placeholder: 'Apellido',
                messages: {
                    error: 'Por favor, ingrese un apellido válido',
                    error_extra: RegexExtra.NOMBRE_2_45
                }
            },
            customer_email: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'email',
                label: 'Email',
                id: 'editar_customer_email',
                name: 'customer_email',
                change_param: 'editar',
                regex: Regex.EMAIL,
                value: '',
                default_value: '',
                placeholder: 'Email',
                messages: {
                    error: 'Por favor, ingrese un email válido',
                    error_extra: RegexExtra.EMAIL
                }
            },
            customer_identification_type: {
                auth: true,
                validate: false,
                required: true,
                error: false,
                label: 'Tipo identificación',
                id: 'customer_identification_type',
                name: 'customer_identification_type',
                change_param: 'editar',
                value: '',
                default_value: '',
                messages: {
                    error: ''
                },
            },
            customer_identification: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Identificación',
                id: 'editar_customer_identification',
                name: 'customer_identification',
                change_param: 'editar',
                regex: Regex.DNI_CUIT,
                value: '',
                default_value: '',
                placeholder: 'Identificación',
                messages: {
                    error: 'Por favor, ingrese una Identificación válida',
                    error_extra: RegexExtra.DNI_CUIT
                }
            },
            customer_phone: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Teléfono',
                id: 'editar_customer_phone',
                name: 'customer_phone',
                change_param: 'editar',
                regex: Regex.TELEFONO_COMPUESTO,
                value: '',
                default_value: '',
                placeholder: 'Teléfono',
                messages: {
                    error: 'Por favor, ingrese una teléfono válido',
                    error_extra: RegexExtra.TELEFONO_COMPUESTO
                }
            },
            customer_street: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Dirección',
                id: 'editar_customer_street',
                name: 'customer_street',
                change_param: 'editar',
                regex: Regex.CALLE_1_45,
                value: '',
                default_value: '',
                placeholder: 'Dirección',
                messages: {
                    error: 'Por favor, ingrese una dirección válida',
                    error_extra: RegexExtra.CALLE_1_45
                }
            },
            customer_street_number: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Número',
                id: 'editar_customer_street_number',
                name: 'customer_street_number',
                change_param: 'editar',
                regex: Regex.ALTURA_CALLE_1_45,
                value: '',
                default_value: '',
                placeholder: 'Número',
                messages: {
                    error: 'Por favor, ingrese un número válido',
                    error_extra: RegexExtra.ALTURA_CALLE_1_45
                }
            },
            customer_floor: {
                auth: false,
                validate: true,
                required: false,
                disabled: false,
                error: false,
                type: 'number',
                label: 'Piso',
                id: 'editar_customer_floor',
                name: 'customer_floor',
                change_param: 'editar',
                regex: Regex.PISO_DPTO_1_20,
                value: '',
                default_value: '',
                placeholder: 'Piso',
                messages: {
                    error: 'Por favor, ingrese un número de piso válido',
                    error_extra: RegexExtra.PISO_DPTO_1_20
                }
            },
            customer_apartment: {
                auth: false,
                validate: true,
                required: false,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Departamento',
                id: 'editar_customer_apartment',
                name: 'customer_apartment',
                change_param: 'editar',
                regex: Regex.PISO_DPTO_1_20,
                value: '',
                default_value: '',
                placeholder: 'Departamento',
                messages: {
                    error: 'Por favor, ingrese un número de departamento válido',
                    error_extra: RegexExtra.PISO_DPTO_1_20
                }
            },
            customer_state: {
                auth: true,
                validate: false,
                required: true,
                error: false,
                label: 'Provincia',
                id: 'customer_state',
                name: 'customer_state',
                change_param: 'editar',
                value: '',
                default_value: '',
                messages: {
                    error: ''
                },
            },
            customer_city: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Ciudad',
                id: 'editar_customer_city',
                name: 'customer_city',
                change_param: 'editar',
                regex: Regex.CIUDAD_2_45,
                value: '',
                default_value: '',
                placeholder: 'Ciudad',
                messages: {
                    error: 'Por favor, ingrese una ciudad válida',
                    error_extra: RegexExtra.CIUDAD_2_45
                }
            },
            customer_zipcode: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'number',
                label: 'Código Postal',
                id: 'editar_customer_zipcode',
                name: 'customer_zipcode',
                change_param: 'editar',
                regex: Regex.CODIGO_POSTAL,
                value: '',
                default_value: '',
                placeholder: 'Ciudad',
                messages: {
                    error: 'Por favor, ingrese un código postal válido',
                    error_extra: RegexExtra.CODIGO_POSTAL
                }
            },
            customer_country: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'País',
                id: 'editar_customer_country',
                name: 'customer_country',
                change_param: 'editar',
                regex: Regex.STRING_GENERICO_2_45,
                value: '',
                default_value: '',
                placeholder: 'País',
                messages: {
                    error: 'Por favor, ingrese un país válido',
                    error_extra: RegexExtra.STRING_GENERICO_2_45
                }
            },
        },
        selects: {
            status_cliente: [
                {
                    value: -1,
                    label: 'Seleccione un estado'
                },
                {
                    value: 0,
                    label: 'En espera de verificación'
                },
                {
                    value: 1,
                    label: 'Operativa'
                },
                {
                    value: 2,
                    label: 'Presenta deudas'
                },
                {
                    value: 3,
                    label: 'Bloqueada'
                },
                {
                    value: 4,
                    label: 'Verificación rechazada'
                }
            ],
            identification_type: [
                {
                    value: 'DNI',
                    label: 'DNI'
                },
                {
                    value: 'CUIT',
                    label: 'CUIT'
                },
            ],
            customer_state: [
                {
                    value: "Buenos Aires",
                    label: "Buenos Aires"
                },
                {
                    value: "C.A.B.A",
                    label: "C.A.B.A"
                },
                {
                    value: "Catamarca",
                    label: "Catamarca"
                },
                {
                    value: "Chaco",
                    label: "Chaco"
                },
                {
                    value: "Chubut",
                    label: "Chubut"
                },
                {
                    value: "Córdoba",
                    label: "Córdoba"
                },
                {
                    value: "Corrientes",
                    label: "Corrientes"
                },
                {
                    value: "Entre Ríos",
                    label: "Entre Ríos"
                },
                {
                    value: "Formosa",
                    label: "Formosa"
                },
                {
                    value: "Jujuy",
                    label: "Jujuy"
                },
                {
                    value: "La Pampa",
                    label: "La Pampa"
                },
                {
                    value: "La Rioja",
                    label: "La Rioja"
                },
                {
                    value: "Mendoza",
                    label: "Mendoza"
                },
                {
                    value: "Misiones",
                    label: "Misiones"
                },
                {
                    value: "Neuquén",
                    label: "Neuquén"
                },
                {
                    value: "Río Negro",
                    label: "Río Negro"
                },
                {
                    value: "Salta",
                    label: "Salta"
                },
                {
                    value: "San Juan",
                    label: "San Juan"
                },
                {
                    value: "San Luis",
                    label: "San Luis"
                },
                {
                    value: "Santa Cruz",
                    label: "Santa Cruz"
                },
                {
                    value: "Santa Fe",
                    label: "Santa Fe"
                },
                {
                    value: "Santiago del Estero",
                    label: "Santiago del Estero"
                },
                {
                    value: "Tierra del Fuego",
                    label: "Tierra del Fuego"
                },
                {
                    value: "Tucumán",
                    label: "Tucumán"
                }
            ]
        },
        datos_select: {
            id_tienda: '',
            filtro_tienda: -1
        },
        //modales
        modals: {
            status: false,
            editar: false,
        },
        forms: {
            status: false,
            editar: false,
        },
    };

    componentDidMount() 
    {
        this.obtenerCliente();
    }

    componentDidUpdate() 
    {
        this.obtenerCliente();
    }

    obtenerCliente = () => {
        if(this.state.data_busqueda !== this.props.busqueda)
        {
            this.props.isLoading(true);
            this.setState({
                data_busqueda: this.props.busqueda,
                data_response: null,
                loading: {
                    ...this.state.loading,
                    cliente: true,
                    error: false
                }
            }, () => {
                this.props.identificarCliente({
                    customer_email : this.state.data_busqueda, 
                }, (err,resp) => {
                    this.props.isLoading(false);
                    this.setState({
                        data_response: resp.data.length > 0 ? resp.data[0] : null,
                        loading: {
                            ...this.state.loading,
                            cliente: false,
                            error: (this.state.loading.error || err) ? true : false
                        }
                    });
                    if(err)
                    {
                        this.setState({
                            alert: {
                                open: true,
                                message: Validate.translate_message(resp.message.code),
                                type: 'error'
                            }
                        });
                    }
                    if(resp.data.length === 0 && err===false)
                    {
                        this.setState({
                            alert: {
                                open: true,
                                message: 'No se han encontrado resultados',
                                type: 'error'
                            },
                            loading: {
                                ...this.state.loading,
                                cliente: false,
                                error: true
                            }
                        });
                    }
                });
            });
        }
    }

    handleChange = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            }
        },() => {
            Validate.validate_input(this.state[aditional][name],blur,(input) => {
                this.setState({
                    [aditional]: {
                        ...this.state[aditional],
                        [name]: input
                    }
                });
            });
        });
    }

    handleChangeSelect = (e,aditional) => {

        let value = e.target.value;
        let name = e.target.name;
        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            },
        });

    }

    //abre y cierra los modales
    modalHandler = (open,id,aditional) => {
        switch(id) 
        {
            case 'status':
                if(open)
                {
                    let tipo = aditional;
                    Validate.cargar_formulario_sin_nulos(tipo, Validate.limpiar_formulario(this.state.status), (editar_obj) => {
                        this.setState({
                            status: editar_obj,
                            modals: {
                                ...this.state.modals,
                                [id]: open
                            }
                        });
                    });
                }
                else
                {
                    this.setState({
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
            break;
            case 'editar':
                if(open)
                {
                    let tipo = aditional;
                    Validate.cargar_formulario(tipo, Validate.limpiar_formulario(this.state.editar), (editar_obj) => {
                        this.setState({
                            editar: editar_obj,
                            modals: {
                                ...this.state.modals,
                                [id]: open
                            }
                        });
                    });
                }
                else
                {
                    this.setState({
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
            break;
            default:
            break;
        }
    }

    handleSubmitStatus = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                status: true
            }
        });
        let form = this.state.status;

        if(this.state.status.tipo_status.value!==-1)
        {
            if(Validate.validar_formulario(form))
            {
                this.props.modificarStatus({
                    idCustomer: this.state.data_response.idCustomer,
                    customer_status: form.tipo_status.value
                },(err,resp) => {
    
                    if(err)
                    {
                        this.setState({
                            forms: {
                                ...this.state.forms,
                                status: false
                            },
                            alert: {
                                open: true,
                                message: Validate.translate_message(resp.message.code),
                                type: 'error'
                            }
                        });
                    }
                    else
                    {
                        this.setState({
                            forms: {
                                ...this.state.forms,
                                status: false
                            },
                            alert: {
                                open: true,
                                message: Validate.translate_message(resp.message.code),
                                type: 'success'
                            },
                            modals: {
                                ...this.state.modals,
                                status: false
                            },
                            data_response: resp.data
                        },() => {
                            let form_obj = Validate.limpiar_formulario(this.state.status);
                            this.setState({
                                status: form_obj
                            });
                        });
    
                    }
                });
            }
            else
            {
                Validate.validar_formulario_actualizar_obj(form,(new_form) => {
                    this.setState({
                        status: new_form,
                        forms: {
                            ...this.state.forms,
                            status: false
                        },
                        alert: {
                            ...this.state.alert,
                            open: true,
                            message: 'Por favor, revisá tus datos, tenés algunos errores',
                            type: 'error'
                        }
                    });
                });
            }
        }
        else
        {
            this.setState({
                forms: {
                    ...this.state.forms,
                    status: false
                },
                alert: {
                    open: true,
                    message: 'Por favor, seleccione una opción válida',
                    type: 'error'
                }
            });
        }
    }

    handleSubmitEditar = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                editar: true
            }
        });
        let form = this.state.editar;

        if(Validate.validar_formulario(form))
        {
            this.props.editarCliente({
                idCustomer: form.idCustomer.value,
                customer_name: form.customer_name.value,
                customer_surname: form.customer_surname.value,
                customer_email: form.customer_email.value,
                customer_identification: form.customer_identification.value,
                customer_identification_type: form.customer_identification_type.value,
                customer_phone: form.customer_phone.value,
                customer_street: form.customer_street.value,
                customer_street_number: form.customer_street_number.value,
                customer_floor: form.customer_floor.value,
                customer_apartment: form.customer_apartment.value,
                customer_city: form.customer_city.value,
                customer_state: form.customer_state.value,
                customer_zipcode: parseInt(form.customer_zipcode.value),
                customer_country: form.customer_country.value
                
            },(err,resp) => {
                if(err)
                {
                    this.setState({
                        forms: {
                            ...this.state.forms,
                            editar: false
                        },
                        alert: {
                            open: true,
                            message: Validate.translate_message(resp.message.code),
                            type: 'error'
                        }
                    });
                }
                else
                {
                    this.setState({
                        forms: {
                            ...this.state.forms,
                            editar: false
                        },
                        alert: {
                            open: true,
                            message: Validate.translate_message(resp.message.code),
                            type: 'success'
                        },
                        modals: {
                            ...this.state.modals,
                            editar: false
                        },
                        data_response: resp.data
                    },() => {
                        let form_obj = Validate.limpiar_formulario(this.state.editar);
                        this.setState({
                            editar: form_obj
                        });
                    });
                }
            });
        }
        else
        {
            Validate.validar_formulario_actualizar_obj(form,(new_form) => {
                this.setState({
                    editar: new_form,
                    forms: {
                        ...this.state.forms,
                        editar: false
                    },
                    alert: {
                        ...this.state.alert,
                        open: true,
                        message: 'Por favor, revisá tus datos, tenés algunos errores',
                        type: 'error'
                    }
                });
            });
        }
    }


    //cierra las alertas
    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    } 
 
    render()
    {
        let {classes} = this.props;

        if(this.state.loading.cliente || this.state.loading.error)
        {
            return(
                <Card className={classes.card}>
                    <CardHeader
                        title="Cliente"
                        className={classes.card_header}
                        classes={{action: classes.card_action_button}}
                    />
                    <Divider/>
                    <AlertUI
                        open={this.state.alert.open}
                        message={this.state.alert.message}
                        type={this.state.alert.type}
                        handleCloseAlert={this.handleCloseAlert}
                    />
                    <CardContent className={classes.card_content_no_padding}>
                        <List>
                            <ListItem>
                                <ListItemText 
                                    primary="No se han encontrado datos"
                                    secondary="Por favor, ingrese un email de cliente"
                                />
                            </ListItem> 
                        </List>
                    </CardContent>
                </Card>
            );
        } 
        else
        {
            let {
                idCustomer,
                customer_name, 
                customer_surname, 
                customer_email, 
                customer_phone,
                customer_identification,
                customer_identification_type,
                customer_street,
                customer_street_number,
                customer_city,
                customer_state,
                customer_floor,
                customer_apartment,
                customer_zipcode,
                customer_status,
                customer_tax_business,
                customer_tax_identification_type,
                customer_tax_identification,
                customer_tax_condition
            } = this.state.data_response ? this.state.data_response : '';
            let customer_status_name;
    
            //Determina el status de la tienda según el ID
            switch(customer_status)
            {
                case 0:
                    customer_status_name = 'En espera de verificación';
                break;
                    
                case 1:
                    customer_status_name = 'Operativa';
                break;
    
                case 2:
                    customer_status_name = 'Presenta deudas';
                break;
    
                case 3:
                    customer_status_name = 'Bloqueada';
                break;

                case 4:
                    customer_status_name = 'Verificación rechazada';
                break;
    
                default:
                break;
            }

            switch(customer_tax_condition)
            {
                case 'RI':
                    customer_tax_condition = 'Responsable Inscripto';
                break;
                case 'MO':
                    customer_tax_condition = 'Monotributo';
                break;
                case 'CF':
                    customer_tax_condition = 'Consumidor Final';
                break;
                case 'EX':
                    customer_tax_condition = 'Excento';
                break;
                case 'NA':
                    customer_tax_condition = 'IVA No Alcanzado';
                break;
                default: break;
            }
            
            return(
                <Card className={classes.card}>
                    <CardHeader
                        title="Cliente"
                        className={classes.card_header}
                        classes={{action: classes.card_action_button}}
                        action={
                            <DropdownUI type="icon">
                                <MenuItemUI type="callf" onClickFunction={() => this.modalHandler(true,'editar',this.state.data_response)}>Editar cliente</MenuItemUI>
                                <MenuItemUI type="link" link={`/buscar-cliente/envios-cliente/${idCustomer}`}>Ver envíos</MenuItemUI>
                            </DropdownUI> 
                        }
                    />
                    <Divider/>
                    <ModalEditar
                        editar={this.state.editar}
                        handleSubmit={this.handleSubmitEditar}
                        handleChange={this.handleChange}
                        modalHandler={this.modalHandler}
                        modal_state={this.state.modals.editar}
                        form_state={this.state.forms.editar}
                        selects={this.state.selects}
                        handleChangeSelect={this.handleChangeSelect}
                    />
                    <ModalStatus
                        status={this.state.status}
                        handleSubmit={this.handleSubmitStatus}
                        handleChange={this.handleChange}
                        modalHandler={this.modalHandler}
                        modal_state={this.state.modals.status}
                        form_state={this.state.forms.status}
                        handleChangeSelect={this.handleChangeSelect}
                        selects={this.state.selects}
                    />                    
                    <AlertUI
                        open={this.state.alert.open}
                        message={this.state.alert.message}
                        type={this.state.alert.type}
                        handleCloseAlert={this.handleCloseAlert}
                    />
                    <CardContent className={classes.card_content_no_padding}>
                        <Table className={classes.table} padding="dense">
                            <TableBody>
                                <TableRow hover>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell classes={{root: classes.table_class}}>{customer_name}</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell>Apellido</TableCell>
                                    <TableCell classes={{root: classes.table_class}}>{customer_surname}</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell>Email de cliente</TableCell>
                                    <TableCell classes={{root: classes.table_class}}>{customer_email}</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell>Número de teléfono</TableCell>
                                    <TableCell classes={{root: classes.table_class}}>{Validate.is_falsy(customer_phone) ? 'N/A' : customer_phone }</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell>Identificación</TableCell>
                                    <TableCell classes={{root: classes.table_class}}>{`${customer_identification_type} ${customer_identification}`}</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell>Ciudad</TableCell>
                                    <TableCell classes={{root: classes.table_class}}>{customer_city}</TableCell>
                                </TableRow>
                                <TableRow hover>
                                <TableCell>Provincia</TableCell>
                                    <TableCell classes={{root: classes.table_class}}>{customer_state}</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell>Dirección</TableCell>
                                    <TableCell classes={{root: classes.table_class}}>{`${customer_street} ${customer_street_number}`}</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell>Piso</TableCell>
                                    <TableCell classes={{root: classes.table_class}}>{Validate.is_falsy(customer_floor) ? 'N/A' : customer_floor}</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell>Dpto</TableCell>
                                    <TableCell classes={{root: classes.table_class}}>{Validate.is_falsy(customer_apartment) ? 'N/A' : customer_apartment}</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell>Código postal</TableCell>
                                    <TableCell classes={{root: classes.table_class}}>{customer_zipcode}</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell>Facturación</TableCell>
                                    <TableCell classes={{root: classes.table_class}}>
                                        {customer_tax_business} - {customer_tax_identification_type}: {customer_tax_identification} - {customer_tax_condition} (Se toma de Empretienda)
                                    </TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell>Estado de cuenta</TableCell>
                                    <TableCell classes={{root: classes.table_class}}>
                                        {customer_status_name}
                                        <IconButton aria-label="Editar" onClick={(e) => this.modalHandler(true,'status',this.state.data_response)}>
                                            <Icon>edit</Icon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            );
            
        }
    }
}

const styles = theme => ({
    span: {
        display: 'block',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    list: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '0',
        '&:last-child': {
            borderBottom: '0px solid transparent'
        }
    },
    list_text: {
        paddingRight: '100px'
    },
    table: {
        minWidth: '100%',
    },    
    card: theme.card.card,
    card_header: theme.card.card_header,
    card_header_title: theme.card.card_header_title,
    card_actions: theme.card.card_actions_public,
    card_content: theme.card.card_content,
    card_content_no_padding: {
        ...theme.card.card_content,
        padding: '0px !important',
        overflowX: 'auto'
    }

});

const mapStateToProps = (state) => {
    return {
        solicitudes: state.solicitudes,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        identificarCliente: (data,callback) => dispatch(identificarCliente(data,callback)),
        modificarStatus: (data,callback) => dispatch(modificarStatus(data,callback)),
        editarCliente: (data,callback) => dispatch(editarCliente(data,callback))
    }
}

ResultadoCliente_.propTypes = {

    //Función que hace la request a la api
    identificarCliente: PropTypes.func.isRequired,

    isLoading: PropTypes.func.isRequired,

    //Parámetro de búsqueda que recibe el componente por props
    busqueda: PropTypes.string.isRequired

};

export const ResultadoCliente = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(ResultadoCliente_));
