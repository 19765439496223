import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    Grid,
    LinearProgress
 } from '@material-ui/core';
import { 
    PrivateLayout, 
    AlertUI, 
    ModuleTitleUI,
    InputUI,
    SelectUI
} from '../../../../common';
import { 
    EnviosList, 
    ModalOrigen, 
    ModalDestino, 
    ModalSeguirEnvio,
    ModalProductosEnviados,
    ModalReenvio
}  from './subcomponents';
import { Validate, Regex, RegexExtra } from '../../../../../other';
import { clienteObtenerEnvios, clienteSeguirEnvio, clienteGenerarReenvio } from '../../../../../store/actions';

class EnviosCliente_ extends Component 
{
    state={
        data_response: '',
        filter: {
            search: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Buscar',
                id: 'search',
                name: 'search',
                change_param: 'filter',
                regex: Regex.STRING_GENERICO_2_45,
                value: '',
                default_value: '',
                placeholder: 'Buscar...',
                messages: {
                    error: 'Por favor, ingrese una búsqueda válida',
                    error_extra: RegexExtra.STRING_GENERICO_2_45
                }
            },
            filter_status: {
                validate: false,
                required: true,
                error: false,
                label: 'Filtrar',
                id: 'filter_status',
                name: 'filter_status',
                change_param: 'filter',
                value: -1,
                messages: {
                    error: ''
                },
            }, 
        },
        ver_origen: {
            idShipment: 0,
            shipment_submethod: 0,
            shipment_from_name: '', 
            shipment_from_surname: '', 
            shipment_from_email: '', 
            shipment_from_identification: '', 
            shipment_from_identification_type: '', 
            shipment_from_phone: '', 
            shipment_from_street: '', 
            shipment_from_street_number: '', 
            shipment_from_floor: '', 
            shipment_from_apartment: '', 
            shipment_from_city: '', 
            shipment_from_state: '', 
            shipment_from_zipcode: '', 
            shipment_from_country: '', 
            offices: []
        },
        ver_destino: {
            idShipment: 0,
            shipment_submethod: 0,
            shipment_to_name: '', 
            shipment_to_surname: '', 
            shipment_to_email: '', 
            shipment_to_identification: '', 
            shipment_to_identification_type: '', 
            shipment_to_phone: '', 
            shipment_to_street: '', 
            shipment_to_street_number: '', 
            shipment_to_floor: '', 
            shipment_to_apartment: '', 
            shipment_to_city: '', 
            shipment_to_state: '', 
            shipment_to_zipcode: '', 
            shipment_to_country: '', 
            offices: []
        },
        seguir_envio: [],
        reenviar: {
            shipment_reference: {
                validate:false,
                value: 0,
            },
            idShipment: {
                validate:false,
                value: 0,
            },
            require_payment: {
                validate: false,
                required: true,
                error: false,
                label: 'Requerir pago de éste envío',
                id: 'require_payment',
                name: 'require_payment',
                change_param: 'reenviar',
                value: 1,
                messages: {
                    error: ''
                },
            }, 
        },
        productos_enviados: [],

        loading: {
            envios: true,
            error: false
        },

        alert: {
            open: false,
            type: 'error',
            message: ''
        },

        forms: {
            reenviar: false
        },

        //selects
        selects: {
            filter_status: [
                {
                    value: -1,
                    label: 'Todos'
                },
                {
                    value: 0,
                    label: 'Envío creado'
                },
                {
                    value: 1,
                    label: 'Envío abonado y confirmado'
                },
                {
                    value: 2,
                    label: 'Enviado'
                },
                {
                    value: 3,
                    label: 'En camino'
                },
                {
                    value: 4,
                    label: 'En ciudad o sucursal de destino'
                },
                {
                    value: 5,
                    label: 'En proceso de entrega'
                },
                {
                    value: 6,
                    label: 'Entrega sin éxito'
                },
                {
                    value: 7,
                    label: 'Producto entregado'
                },
                {
                    value: 8,
                    label: 'Envío en rescate'
                },
                {
                    value: 9,
                    label: 'Problemas de entrega'
                },
                {
                    value: 10,
                    label: 'Envío cancelado o anulado'
                },
            ],
            si_no: [
                {
                    value: 1,
                    label: 'Si'
                },
                {
                    value: 0,
                    label: 'No'
                },
            ]
        },

        //modales
        modals: {
            ver_origen: false,
            ver_destino: false,
            seguir_envio: false,
            productos_enviados: false,
            reenviar: false,
        },
        data_busqueda: '',
        button_loading: false,
    };

    
    componentDidMount()
    {
        this.props.clienteObtenerEnvios({
            xIdCustomer: this.props.match.params.idCustomer,
        }, (err,resp) => {
            this.setState({
                data_response: resp.data,
                loading: {
                    ...this.state.loading,
                    envios: false,
                    error: (this.state.loading.error || err) ? true : false
                }
            });
        });
    }

    handleChangeFilter = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;
    
        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                },
            }
        });
    }

    handleChange = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;
        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            }
        },() => {
            Validate.validate_input(this.state[aditional][name],blur,(input) => {
                this.setState({
                    [aditional]: {
                        ...this.state[aditional],
                        [name]: input
                    }
                });
            });
        });
    }

    handleChangeSelect = (e,aditional) => {

        let value = Validate.trim_int(e.target.value);
        let name = e.target.name;

        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            },
        });

    }

    //cierra las alertas
    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }

    isLoading = (is_loading) => {
        this.setState({
            button_loading: is_loading,
        });
    }

    //abre y cierra los modales
    modalHandler = (open,id,aditional) => {
        let envio;
        switch(id)
        {
            case 'ver_origen':

                envio = aditional;
                this.setState({
                    modals: {
                        ...this.state.modals,
                        [id]: open
                    },
                    ver_origen: open ? {
                        idShipment: envio.idShipment,
                        shipment_submethod: envio.shipment_submethod,
                        shipment_from_name: envio.shipment_from_name, 
                        shipment_from_surname: envio.shipment_from_surname, 
                        shipment_from_email: envio.shipment_from_email, 
                        shipment_from_identification: envio.shipment_from_identification, 
                        shipment_from_identification_type: envio.shipment_from_identification_type, 
                        shipment_from_phone: envio.shipment_from_phone, 
                        shipment_from_street: envio.shipment_from_street, 
                        shipment_from_street_number: envio.shipment_from_street_number, 
                        shipment_from_floor: envio.shipment_from_floor, 
                        shipment_from_apartment: envio.shipment_from_apartment, 
                        shipment_from_city: envio.shipment_from_city, 
                        shipment_from_state: envio.shipment_from_state, 
                        shipment_from_zipcode: envio.shipment_from_zipcode, 
                        shipment_from_country: envio.shipment_from_country,
                        offices: envio.offices
                    } : this.state.ver_origen,
                });

            break;

            case 'ver_destino':

                envio = aditional;
                this.setState({
                    modals: {
                        ...this.state.modals,
                        [id]: open
                    },
                    ver_destino: open ? {
                        idShipment: envio.idShipment,
                        shipment_submethod: envio.shipment_submethod,
                        shipment_to_name: envio.shipment_to_name, 
                        shipment_to_surname: envio.shipment_to_surname, 
                        shipment_to_email: envio.shipment_to_email, 
                        shipment_to_identification: envio.shipment_to_identification, 
                        shipment_to_identification_type: envio.shipment_to_identification_type, 
                        shipment_to_phone: envio.shipment_to_phone, 
                        shipment_to_street: envio.shipment_to_street, 
                        shipment_to_street_number: envio.shipment_to_street_number, 
                        shipment_to_floor: envio.shipment_to_floor, 
                        shipment_to_apartment: envio.shipment_to_apartment, 
                        shipment_to_city: envio.shipment_to_city, 
                        shipment_to_state: envio.shipment_to_state, 
                        shipment_to_zipcode: envio.shipment_to_zipcode, 
                        shipment_to_country: envio.shipment_to_country,
                        offices: envio.offices
                    } : this.state.ver_destino,
                });

            break;

            case 'seguir_envio':
                envio = aditional;
                
                if(open)
                {
                    this.props.clienteSeguirEnvio({
                        xIdCustomer: this.props.match.params.idCustomer,
                        idShipment: envio.idShipment,
                        shipment_tracking_number: envio.shipment_tracking_number
                    }, (err,resp) => {
                        this.setState({
                            seguir_envio: resp.data,
                            alert: {
                                open: err ? true : false,
                                type: 'error',
                                message: 'El envío no puede seguirse ya que no ha sido dado de alta'
                            },
                            modals: {
                                ...this.state.modals,
                                [id]: err ? false : open
                            },
                        });
                    });
                }
                else
                {
                    this.setState({
                        seguir_envio: [],
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        },
                    });
                }
                
            break;

            case 'productos_enviados':

                envio = aditional;
                this.setState({
                    modals: {
                        ...this.state.modals,
                        [id]: open
                    },
                    productos_enviados: open ? envio.items : this.state.productos_enviados,
                });

            break;

            case 'reenviar':

                envio = aditional;

                this.setState({
                    modals: {
                        ...this.state.modals,
                        [id]: open
                    },
                    reenviar: open ? {
                        ...this.state.reenviar,
                        shipment_reference: {
                            ...this.state.reenviar.shipment_reference,
                            value: envio.shipment_reference
                        },
                        idShipment: {
                            ...this.state.reenviar.idShipment,
                            value: envio.idShipment
                        }
                    } : this.state.reenviar
                });

            break;

            default:
                
                this.setState({
                    modals: {
                        ...this.state.modals,
                        [id]: open
                    }
                });

            break;
        }
    }

    handleSubmitReenviar = (e) => {
        e.preventDefault();

        this.setState({
            forms: {
                ...this.state.forms,
                reenviar: true
            }
        });

        this.props.clienteGenerarReenvio({
            xIdCustomer: this.props.match.params.idCustomer,
            idShipment: this.state.reenviar.idShipment.value,
            require_payment: this.state.reenviar.require_payment.value
        }, (err,resp) => {
            this.setState({
                forms: {
                    ...this.state.forms,
                    reenviar: false
                },
                modals: {
                    ...this.state.modals,
                    reenviar: err ? true : false
                },
                alert: {
                    open: true,
                    type: err ? 'error' : 'success',
                    message: err ? resp.message.description : 'El envío ha sido creado y la orden de Empretienda actualizada'
                }
            }, () => {
                this.props.clienteObtenerEnvios({
                    xIdCustomer: this.props.match.params.idCustomer,
                }, (err,resp) => {
                    this.setState({
                        data_response: resp.data,
                        loading: {
                            ...this.state.loading,
                            envios: false,
                            error: (this.state.loading.error || err) ? true : false
                        }
                    });
                });
            });
        });

    }

    render() 
    {
        return (
            <PrivateLayout 
                error={this.state.loading.error} 
                url={this.props.match.url}
            >
                <AlertUI
                    open={this.state.alert.open}
                    message={this.state.alert.message}
                    type={this.state.alert.type}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <ModuleTitleUI 
                    title="Envíos del cliente"
                    subtitles={[
                        'Aquí podrás ver la información de los envíos del cliente'
                    ]}
                />
                <ModalOrigen
                    ver_origen={this.state.ver_origen}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.ver_origen}
                />
                <ModalDestino
                    ver_destino={this.state.ver_destino}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.ver_destino}
                />
                <ModalSeguirEnvio
                    seguir_envio={this.state.seguir_envio}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.seguir_envio}
                />
                <ModalProductosEnviados
                    productos_enviados={this.state.productos_enviados}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.productos_enviados}
                />
                <ModalReenvio
                    reenviar={this.state.reenviar}
                    handleSubmit={this.handleSubmitReenviar}
                    handleChangeSelect={this.handleChangeSelect}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.reenviar}
                    form_state={this.state.forms.reenviar}
                    selects={this.state.selects}
                />
                <div style={{ paddingBottom: 12 }}>
                    <Grid container  alignItems="center" spacing={24}>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputUI
                                handleChange={this.handleChangeFilter}
                                input={this.state.filter.search}
                            />
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <SelectUI
                                data={this.state.filter.filter_status}
                                options={this.state.selects.filter_status}
                                handleChangeSelect={this.handleChangeSelect}
                            />
                        </Grid>
                    </Grid>
                </div>
                {this.state.loading.envios ? <LinearProgress/> : <EnviosList envios={this.state.data_response} modalHandler={this.modalHandler} filtro={this.state.filter}/>}
            </PrivateLayout>
        );
    }
}


const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
    return {
        clienteObtenerEnvios: (data,callback) => dispatch(clienteObtenerEnvios(data,callback)),
        clienteSeguirEnvio: (data,callback) => dispatch(clienteSeguirEnvio(data,callback)),
        clienteGenerarReenvio: (data,callback) => dispatch(clienteGenerarReenvio(data,callback)),
    }
}

// EnviosCliente_.propTypes = {

// };

export const EnviosCliente = connect(mapStateToProps,mapDispatchToProps)(EnviosCliente_);
