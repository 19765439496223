import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { PublicRoute, PrivateRoute, ThemeUI } from './components/common';
import { 
    Login,
    Alianzas, 
    Inicio,
    CuponAlianza,
    Leads,
    Administracion,
    BuscarTienda,
    BuscarBlacklist,
    SolicitudesPendientes,
    BuscarCliente,
    EnviosCliente,
    LiquidacionMasiva,
    Estadisticas,
    BaseConocimiento
} from './components';

//styles
import './libs/css/style.css';
import { AuditarTiendas } from './components/auditar-tiendas/AuditarTiendas';

class App extends Component 
{
    render() 
    {
        return (
            <ThemeUI>
                <BrowserRouter>
                    <Switch>
                        <PrivateRoute exact path="/" component={Inicio} />
                        <PublicRoute exact path="/login" component={Login} />
                        <PrivateRoute exact path="/alianza" component={Alianzas} />
                        <PrivateRoute exact path="/cupon-alianza/liquidacion-masiva" component={LiquidacionMasiva} />
                        <PrivateRoute exact path="/cupon-alianza/:idAlianzas" component={CuponAlianza} />
                        <PrivateRoute exact path="/cupon-alianza/:idAlianzas/:idCuponAlianza" component={Leads} />
                        <PrivateRoute exact path="/administracion" component={Administracion} />
                        <PrivateRoute exact path="/buscar-tienda" component={BuscarTienda} />
                        <PrivateRoute exact path="/buscar-blacklist" component={BuscarBlacklist} />
                        <PrivateRoute exact path="/solicitudes-pendientes" component={SolicitudesPendientes} />
                        <PrivateRoute exact path="/buscar-cliente" component={BuscarCliente} />
                        <PrivateRoute exact path="/buscar-cliente/envios-cliente/:idCustomer" component={EnviosCliente} />
                        <PrivateRoute exact path="/auditar-tiendas" component={AuditarTiendas} />
                        <PrivateRoute exact path="/estadisticas" component={Estadisticas} />
                        <PrivateRoute exact path="/base-conocimiento/:idKnowledgeBase" component={BaseConocimiento} />
                    </Switch>
                </BrowserRouter>
            </ThemeUI>
        );
    }
}

export default App;
