import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, LinearProgress } from '@material-ui/core';
import { ModalLiteUI } from '../../../../../common';
import { Validate } from '../../../../../../other';

const ModalSeguirEnvio = (props) => {

    let { 
        seguir_envio,
        modalHandler,
        modal_state
    } = props;

    const ShippingWrapper = ({children}) => {
        return (
            <div style={{backgroundColor: '#f5f5f5', padding: '10px', margin: '10px 0px'}}>
                {children}
            </div>
        );
    }

    const track_envio = (seguir_envio) => {
        if(seguir_envio.length>0)
        {
            return seguir_envio.map((item, index) => {
                return(
                    <ShippingWrapper key={index}>
                        <List>
                            <ListItem>
                                <ListItemText primary={<span><strong>{'Fecha: '}</strong>{Validate.format_date(item.shipment_date)}</span>}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<span><strong>{'Estado: '}</strong>{item.shipment_status}</span>}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<span><strong>{'Detalles: '}</strong>{item.shipment_details.length>0 ? Validate.trim_ucwords(item.shipment_details.join(', ')) : '-'}</span>}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<span><strong>{'Ubicación: '}</strong>{item.shipment_location}</span>}/>
                            </ListItem>
                        </List>
                    </ShippingWrapper>
                );
            });
        }
        else
        {
            return (
                <List>
                    <ListItem>
                        <ListItemText primary="No se ha encontrado información" />
                    </ListItem>
                </List>
            );
        }
    }
    
    return (
        <ModalLiteUI 
            open={modal_state}
            title="Seguir envío"
            id="seguir_envio"
            modalHandler={modalHandler}
            aditional_param="seguir_envio"
        >
            {seguir_envio ? track_envio(seguir_envio) : <LinearProgress/>} 
        </ModalLiteUI>
    );
}

ModalSeguirEnvio.propTypes = {
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    //true if the modal is open
    modal_state: PropTypes.bool.isRequired,

    //The object initialized in state 
    seguir_envio: PropTypes.array.isRequired,
};

export { ModalSeguirEnvio };
