import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Icon,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    withStyles
} from '@material-ui/core';
import { DropdownUI, MenuItemUI,ToggleUI } from '../../../../../common';
import { Validate, Config } from '../../../../../../other';

const EnviosList_ = (props) => {
    
    let { envios, classes, modalHandler, filtro } = props;

    let mobile = Validate.is_mobile();

    const metodo_envio = (shipment_method) => {
        switch (shipment_method)
        {
            case 1: 
                return 'Envío a domicilio';
            case 2: 
                return 'Envío a sucursal';
            case 3: 
                return 'Envío a domicilio prioritario';
        
            default:
            break;
        }
    }

    const submetodo_envio = (shipment_submethod) => {
        switch (shipment_submethod)
        {
            case 1: 
                return 'Puerta a puerta';
            case 2: 
                return 'Puerta a sucursal';
            case 3: 
                return 'Sucursal a sucursal';
            case 4: 
                return 'Sucursal a puerta';
            default:
            break;
        }
    }

    const estado_envio = (shipment_status) => {
        switch (shipment_status)
        {
            case 0: 
                return 'Envío creado';
            case 1: 
                return 'Envío abonado y confirmado';
            case 2: 
                return 'Enviado';
            case 10: 
                return 'Envío cancelado o anulado';
        
            default:
            break;
        }
    }

    const renderizarEnvios = (envios,modalHandler) => {

        if(envios.length>0)
        {
            return envios.filter((envio) =>{
                let { 
                    idShipment,
                    shipment_reference, 
                    shipment_status, 
                    shipment_to_name, 
                    shipment_to_surname,
                    shipment_to_email,
                    shipment_to_identification
                } = envio;
                
                let nombre_completo = shipment_to_name + ' ' + shipment_to_surname;
                let busqueda = filtro.search.value.toLowerCase();
                let valor_select = filtro.filter_status.value;
                if(
                    (   
                        (shipment_reference !== null && shipment_reference.toLowerCase().includes(busqueda))  
                        || (idShipment.toString()===busqueda)
                        || (shipment_to_name !== null && shipment_to_surname !== null && nombre_completo.toLowerCase().includes(busqueda))
                        || (shipment_to_email !== null && shipment_to_email.toLowerCase().includes(busqueda))
                        || (shipment_to_identification !== null && shipment_to_identification.toLowerCase().includes(busqueda))

                        )
                    &&
                    (
                        shipment_status===valor_select || (valor_select===-1)
                    )    
                )
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }).map((envio,index) => {

                return  (
                    <ListItem key={envio.idShipment} className={classes.list_item}>
                        {!mobile ? (
                            <ListItemAvatar>
                                <Avatar>
                                    <Icon>local_shipping</Icon>
                                </Avatar>
                            </ListItemAvatar>
                        ) : ''}
                        <ListItemText
                            classes={{root: classes.list_text}}
                            primary={"Envío Emprepack #"+envio.idShipment}
                            secondary={
                                <span className={classes.span}>
                                    <span className={classes.span}><strong>Destinatario:</strong> {Validate.trim_ucwords(envio.shipment_to_name+' '+envio.shipment_to_surname)}</span>
                                    <span className={classes.span}><strong>Tipo:</strong> {metodo_envio(envio.shipment_method)} ({submetodo_envio(envio.shipment_submethod)})</span>
                                    <span className={classes.span}><strong>Estado:</strong> {estado_envio(envio.shipment_status)}</span>
                                    <span className={classes.span}><strong>Fecha:</strong> {Validate.utc2local_format(envio.shipment_created_at,Config.DATE_FORMAT)} (Actualizado a {Validate.utc2local_format(envio.shipment_updated_at,Config.DATE_FORMAT)})</span>
                                    <span className={classes.span}><strong>Orden Empretienda:</strong> #{envio.shipment_reference}</span>
                                    <span className={classes.span}><strong>Número de seguimiento:</strong> {Validate.is_falsy(envio.shipment_code) ? 'N/A' : envio.shipment_code}</span>
                                    <span className={classes.span}><strong>Etiqueta:</strong> {Validate.is_falsy(envio.shipment_label) ? 'N/A' : <u><a href={envio.shipment_label} target='_blank' rel='noopener noreferrer' style={{color: 'inherit'}}>Descargar</a></u>}</span>
                                    <span className={classes.span}><strong>Precio:</strong> ${Validate.number_format(envio.shipment_price)}</span>
                                </span>
                            }
                        />
                        <ListItemSecondaryAction>
                            <DropdownUI type="icon">
                                <MenuItemUI type="callf" onClickFunction={() => modalHandler(true,'ver_origen',envio)}>Ver origen</MenuItemUI>
                                <MenuItemUI type="callf" onClickFunction={() => modalHandler(true,'ver_destino',envio)}>Ver destino</MenuItemUI>
                                <MenuItemUI type="callf" onClickFunction={() => modalHandler(true,'seguir_envio',envio)}>Seguir envío</MenuItemUI>
                                <MenuItemUI type="callf" onClickFunction={() => modalHandler(true,'productos_enviados',envio)}>Ver productos enviados</MenuItemUI>
                                <MenuItemUI type="callf" onClickFunction={() => modalHandler(true,'reenviar',envio)}>Generar nuevo envío</MenuItemUI>
                                <ToggleUI show={envio.shipment_code!==null}>
                                    <MenuItemUI type="link" external open_new_tab link={'https://api.emprepack.com/v1/shipment/'+envio.idShipment+'/invoice?shipment_code='+envio.shipment_code}>Descargar factura</MenuItemUI>
                                </ToggleUI>
                            </DropdownUI>                            
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            });
        }
        else
        {
            return (
                <ListItem className={classes.list_item}>
                    <ListItemText 
                        primary="No se han encontrado envíos"
                        secondary="Ajuste los filtros e intente nuevamente"
                    />
                </ListItem>
            );
        }
    }

    return (
        <List classes={{root: classes.list}}>
            {renderizarEnvios(envios,modalHandler)}
        </List>
    );

}

const styles = theme => ({
    span: {
        display: 'block',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    list: {
        padding: '0'
    },
    list_item: {
        backgroundColor: '#fff',
        boxShadow: theme.card.card.boxShadow,
        marginBottom: '20px',
        borderRadius: '4px'
    },
    list_text: {
        paddingRight: '50px'
    },
    link: {
        marginLeft: '5px',
        color: 'inherit'
    }
});

const mapStateToProps = null;

const mapDispatchToProps = null;

EnviosList_.propTypes = {
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    envios: PropTypes.array.isRequired,

};


export const EnviosList = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(EnviosList_));
