import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const alianzaObtenerAlianzas = (callback) => {

    return (dispatch, getState) => {
        axios.get(Config.BACKEND_ENDPOINT+'/alianza').then(resp => {
            
            //guardo en redux
            const alianzas = resp.data.data;
            dispatch({type: Reducers.ALIANZA_GUARDAR, data: alianzas });
            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const alianzaObtenerAlianza = (data, callback) => {
    return (dispatch, getState) => {
        axios.get(`${Config.BACKEND_ENDPOINT}/alianza/${data.idAlianzas}`).then(resp => {

            //guardo en redux
            const alianza = resp.data.data;
            dispatch({type: Reducers.ALIANZA_GUARDAR_ID, data: alianza });
            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}


const alianzaAgregar = (data,callback) => {

    return (dispatch, getState) => {
        axios.post(Config.BACKEND_ENDPOINT+'/alianza', data).then(resp => {
            
            //guardo en redux
            const nueva_alianza = resp.data.data;
            dispatch({type: Reducers.ALIANZA_AGREGAR, data: nueva_alianza });
            return callback(false, resp.data);
            
        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
    
}

const alianzaEditar = (data,callback) => {

    return (dispatch, getState) => {
        axios.put(`${Config.BACKEND_ENDPOINT}/alianza/${data.idAlianzas}`, data).then(resp => {
            
            //guardo en redux
            const alianza_editado = resp.data.data;
            dispatch({type: Reducers.ALIANZA_EDITAR, data: alianza_editado });
            return callback(false, resp.data);
            
        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
    
}

const alianzaBorrar = (data,callback) => {

    return (dispatch, getState) => {
        axios.delete(`${Config.BACKEND_ENDPOINT}/alianza/${data.idAlianzas}`).then(resp => {
            
            //guardo en redux
            const alianza_borrado = resp.data.data;
            dispatch({type: Reducers.ALIANZA_BORRAR, data: alianza_borrado });
            return callback(false, resp.data);
            
        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
    
}

export { 
    alianzaObtenerAlianzas,
    alianzaObtenerAlianza, 
    alianzaAgregar,
    alianzaEditar,
    alianzaBorrar
};