/*
    Author: Luciano Ghione  
    Scope: Web
    Version: 1.0.0
    ModalLiteUI is a react function component based on Material UI 
    which is used to handle modals with ease.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { 
    Modal, 
    Card, 
    CardHeader, 
    CardContent, 
    Divider, 
    IconButton,
    Grow,
    withStyles 
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const ModalLiteUI_ = (props) => {

    let {
        classes,
        open,
        title,
        subtitle="",
        modalHandler,
        id,
        aditional_param = null,
        children
    } = props;

    return (
        <Modal 
            open={open} 
            disableRestoreFocus 
            onBackdropClick={() => modalHandler(false,id,aditional_param)}
            onEscapeKeyDown={() => modalHandler(false,id,aditional_param)}
        >
            <Grow in={open} timeout={300}>
                <div className={classes.wrapper}>
                    <Card className={classes.card}>
                        <CardHeader
                            title={title}
                            subheader={subtitle}
                            classes={{root: classes.card_header,action: classes.card_action_button, title: classes.card_header_title}}
                            action={
                                <IconButton onClick={() => modalHandler(false,id,aditional_param)}>
                                    <ClearIcon />
                                </IconButton>
                            }
                        />
                        <Divider/>
                        <CardContent className={classes.card_content}>
                           {children}
                        </CardContent>
                    </Card>
                </div>
            </Grow>
        </Modal>
    );
}

const styles = theme => ({
    wrapper: {
        outline: 'none !important',
        overflow: 'auto',
        maxHeight:'95%',
        width: '95%',
        [theme.breakpoints.up('sm')]: {
            width: '600px',
            marginTop: '1%',
        },
        marginTop: '3%',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    card: {
        borderRadius: '4px',
        outline: 'none !important',
    },
    card_content: theme.card.card_content,
    card_action_button: theme.card.card_action_button,
    card_header: theme.card.card_header,
    card_header_title: theme.card.card_header_title
});

ModalLiteUI_.propTypes = {
    
    //Object used to add some styling with withStyles
    classes: PropTypes.object,

    //true to open the modal
    open: PropTypes.bool.isRequired,

    //The title of the modal
    title: PropTypes.string.isRequired,

    //The subtitle of the modal
    //Default: ""
    subtitle: PropTypes.node,
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    //The id of the modal
    //It's passed as argument in modalHandler and handleSubmit
    //To identify the modal 
    id: PropTypes.string.isRequired,

    //The aditional param which 
    //is passed in modalHandler
    //Default: null
    aditional_param: PropTypes.any,

    //The content of the modal
    children: PropTypes.node.isRequired

};

export const ModalLiteUI = withStyles(styles)(ModalLiteUI_);
