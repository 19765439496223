import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { ModalLiteUI } from '../../../../../common';
import { Validate } from '../../../../../../other';

const ModalProductosEnviados = (props) => {

    let { 
        productos_enviados,
        modalHandler,
        modal_state
    } = props;

    const ShippingWrapper = ({children}) => {
        return (
            <div style={{backgroundColor: '#f5f5f5', padding: '10px', margin: '10px 0px'}}>
                {children}
            </div>
        );
    }

    let peso_total = 0;
    let volumen_total = 0;
    let costo_total = 0;

    const productos_lista = (productos_enviados) => {
        if(productos_enviados && productos_enviados.length>0)
        {
            return productos_enviados.map((item, index) => {
                peso_total += (item.item_quantity * item.item_weight);
                volumen_total += (item.item_width * item.item_height * item.item_depth * item.item_quantity);
                costo_total += (item.item_declared_value * item.item_quantity)
                return(
                    <ShippingWrapper key={index}>
                        <List>
                            <ListItem>
                                <ListItemText primary={<span><strong>{'Nombre: '}</strong>{item.item_name}</span>}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<span><strong>{'Ancho: '}</strong>{item.item_width}cm</span>}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<span><strong>{'Alto: '}</strong>{item.item_height}cm</span>}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<span><strong>{'Profundidad: '}</strong>{item.item_depth}cm</span>}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<span><strong>{'Peso: '}</strong>{item.item_weight}kg</span>}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<span><strong>{'Cantidad: '}</strong>{item.item_quantity}</span>}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<span><strong>{'Precio: '}</strong>${Validate.number_format(item.item_declared_value)}</span>}/>
                            </ListItem>
                        </List>
                    </ShippingWrapper>
                );
            });
        }
        else
        {
            return (
                <List>
                    <ListItem>
                        <ListItemText primary="No se ha encontrado información" />
                    </ListItem>
                </List>
            );
        }
    }
    
    return (
        <ModalLiteUI 
            open={modal_state}
            title="Productos enviados"
            id="productos_enviados"
            modalHandler={modalHandler}
            aditional_param="productos_enviados"
        >
            {productos_lista(productos_enviados)} 
            <ShippingWrapper>
                <List>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Peso total: '}</strong>{peso_total}kg</span>}/>
                    </ListItem>
                </List>
            </ShippingWrapper>
            <ShippingWrapper>
                <List>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Volumen total: '}</strong>{volumen_total}cm3</span>}/>
                    </ListItem>
                </List>
            </ShippingWrapper>
            <ShippingWrapper>
                <List>
                    <ListItem>
                        <ListItemText primary={<span><strong>{'Valor total declarado: '}</strong>${Validate.number_format(costo_total)}</span>}/>
                    </ListItem>
                </List>
            </ShippingWrapper>
        </ModalLiteUI>
    );
}

ModalProductosEnviados.propTypes = {
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    //true if the modal is open
    modal_state: PropTypes.bool.isRequired,

    //The object initialized in state 
    productos_enviados: PropTypes.array.isRequired,
};

export { ModalProductosEnviados };
