import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ModalUI, SelectUI } from '../../../../../common';

const ModalReenvio = (props) => {

    let { 
        reenviar, 
        handleSubmit,
        handleChangeSelect,
        modalHandler,
        modal_state,
        form_state,
        selects
    } = props;

    return (
        <ModalUI 
            open={modal_state}
            id="reenviar"
            title={"Generar nuevo envío para orden #"+reenviar.shipment_reference.value}
            subtitle="Genera un nuevo envío para la orden seleccionada y actualiza la orden de Empretienda"
            modalHandler={modalHandler}
            aditional_param="reenviar"
            handleSubmit={handleSubmit}
            button_label="Generar"
            button_disabled={false}
            button_loading={form_state}
        >
            <Grid container spacing={24} direction='row'>
                <Grid item xs={12}>
                    <SelectUI
                        data={reenviar.require_payment}
                        options={selects.si_no}
                        handleChangeSelect={handleChangeSelect}
                    />
                </Grid>
            </Grid>
        </ModalUI>
    );
}

ModalReenvio.propTypes = {

    //contains the form info
    reenviar: PropTypes.object.isRequired,

    //function called when user confirms the action
    handleSubmit: PropTypes.func.isRequired,

    //function called when user changes the select
    handleChangeSelect: PropTypes.func.isRequired,

    //indicates whether or not the user has submited the form
    form_state: PropTypes.bool.isRequired,

    //stores the options
    selects: PropTypes.object.isRequired,
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    //true if the modal is open
    modal_state: PropTypes.bool.isRequired,

};

export { ModalReenvio };
