/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const cuponLeadsReducer = (state = initState,action) => {
    let leads;
    switch(action.type)
    {
        case Reducers.CUPON_LEADS_GUARDAR:

            leads = action.data;     
            return leads;

        break;

        case Reducers.CUPON_LEADS_ABONADO:

            let lead_item = state[0].leads.map( item => {
                if (item.Tienda_idTienda === action.data.Tienda_idTienda){
                    return {
                        ...item,
                        cat_pago: 1
                    };
                } else {
                    return item;
                }
            });
            
            return [{
                ...state[0],
                leads: lead_item
            }];

        break;
        
        case Reducers.AUTH_UNSET:

            return initState;

        break;

        default: 

            return state;

        break;
    }
}

export { cuponLeadsReducer };