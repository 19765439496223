import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    withStyles,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    CardHeader,
    Divider,
    IconButton,
    Icon
} from '@material-ui/core';
import { 
    AlertUI
} from '../../common';
import { 
    disposableBuscarEmail,
    disposableBorrarEmail
} from '../../../store/actions';
import { ModalBorrar } from './';

class ResultadoEmail_ extends Component
{   
    state={
        data_response: {},
        data_busqueda: '',
        loading: {
            email: true,
            error: false
        },
        alert: {
            open: false,
            type: 'error',
            message: ''
        },
        modals: {
            delete: false,
        },
        forms: {
            delete: false
        }
    };

    componentDidMount() 
    {
        this.obtenerEmail();
    }

    componentDidUpdate() 
    {
        this.obtenerEmail();
    }

    obtenerEmail = () => {
        if(this.state.data_busqueda !== this.props.busqueda)
        {
            this.props.isLoading(true);
            this.setState({
                data_busqueda: this.props.busqueda,
                data_response: {},
                loading: {
                    ...this.state.loading,
                    email: true
                }
            }, () => {
                this.props.disposableBuscarEmail({
                    email: this.props.busqueda,
                }, (err,resp) => {
                    this.props.isLoading(false);
                    this.setState({
                        data_response: err ? {} : resp.data,
                        loading: {
                            ...this.state.loading,
                            email: false
                        },
                        alert: {
                            open: err ? true : false,
                            message: resp.message,
                            type: 'error'
                        }
                    });
                });
            });
        }
    }

    //cierra las alertas
    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    } 

    modalHandler = (open, modal, aditional) => {
        this.setState({
            modals:{
                ...this.state.modals,
                [modal]: open
            }
        });
    }

    handleSubmitRemover = (e) => {
        e.preventDefault();

        this.setState({
            forms: {
                ...this.state.forms,
                delete: true
            }
        });

        this.props.disposableBorrarEmail({
            email: this.state.data_response.email
        }, (err,resp) => {

            this.setState({
                data_response: err ? this.state.data_response : {},
                forms: {
                    ...this.state.forms,
                    delete: false,
                },
                alert: {
                    open: true,
                    message: resp.message,
                    type: err ? 'error' : 'success'
                }
            });

        });
    }
 
    render()
    {
        let {classes} = this.props;

        if(this.state.loading.email || this.state.loading.error || this.state.data_response.email===undefined)
        {
            return (
                <Card className={classes.card_below}>
                    <CardHeader
                        title="Email"
                        className={classes.card_header}
                        classes={{action: classes.card_action_button}}
                    />
                    <Divider/>
                    <AlertUI
                        open={this.state.alert.open}
                        message={this.state.alert.message}
                        type={this.state.alert.type}
                        handleCloseAlert={this.handleCloseAlert}
                    />
                    <CardContent className={classes.card_content}>
                        <List>
                            <ListItem>
                                <ListItemText primary="No se han encontrado datos" />
                            </ListItem> 
                        </List>
                    </CardContent>
                </Card>
            )
        } 
        else
        {
            return (
                <Fragment>
                    <ModalBorrar 
                        data_response={this.state.data_response}
                        handleSubmit={this.handleSubmitRemover}
                        modalHandler={this.modalHandler}
                        modal_state={this.state.modals.delete}
                        form_state={this.state.forms.delete}
                    />
                    <Card className={classes.card_below}>
                        <CardHeader
                            title="Email"
                            className={classes.card_header}
                            classes={{action: classes.card_action_button}}
                        />
                        <Divider/>
                        <AlertUI
                            open={this.state.alert.open}
                            message={this.state.alert.message}
                            type={this.state.alert.type}
                            handleCloseAlert={this.handleCloseAlert}
                        />
                        <CardContent className={classes.card_content}>
                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary={this.state.data_response.email}
                                        classes={{root: classes.list_text}}
                                        secondary={
                                            <span className={classes.span}>
                                                <span className={classes.span}><strong>Tipo de bloqueo:</strong> {this.state.data_response.type==="bounce" ? "Imposibilidad de entrega" : "Nos marco como spam. Pedir que nos desmarque como spam antes de desbloquear."}</span>
                                                <span className={classes.span}><strong>Descripción:</strong></span>
                                                <span className={classes.span}>{this.state.data_response.description}</span>
                                            </span>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton aria-label="Borrar" onClick={(e) => this.modalHandler(true,'delete',null)}>
                                            <Icon>clear</Icon>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem> 
                            </List>
                        </CardContent>
                    </Card>
                </Fragment>
            );
        }
    }
}

const styles = theme => ({
    span: {
        display: 'block',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    list: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '0',
        '&:last-child': {
            borderBottom: '0px solid transparent'
        }
    },
    list_text: {
        paddingRight: '100px'
    },
    table: {
        minWidth: '100%',
    },    
    card: theme.card.card,
    card_header: theme.card.card_header,
    card_header_title: theme.card.card_header_title,
    card_actions: theme.card.card_actions_public,
    card_content: {
        padding: 0,
        '&:last-child': {
            padding: 0
        }
    },

});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
    return {
        disposableBuscarEmail: (data,callback) => dispatch(disposableBuscarEmail(data,callback)),
        disposableBorrarEmail: (data,callback) => dispatch(disposableBorrarEmail(data,callback)),
    }
}

ResultadoEmail_.propTypes = {

    isLoading: PropTypes.func.isRequired,

    //Parámetro de búsqueda que recibe el componente por props
    busqueda: PropTypes.string.isRequired

};

export const ResultadoEmail = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(ResultadoEmail_));
