import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { ModalUI } from '../../common';

const ModalBorrar = (props) => {

    let { 
        data_response,
        handleSubmit,
        modalHandler,
        modal_state,
        form_state
    } = props;
  
    return (
        <ModalUI 
            open={modal_state}
            id="delete"
            title="Remover de blacklist"
            modalHandler={modalHandler}
            aditional_param="delete"
            handleSubmit={handleSubmit}
            button_label="Remover"
            button_disabled={false}
            button_loading={form_state}
        >
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Typography>
                        ¿Estás seguro de que quieres remover el email <strong>{data_response.email}</strong> de blacklist? 
                    </Typography>
                </Grid>
            </Grid>
        </ModalUI>
    );
}

ModalBorrar.propTypes = {

    //The form submit function
    //Called: handleSubmit(event,id)
    handleSubmit: PropTypes.func.isRequired,
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    //true if the modal is open
    modal_state: PropTypes.bool.isRequired,

    //true if the modal is open
    form_state: PropTypes.bool.isRequired,

    //The object initialized in state 
    data_response: PropTypes.shape({
        email: PropTypes.string.isRequired,
    })
};

export { ModalBorrar };
