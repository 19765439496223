/*
    Author: Luciano Ghione  
    Scope: Web
    Version: 1.0.0
    AutosuggestUI is a react function component based on Material UI and react-autosuggest 
    which is used to handle searching and autocompletion stuff.
*/
import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { MenuItem, TextField, Paper, Chip, withStyles } from '@material-ui/core';

const renderInputComponent = (inputProps) => {

    const { 
        classes, 
        inputRef = () => {}, 
        ref, 
        multiple_option,
        multiple_chipdata,
        multiple_deletefunc,
        multiple_suggestion_index, 
        ...other 
    } = inputProps;

    if(multiple_option)
    {
        return (
            <TextField
                variant="outlined"
                fullWidth
                multiline
                InputProps={{
                    inputRef: node => {
                        ref(node);
                        inputRef(node);
                    },
                    classes: {
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    },
                    startAdornment: multiple_chipdata.map((item,index) => (
                        <Chip
                            key={index}
                            tabIndex={-1}
                            label={item[multiple_suggestion_index]}
                            className={classes.chip}
                            onDelete={() => multiple_deletefunc(item)}
                        />
                    ))
                }}
                {...other}
            />
        );
    }
    else
    {
        return (
            <TextField
                variant="outlined"
                fullWidth
                InputProps={{
                    inputRef: node => {
                        ref(node);
                        inputRef(node);
                    },
                    classes: {
                        input: classes.input,
                    },
                }}
                {...other}
            />
        );
    }
}
const renderSuggestion = (suggestion_index, suggestion, { query, isHighlighted }) => {

    let value = suggestion[suggestion_index];
    const matches = match(value, query);
    const parts = parse(value, matches);
  
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map((part, index) =>
            part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 500 }}>
                {part.text}
              </span>
            ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            ),
          )}
        </div>
      </MenuItem>
    );
}
const getSuggestionValue = (suggestion_index,suggestion) => {
    return suggestion[suggestion_index];
}

const AutosuggestUI_ = (props) => {

    let {  
        suggestions,
        suggestion_selected, 
        input_value,
        suggestion_index,
        label,
        placeholder,
        classes,
        handleOnSuggestionSelected,
        handleSuggestionsFetchRequested,
        handleSuggestionsClearRequested,
        handleSuggestionsChangeSelected,
        //below options are needed
        //if you want chip-like autocomplete
        multiple = false,
        handleDeleteChip = () => {},
    } = props;

    const autosuggestProps = {
        renderInputComponent,
        suggestions: suggestions,
        onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
        onSuggestionsClearRequested: handleSuggestionsClearRequested,
        getSuggestionValue: (suggestion) => getSuggestionValue(suggestion_index,suggestion),
        onSuggestionSelected: (e, { suggestion }) => handleOnSuggestionSelected(suggestion),
        renderSuggestion: (suggestion, args) => renderSuggestion(suggestion_index,suggestion, args)
    };

    return (
        <Autosuggest
            {...autosuggestProps}
            inputProps={{
                classes,
                multiple_option: multiple ? 1 : 0,
                multiple_chipdata: multiple ? suggestion_selected : [],
                multiple_deletefunc: handleDeleteChip,
                multiple_suggestion_index: suggestion_index,
                placeholder: placeholder,
                value: input_value,
                onChange: handleSuggestionsChangeSelected,
                label: label,
                InputLabelProps: {
                    shrink: true
                }
            }}
            theme={{
                container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion
            }}
            renderSuggestionsContainer={options => (
                <Paper {...options.containerProps} square>
                    {options.children}
                </Paper>
            )}
        />
    );
}

const styles = theme => ({
    container: {
        position: 'relative',
        overflow: 'visible'
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 2,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block'
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    chip: {
        margin: `0px ${theme.spacing.unit / 2}px ${theme.spacing.unit*2}px ${theme.spacing.unit / 2}px`,
    },
    inputRoot: {
        flexWrap: 'wrap',
        boxSizing: 'border-box'
    },
    inputInput: {
        boxSizing: 'border-box',
        display: 'flex',
        flex: 1
    },
});


/*
    Usage:

    importing:

        import {debounce} from 'lodash';

    stating:
        state = {
            suggestions = [] //array of filtered results
            suggestion_selected = ''; //valor del input
        }
        debounceMethod = null;
        componentDidMount()
        {
            this.debounceMethod = debounce(this.handleSuggestionFetchData, 500);
        }

    functions:
        handleOnSuggestionSelected = (selected_suggestion) => {
            console.log(selected_suggestion);
        }
        handleSuggestionFetchData = (value) => {
            const inputValue = value.trim().toLowerCase();
            const inputLength = inputValue.length;
            let count = 0;

            console.log('ok go fetch ',value);

            axios.get('https://api.graficamilcolores.com/api/categorias').then((resp) => {

                let feed = resp.data.data;

                //to filter requested data:
                let suggestionsFetched = (inputLength === 0) ? [] : feed.filter(suggestion => {
                    count++;
                    return count<=20 && suggestion.nombre.toLowerCase().search(inputValue)>=0;
                });

                this.setState({
                    suggestions: suggestionsFetched
                });

            }).catch(err => console.log('error'));
        }
        handleSuggestionsFetchRequested = ({ value }) => {
            //funcion llamada cada vez que se escribe 
            //retraso el request para evitar multiples requests a la api
            this.debounceMethod(value);
        };
        handleSuggestionsClearRequested = () => {
            //usado para limpiar
            this.setState({
                suggestions: []
            });
        };
        handleSuggestionsChangeSelected = (e,{newValue}) => {
            console.log('writed: ', newValue);
            this.setState({
                suggestion_selected: newValue
            });
        };

        rendering:
            <AutosuggestUI
                suggestions={this.state.suggestions}
                suggestion_selected={this.state.suggestion_selected}
                suggestion_index="nombre"
                label="Buscar categoria"
                placeholder="Ej: Troqueladas"
                handleOnSuggestionSelected={this.handleOnSuggestionSelected}
                handleSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                handleSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                handleSuggestionsChangeSelected={this.handleSuggestionsChangeSelected}

                //optionals for multiple
                multiple = false,
                handleDeleteChip = () => {},
            />
*/

AutosuggestUI_.propTypes = {
    
    //Object used to add some styling with withStyles
    classes: PropTypes.object,

    //The list of objects fetched for handleSuggestionsFetchRequested
    suggestions: PropTypes.array.isRequired, 

    //The value of the autocomplete input
    input_value: PropTypes.string.isRequired,

    //The selected/s value/s (string or array for multiple)
    suggestion_selected: PropTypes.any,

    //Index of the object of suggestions we want to search in
    suggestion_index: PropTypes.string.isRequired,

    //Label of the input
    label: PropTypes.string.isRequired,

    //Placeholder of the input
    placeholder: PropTypes.string.isRequired,

    //This function is fired when an option is selected
    //And sends the entire object selected to be processed
    handleOnSuggestionSelected: PropTypes.func.isRequired,

    //This function which makes the request or not
    //and filter the options regarding the suggestion_selected
    //And store them on suggestions
    handleSuggestionsFetchRequested: PropTypes.func.isRequired,

    //This function has to clear the suggestions array
    handleSuggestionsClearRequested: PropTypes.func.isRequired,

    //This function has to update the controlled input
    //suggestion_selected
    handleSuggestionsChangeSelected: PropTypes.func.isRequired,

    //Defines whether or not the input supports multiple options
    //Default: false
    multiple: PropTypes.bool,

    //This function will be used to delete chips if needed 
    //(only needed if multiple is set to true)
    //Default: () => {}
    handleDeleteChip: PropTypes.func,

};

export const AutosuggestUI = withStyles(styles)(AutosuggestUI_);