/* eslint-disable react/jsx-no-duplicate-props */
/*
    Author: Luciano Ghione  
    Scope: Web
    Version: 1.0.0
    DateTimeUI is a react function component based on Material UI which is used to handle 
    user advanced controlled inputs in an easy way.
*/
import React from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import {withStyles} from '@material-ui/core';
import { Config } from '../../other';
import moment from "moment";
import 'moment/locale/es';

const DateTimeUI_ = (props) => {

    let {
        classes, 
        handleChangeDate, 
        inputdate,
        dateTimeProps = {},
        date_format = Config.DATE_FORMAT
    } = props;
    
    let {
        momentobj,
        label,
        name, 
        change_param
    } = inputdate;
    
    return (
        <div className={classes.root}>
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale="es">
                    <DateTimePicker
                        ampm
                        fullWidth
                        margin="dense"
                        value={momentobj}
                        variant="outlined"
                        onChange={(newmomentobj) => handleChangeDate(newmomentobj,name,change_param)}
                        label={label}
                        format={date_format}
                        todayLabel="Hoy"
                        cancelLabel="Cancelar"
                        clearLabel="Reset"
                        okLabel="Aceptar"
                        invalidLabel="Por favor, ingrese una fecha válida"
                        invalidDateMessage="Por favor, ingrese una fecha válida"
                        {...dateTimeProps}
                    />
            </MuiPickersUtilsProvider>
        </div>
    );


}

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

DateTimeUI_.propTypes = {

    //Object used to add some styling with withStyles
    classes: PropTypes.object,

    //Function used to update controlled input
    //handleChangeDate(momentobj,name,change_param = null)
    handleChangeDate: PropTypes.func.isRequired,

    //The object initialized in state
    inputdate: PropTypes.shape({
        validate:       PropTypes.bool.isRequired,
        momentobj:      PropTypes.any.isRequired,
        label:          PropTypes.string.isRequired,
        name:           PropTypes.string.isRequired,
        change_param:   PropTypes.any
    }),

    dateTimeProps:  PropTypes.object

};

export const DateTimeUI = withStyles(styles)(DateTimeUI_);