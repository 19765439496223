import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    withStyles,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    CardHeader,
    Divider,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Typography,
    IconButton,
    Icon
} from '@material-ui/core';
import { 
    obtenerFacturacion,
    editarUltimaFactura
} from '../../../store/actions';
import {
    ToggleUI
} from '../../common';
import { 
    ModalEditar
} from './subfacturacion';
import { 
    AlertUI
} from '../../common';
import { Validate, Regex, RegexExtra, Config } from '../../../other';

class Facturacion_ extends Component
{   
    state={
        id_tienda: 0,
        data_response: {
           
        },
        factura_edit_response: null,
        loading: {
            tienda: true,
            error: false
        },
        //campos para modal editar
        editar: {
            dias: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'number',
                label: 'Días a agregar',
                id: 'editar_dias',
                name: 'dias',
                change_param: 'editar',
                regex: Regex.NUMERO_ENTERO,
                value: '',
                default_value: '',
                placeholder: 'Ingrese días',
                messages: {
                    error: 'Por favor, ingrese un número de días válido',
                    error_extra: RegexExtra.NUMERO_ENTERO
                }
            },
            tipo_editar: {
                auth: true,
                validate: false,
                required: true,
                error: false,
                label: 'Tipo',
                id: 'tipo_editar',
                name: 'tipo_editar',
                change_param: 'editar',
                value: -1,
                default_value: -1,
                messages: {
                    error: ''
                },
            },
        },
        selects: {
            tipo_editar: [
                {
                    value: -1,
                    label: 'Seleccione una opción'
                },
                {
                    value: 0,
                    label: 'Reiniciar días'
                },
                {
                    value: 1,
                    label: 'Extender días'
                }
            ]
        },
        //modales
        modals: {
            editar: false,
        },
        forms: {
            editar: false,
        },
        alert: {
            open: false,
            type: 'error',
            message: ''
        }
    };

    componentDidMount() 
    {
        this.handleChangeId();
    }

    componentDidUpdate() 
    {
        this.handleChangeId();
    }


    handleChangeId = () => {
        if(this.state.id_tienda !== this.props.id_tienda)
        {
            this.setState({
                id_tienda: this.props.id_tienda,
                factura_edit_response: null,
                loading: {
                    ...this.state.loading,
                    tienda: true,
                    error: false
                }
            }, () => {
                this.props.isLoading(true);
                this.props.obtenerFacturacion({
                    xIdTienda: this.state.id_tienda,
                }, (err,resp) => {
                    this.props.isLoading(false);
                    this.setState({
                        data_response: resp.data,
                        loading: {
                            ...this.state.loading,
                            tienda: false,
                            error: (this.state.loading.error || err) ? true : false
                        }
                    });
                });
            });
        }
    }

    handleChange = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            }
        },() => {
            Validate.validate_input(this.state[aditional][name],blur,(input) => {
                this.setState({
                    [aditional]: {
                        ...this.state[aditional],
                        [name]: input
                    }
                });
            });
        });
    }

    handleChangeSelect = (e,aditional) => {

        let value = Validate.trim_int(e.target.value);
        let name = e.target.name;
        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            },
        });

    }

    handleSubmitEditar = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                editar: true
            }
        });
        let form = this.state.editar;

        if(this.state.editar.tipo_editar.value!==-1)
        {
            if(Validate.validar_formulario(form))
            {
                this.props.editarUltimaFactura({
                    dias: form.dias.value,
                    tipo: form.tipo_editar.value,
                    xIdTienda: this.state.id_tienda,
                },(err,resp) => {
    
                    if(err)
                    {
                        this.setState({
                            forms: {
                                ...this.state.forms,
                                editar: false
                            },
                            alert: {
                                open: true,
                                message: resp.message,
                                type: 'error'
                            }
                        });
                    }
                    else
                    {
                        this.setState({
                            factura_edit_response: resp.data,
                            forms: {
                                ...this.state.forms,
                                editar: false
                            },
                            alert: {
                                open: true,
                                message: resp.message,
                                type: 'success'
                            },
                            modals: {
                                ...this.state.modals,
                                editar: false
                            }
                        },() => {
                            let form_obj = Validate.limpiar_formulario(this.state.editar);
                            this.setState({
                                editar: form_obj
                            });
                        });
    
                    }
                });
            }
            else
            {
                Validate.validar_formulario_actualizar_obj(form,(new_form) => {
                    this.setState({
                        editar: new_form,
                        forms: {
                            ...this.state.forms,
                            editar: false
                        },
                        alert: {
                            ...this.state.alert,
                            open: true,
                            message: 'Por favor, revisá tus datos, tenés algunos errores',
                            type: 'error'
                        }
                    });
                });
            }
        }
        else
        {
            this.setState({
                forms: {
                    ...this.state.forms,
                    editar: false
                },
                alert: {
                    open: true,
                    message: 'Por favor, seleccione una opción válida',
                    type: 'error'
                }
            });
        }
    }

    //abre y cierra los modales
    modalHandler = (open,id,aditional) => {
        switch(id) 
        {
            case 'editar':
                if(open)
                {
                    let dias = aditional;
                    Validate.cargar_formulario_sin_nulos(dias, Validate.limpiar_formulario(this.state.editar), (editar_obj) => {
                        this.setState({
                            editar: editar_obj,
                            modals: {
                                ...this.state.modals,
                                [id]: open
                            }
                        });
                    });
                }
                else
                {
                    this.setState({
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
            break;
            default:
            break;
        }
    }

    //cierra las alertas
    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }    

    render()
    {
        let {classes} = this.props;
        if(this.state.loading.tienda===false && this.state.loading.error === false)
        {
            let { df_nombre, df_documento, df_tipo_documento, df_direccion, df_direccion_extra, df_localidad, df_provincia, df_postal } = this.state.data_response.datosfacturacion;
            let data_facturas = this.state.data_response.facturas;
            let data_factura = this.state.data_response.factura;
            let body;
            //Si el id del primer elemento de "facturas" no coincide con el que viene en “factura”, se coloca al inicio de la lista como un nuevo elemento
            if(data_facturas.length)
            {
                if(data_facturas[0].id!==data_factura.id)
                {
                    data_facturas = [
                        data_factura,
                        ...data_facturas
                    ];
                }
            }
            else
            {
                data_facturas = [
                    data_factura,
                ];
            }

            if(data_facturas.length)
            {
                if(this.state.factura_edit_response!== null)
                {
                    data_facturas[0] = this.state.factura_edit_response;
                }
                body = data_facturas.map((factura,idx) => {

                    let table_class = (data_facturas.length-1)===idx ? classes.table_cell_last : classes.table_cell;                
                    return (
                        <TableRow hover key={idx}>
                            <TableCell classes={{root: table_class}}>{ factura.id }</TableCell>
                            <TableCell classes={{root: table_class}}>${Validate.number_format(factura.monto)}</TableCell>
                            <TableCell classes={{root: table_class}}>{ factura.fecha_vencimiento }</TableCell>
                            <TableCell classes={{root: table_class}}>{ factura.fecha_corte }</TableCell>
                            <TableCell classes={{root: table_class}}>{ factura.paga===1 ? 'Sí' : 'No'}</TableCell>
                            <TableCell classes={{root: table_class}}>{ Validate.is_falsy(factura.fecha_pago) ? 'N/A' : Validate.utc2local_format(factura.fecha_pago,Config.DATE_FORMAT)}</TableCell>
                            <TableCell classes={{root: table_class}}>{Validate.is_falsy(factura.facturada) ? 'N/A' : (factura.facturada === 1 ? 'Sí' : 'No')}</TableCell>
                            <TableCell classes={{root: table_class}}>
                                <ToggleUI show={idx===0}>
                                    <IconButton aria-label="Editar" onClick={(e) => this.modalHandler(true,'editar',this.state.data_response)}>
                                        <Icon>edit</Icon>
                                    </IconButton>
                                </ToggleUI>
                            </TableCell>
                        </TableRow>
                    );
                });
            }

            return(
                <Fragment>
                    <ToggleUI show={this.state.data_response.facturacion!==1}>
                        <Card className={classes.card}>
                            <CardHeader
                                title="Datos de facturación"
                                className={classes.card_header}
                                classes={{action: classes.card_action_button}}
                            />
                            <Divider/>
                            <CardContent className={classes.card_content_no_padding}>
                                <List>
                                    <ListItem>
                                            <ListItemText primary="No se cargaron datos de facturación" />
                                    </ListItem> 
                                </List>
                            </CardContent>
                        </Card>
                    </ToggleUI>

                    <ToggleUI show={this.state.data_response.facturacion===1 && this.state.data_response.facturacion!==undefined && this.state.loading.tienda===false}>
                        <Card className={classes.card}>
                            <CardHeader
                                title="Datos de facturación"
                                className={classes.card_header}
                                classes={{action: classes.card_action_button}}
                            />
                            <Divider/>
                            <CardContent className={classes.card_content_no_padding}>
                                <Table className={classes.table} padding="dense">
                                    <TableBody>
                                        <TableRow hover>
                                            <TableCell>Razón social</TableCell>
                                            <TableCell classes={{root: classes.table_class}}>{Validate.is_falsy(df_nombre) ? 'N/A' : df_nombre}</TableCell>
                                        </TableRow>
                                        <TableRow hover>
                                            <TableCell>Tipo de documento</TableCell>
                                            <TableCell classes={{root: classes.table_class}}>{Validate.is_falsy(df_tipo_documento) ? 'N/A' : df_tipo_documento}</TableCell>
                                        </TableRow>
                                        <TableRow hover>
                                            <TableCell>Número de documento</TableCell>
                                            <TableCell classes={{root: classes.table_class}}>{Validate.is_falsy(df_documento) ? 'N/A' : df_documento}</TableCell>
                                        </TableRow>
                                        <TableRow hover>
                                            <TableCell>Dirección</TableCell>
                                            <TableCell classes={{root: classes.table_class}}>{Validate.is_falsy(df_direccion) ? 'N/A' : df_direccion}</TableCell>
                                        </TableRow>
                                        <TableRow hover>
                                            <TableCell>Piso y departamento</TableCell>
                                            <TableCell classes={{root: classes.table_class}}>{Validate.is_falsy(df_direccion_extra) ? 'N/A' : df_direccion_extra}</TableCell>
                                        </TableRow>
                                        <TableRow hover>
                                            <TableCell>Localidad</TableCell>
                                            <TableCell classes={{root: classes.table_class}}>{Validate.is_falsy(df_localidad) ? 'N/A' : df_localidad}</TableCell>
                                        </TableRow>
                                        <TableRow hover>
                                            <TableCell>Provincia</TableCell>
                                            <TableCell classes={{root: classes.table_class}}>{Validate.is_falsy(df_provincia) ? 'N/A' : df_provincia}</TableCell>
                                        </TableRow>
                                        <TableRow hover>
                                            <TableCell>Código postal</TableCell>
                                            <TableCell classes={{root: classes.table_class}}>{Validate.is_falsy(df_postal) ? 'N/A' : df_postal}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </ToggleUI>

                    <ToggleUI show={this.state.loading.error===false && this.state.loading.tienda===false}>
                        <Card className={classes.card}>
                            <CardHeader
                                title="Facturas"
                                className={classes.card_header}
                                classes={{action: classes.card_action_button}}
                            />
                            <Divider/>
                            <ModalEditar
                                editar={this.state.editar}
                                handleSubmit={this.handleSubmitEditar}
                                handleChange={this.handleChange}
                                modalHandler={this.modalHandler}
                                modal_state={this.state.modals.editar}
                                form_state={this.state.forms.editar}
                                handleChangeSelect={this.handleChangeSelect}
                                selects={this.state.selects}
                                tipo={this.state.tipo}
                            />
                            <AlertUI
                                open={this.state.alert.open}
                                message={this.state.alert.message}
                                type={this.state.alert.type}
                                handleCloseAlert={this.handleCloseAlert}
                            />
                            <ToggleUI show={data_facturas.length}>
                                <CardContent className={classes.card_content_no_padding}>
                                    <Table className={classes.table} padding="dense">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Id</TableCell>
                                                <TableCell>Monto</TableCell>
                                                <TableCell>Fecha de vencimiento</TableCell>
                                                <TableCell>Fecha de corte</TableCell>
                                                <TableCell>Paga</TableCell>
                                                <TableCell>Fecha de pago</TableCell>
                                                <TableCell>Facturada</TableCell>
                                                <TableCell> </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {body}
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </ToggleUI>

                            <ToggleUI show={data_facturas.length===0}>
                                <Card className={classes.card}>
                                    <CardContent className={classes.card_content}>
                                        <Typography className={classes.list_item_title}>
                                            No se han encontrado facturas
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </ToggleUI>
                        </Card>
                    </ToggleUI>

                    <ToggleUI show={this.state.loading.error}>
                        <List>
                            <ListItem>
                                <ListItemText 
                                    primary="No se han encontrado datos"
                                    secondary="Por favor, ingrese un ID Tienda y un filtro"
                                />
                            </ListItem> 
                        </List>                        
                    </ToggleUI>
                </Fragment>
            );
        }
        else
        {
            return(
                <ToggleUI show={this.state.loading.error}>
                    <Card className={classes.card}>
                        <CardHeader
                            title="Tienda"
                            className={classes.card_header}
                            classes={{action: classes.card_action_button}}
                        />
                        <Divider/>
                        <CardContent className={classes.card_content_no_padding}>
                            <List>
                                <ListItem>
                                    <ListItemText 
                                        primary="No se han encontrado datos"
                                        secondary="Por favor, ingrese un ID Tienda y un filtro"
                                    />
                                </ListItem> 
                            </List>
                        </CardContent>
                    </Card>
                </ToggleUI>
            );
        }
    }
}

const styles = theme => ({
    span: {
        display: 'block',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    list: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '0',
        '&:last-child': {
            borderBottom: '0px solid transparent'
        }
    },
    list_text: {
        paddingRight: '100px'
    },
    table: {
        minWidth: '100%',
    },    
    card: {
        ...theme.card.card,
        padding: '0px !important',
        marginBottom: '20px',
    },
    card_content: theme.card.card_content,
    card_content_no_padding: {
        ...theme.card.card_content,
        padding: '0px !important',
        overflowX: 'auto'
    }
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
    return {
        obtenerFacturacion: (data,callback) => dispatch(obtenerFacturacion(data,callback)),
        editarUltimaFactura: (data,callback) => dispatch(editarUltimaFactura(data,callback))

    }
}

Facturacion_.propTypes = {

    obtenerFacturacion: PropTypes.func.isRequired,

    editarUltimaFactura: PropTypes.func.isRequired,

    isLoading: PropTypes.func.isRequired,

    id_tienda: PropTypes.string.isRequired

};

export const Facturacion = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(Facturacion_));
