import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ModalUI, InputUI, SelectUI } from '../../../common';

const ModalEditar = (props) => {

    let { 
        editar, 
        handleSubmit,
        handleChange, 
        handleChangeSelect,
        modalHandler,
        modal_state,
        form_state,
        selects
    } = props;

    return (
        <ModalUI 
            open={modal_state}
            id="editar"
            title="Editar cliente"
            modalHandler={modalHandler}
            aditional_param="editar"
            handleSubmit={handleSubmit}
            button_label="Editar"
            button_disabled={false}
            button_loading={form_state}
        >
            <Grid container spacing={24} direction='row'>
                <Grid item xs={6}>
                    <InputUI
                        handleChange={handleChange}
                        input={editar.customer_name}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputUI
                        handleChange={handleChange}
                        input={editar.customer_surname}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectUI
                        data={editar.customer_identification_type}
                        options={selects.identification_type}
                        handleChangeSelect={handleChangeSelect}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputUI
                        handleChange={handleChange}
                        input={editar.customer_identification}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputUI
                        handleChange={handleChange}
                        input={editar.customer_email}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputUI
                        handleChange={handleChange}
                        input={editar.customer_phone}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectUI
                        data={editar.customer_state}
                        options={selects.customer_state}
                        handleChangeSelect={handleChangeSelect}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputUI
                        handleChange={handleChange}
                        input={editar.customer_city}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputUI
                        handleChange={handleChange}
                        input={editar.customer_street}
                    />
                </Grid>
                <Grid item xs={6}>
                <InputUI
                        handleChange={handleChange}
                        input={editar.customer_street_number}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputUI
                        handleChange={handleChange}
                        input={editar.customer_floor}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputUI
                        handleChange={handleChange}
                        input={editar.customer_apartment}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputUI
                        handleChange={handleChange}
                        input={editar.customer_zipcode}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputUI
                        handleChange={handleChange}
                        input={editar.customer_country}
                    />
                </Grid>
            </Grid>
        </ModalUI>
    );
}

ModalEditar.propTypes = {

    //The form submit function
    //Called: handleSubmit(event,id)
    handleSubmit: PropTypes.func.isRequired,

    //Called: handleChange(event,id)
    handleChange: PropTypes.func.isRequired,

    handleChangeSelect: PropTypes.func.isRequired,
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    //true if the modal is open
    modal_state: PropTypes.bool.isRequired,

    //true if the modal is open
    form_state: PropTypes.bool.isRequired,

    selects: PropTypes.object.isRequired,

    //The object initialized in state 
    editar: PropTypes.shape({
        customer_name: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            disabled:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            type:           PropTypes.string.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            regex:          PropTypes.instanceOf(RegExp).isRequired,
            value:          PropTypes.string.isRequired,
            default_value:  PropTypes.string.isRequired,
            placeholder:    PropTypes.any,  
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
                error_extra: PropTypes.string.isRequired
            })
        }),
        customer_surname: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            disabled:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            type:           PropTypes.string.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            regex:          PropTypes.instanceOf(RegExp).isRequired,
            value:          PropTypes.string.isRequired,
            default_value:  PropTypes.string.isRequired,
            placeholder:    PropTypes.any,  
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
                error_extra: PropTypes.string.isRequired
            })
        }),
        customer_email: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            disabled:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            type:           PropTypes.string.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            regex:          PropTypes.instanceOf(RegExp).isRequired,
            value:          PropTypes.string.isRequired,
            default_value:  PropTypes.string.isRequired,
            placeholder:    PropTypes.any,  
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
                error_extra: PropTypes.string.isRequired
            }) 
        }),
        customer_identification_type: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            value:          PropTypes.string.isRequired,
            default_value:  PropTypes.string.isRequired,
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
            }) 
        }),
        customer_identification: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            disabled:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            type:           PropTypes.string.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            regex:          PropTypes.instanceOf(RegExp).isRequired,
            value:          PropTypes.string.isRequired,
            default_value:  PropTypes.string.isRequired,
            placeholder:    PropTypes.any,  
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
                error_extra: PropTypes.string.isRequired
            }) 
        }),
        customer_phone: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            disabled:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            type:           PropTypes.string.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            regex:          PropTypes.instanceOf(RegExp).isRequired,
            value:          PropTypes.string.isRequired,
            default_value:  PropTypes.string.isRequired,
            placeholder:    PropTypes.any,  
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
                error_extra: PropTypes.string.isRequired
            }) 
        }),
        customer_street: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            disabled:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            type:           PropTypes.string.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            regex:          PropTypes.instanceOf(RegExp).isRequired,
            value:          PropTypes.string.isRequired,
            default_value:  PropTypes.string.isRequired,
            placeholder:    PropTypes.any,  
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
                error_extra: PropTypes.string.isRequired
            }) 
        }),
        customer_street_number: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            disabled:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            type:           PropTypes.string.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            regex:          PropTypes.instanceOf(RegExp).isRequired,
            value:          PropTypes.string.isRequired,
            default_value:  PropTypes.string.isRequired,
            placeholder:    PropTypes.any,  
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
                error_extra: PropTypes.string.isRequired
            }) 
        }),
        customer_floor: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            disabled:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            type:           PropTypes.string.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            regex:          PropTypes.instanceOf(RegExp).isRequired,
            value:          PropTypes.string.isRequired,
            default_value:  PropTypes.string.isRequired,
            placeholder:    PropTypes.any,  
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
                error_extra: PropTypes.string.isRequired
            }) 
        }),
        customer_apartment: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            disabled:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            type:           PropTypes.string.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            regex:          PropTypes.instanceOf(RegExp).isRequired,
            value:          PropTypes.string.isRequired,
            default_value:  PropTypes.string.isRequired,
            placeholder:    PropTypes.any,  
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
                error_extra: PropTypes.string.isRequired
            }) 
        }),
        customer_state: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            value:          PropTypes.string.isRequired,
            default_value:  PropTypes.string.isRequired,
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
            }) 
        }),
        customer_city: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            disabled:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            type:           PropTypes.string.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            regex:          PropTypes.instanceOf(RegExp).isRequired,
            value:          PropTypes.string.isRequired,
            default_value:  PropTypes.string.isRequired,
            placeholder:    PropTypes.any,  
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
                error_extra: PropTypes.string.isRequired
            }) 
        }),
        customer_zipcode: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            disabled:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            type:           PropTypes.string.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            regex:          PropTypes.instanceOf(RegExp).isRequired,
            value:          PropTypes.string.isRequired,
            default_value:  PropTypes.string.isRequired,
            placeholder:    PropTypes.any,  
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
                error_extra: PropTypes.string.isRequired
            }) 
        }),
        customer_country: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            disabled:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            type:           PropTypes.string.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            regex:          PropTypes.instanceOf(RegExp).isRequired,
            value:          PropTypes.string.isRequired,
            default_value:  PropTypes.string.isRequired,
            placeholder:    PropTypes.any,  
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
                error_extra: PropTypes.string.isRequired
            }) 
        }),
    })
};

export {ModalEditar};
