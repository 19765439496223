import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ModalUI } from '../../common';

const ModalBorrar = (props) => {

    let { 
        borrar,
        depth,
        handleSubmit,
        modalHandler,
        modal_state,
        form_state,
    } = props;

    const getTitle = (depth) => {
        if(depth===2)
        {
            return 'Borrar artículo';
        }
        else if (depth===1)
        {
            return 'Borrar subcategoría';
        }
        else
        {
            return 'Borrar categoría';
        }
    }

    const getName = (depth) => {
        if(depth===2)
        {
            return 'el artículo';
        }
        else if (depth===1)
        {
            return 'la subcategoría';
        }
        else
        {
            return 'la categoría';
        }
    }

    return (
        <ModalUI 
            open={modal_state}
            id="borrar"
            title={getTitle(depth)}
            modalHandler={modalHandler}
            aditional_param="borrar"
            handleSubmit={handleSubmit}
            button_label="Borrar"
            button_disabled={false}
            button_loading={form_state}
        >
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Typography style={{fontSize: '1.1rem'}}>
                        ¿Estás seguro de que quieres borrar {getName(depth)} "{borrar.c_item.title}"? <span style={{color: 'red'}}>Si hay subcategorías o artículos agregados en esta categoría también serán eliminados.</span><br/> Recomendamos que si hay artículos que no deseas borrar dentro, primero los muevas a otra categoría desde la edición de productos.
                    </Typography>
                </Grid>
            </Grid>
        </ModalUI>
    );
}

export {ModalBorrar};