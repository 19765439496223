import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    Grid,
    Icon,
    withStyles, 
    Card, 
    CardHeader, 
    CardContent, 
    Divider,
    LinearProgress
 } from '@material-ui/core';

import { 
    PrivateLayout, 
    AlertUI, 
    ModuleTitleUI,
    InputUI,
    ButtonUI
} from '../../../common';
import {
    obtenerLiquidacionMasiva,
    cuponAbonarMasivo,
    cuponAbonarTodo
} from '../../../../store/actions';
import {
    LiquidacionList,
    ModalAbonarMasivo,
    GenerarExcel,
    ModalAbonarTodo
} from './subcomponents';
import { Validate, Regex, RegexExtra } from '../../../../other';
 
class LiquidacionMasiva_ extends Component 
{
    state={

        filter: {
            search: {
                auth: false,
                validate: false,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Buscar',
                id: 'search',
                name: 'search',
                change_param: 'filter',
                regex: Regex.ANYTHING,
                value: '',
                default_value: '',
                placeholder: 'Buscar...',
                messages: {
                    error: '',
                    error_extra: RegexExtra.ANYTHING
                }
            },
        },
        //datos para el modal marcar abonado
        marcar_abonado:{
            idCuponAlianza: 0,
            ids: [],
            a_nombre: ''
        },
        abonar_todo: {
            cupones_array: []
        },
        loading: {
            cupones: true,
            error: false
        },
        //modales
        modals: {
            marcar_abonado: false,
            abonar_todo: false
        },
        forms: {
            marcar_abonado: false,
            abonar_todo: false
        },
        alert: {
            open: false,
            type: 'error',
            message: ''
        },
    };

    componentDidMount()
    {
        this.props.obtenerLiquidacionMasiva((err,resp) => {
            this.setState({
                loading: {
                    ...this.state.loading,
                    cupones: false,
                    error: (this.state.loading.error || err) ? true : false
                }
            });
        });
    }

    handleChange = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            }
        },() => {
            Validate.validate_input(this.state[aditional][name],blur,(input) => {
                this.setState({
                    [aditional]: {
                        ...this.state[aditional],
                        [name]: input
                    }
                });
            });
        });
    }

    handleChangeFilter = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;
    
        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                },
            }
        });
    }

    handleSubmitAbonarMasivo = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                marcar_abonado: true
            }
        });

        let form = this.state.marcar_abonado;

        this.props.cuponAbonarMasivo({
            idCuponAlianza: form.idCuponAlianza,
            ids: form.ids
        }, (err,resp) => {
            if(err)
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        marcar_abonado: false
                    },
                    alert: {
                        open: true,
                        message: resp.message,
                        type: 'error'
                    }
                });
            }
            else
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        marcar_abonado: false
                    },
                    alert: {
                        open: true,
                        message: resp.message,
                        type: 'success'
                    },
                    modals: {
                        ...this.state.modals,
                        marcar_abonado: false
                    }
                });
            }
        });
    }

    handleSubmitAbonarTodo = (e) => {
        e.preventDefault();

        this.setState({
            forms: {
                ...this.state.forms,
                abonar_todo: true
            }
        });

        let cupones_array = this.state.abonar_todo.cupones_array;
        this.props.cuponAbonarTodo({
            cupones_array
        }, (err,resp) => {
            if(err)
            {
                this.setState({
                    alert: {
                        open: true,
                        message: resp.message,
                        type: 'error'
                    }
                });
            }
            else
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        abonar_todo: false
                    },
                    alert: {
                        open: true,
                        message: resp.message,
                        type: 'success'
                    },
                    modals: {
                        ...this.state.modals,
                        abonar_todo: false
                    }
                });
            }
        });        
    }


    //abre y cierra los modales
    modalHandler = (open,id,aditional) => {
        switch(id) 
        {
            case 'marcar_abonado':
                if(open)
                {
                    let cupones = aditional;
                    this.setState({
                        marcar_abonado: {
                            ...this.state.marcar_abonado,
                            idCuponAlianza: cupones.idCuponAlianza,
                            ids: cupones.ids,
                            a_nombre: cupones.alianza.a_nombre

                        },
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
                else
                {
                    this.setState({
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
            break;

            case 'abonar_todo':
                if(open)
                {
                    let cupones = aditional;
                    let cupones_array;
                    cupones.forEach(cupon => {
                        let key = cupon.idCuponAlianza.toString();
                        cupones_array = {
                            ...cupones_array,
                            [key]: cupon.ids
                        }
                    });

                    this.setState({
                        abonar_todo: {
                            cupones_array
                        },
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
                else
                {
                    this.setState({
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
            break;

            default:
            break;
        }
    }

    //cierra las alertas
    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }

    render() 
    {
        const { classes } = this.props;
        return (
            <PrivateLayout 
                error={this.state.loading.error} 
                url={this.props.match.url}
            >
                <ModalAbonarMasivo
                    marcar_abonado={this.state.marcar_abonado}
                    handleSubmit={this.handleSubmitAbonarMasivo}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.marcar_abonado}
                    form_state={this.state.forms.marcar_abonado}
                />
                <ModalAbonarTodo
                    handleSubmit={this.handleSubmitAbonarTodo}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.abonar_todo}
                    form_state={this.state.forms.abonar_todo}
                />
                <AlertUI
                    open={this.state.alert.open}
                    message={this.state.alert.message}
                    type={this.state.alert.type}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <ModuleTitleUI 
                    title="Liquidación Masiva"
                    button={
                        <ButtonUI
                            type="callf"
                            label="Abonar todo"
                            fullWidth={false}
                            disabled={this.props.cupones.length > 0 ? false : true}
                            onClickFunc={() => this.modalHandler(true,'abonar_todo',this.props.cupones)}
                        />
                    }
                    subtitles={[
                        'Aquí podrás ver todos los cupon-alianza pendientes de liquidación'
                    ]}
                />
                <div style={{ paddingBottom: 12 }}>
                    <Grid container alignItems="center" spacing={24}>
                        <Grid item md={4} sm={6} xs={12}>
                            <InputUI
                                handleChange={this.handleChangeFilter}
                                input={this.state.filter.search}
                                end_adornment={<Icon>search</Icon>}
                            />
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <GenerarExcel/>
                        </Grid>
                    </Grid>
                </div> 
                <Card className={classes.card_below}>
                    <CardHeader
                        title="Cupones"
                        className={classes.card_header}
                        classes={{action: classes.card_action_button}}
                    />
                    <Divider/>
                    <CardContent className={classes.card_content}>
                        {(this.state.loading.cupones) ? <LinearProgress/> : <LiquidacionList modalHandler={this.modalHandler} filtro={this.state.filter.search.value} />} 
                    </CardContent>
                </Card>
            </PrivateLayout>
        );
    }
}

const styles = theme => ({
    card: theme.card.card,
    card_header: theme.card.card_header,
    card_header_title: theme.card.card_header_title,
    card_actions: theme.card.card_actions_public,
    card_content: {
        padding: 0,
        '&:last-child': {
            padding: 0
        }
    },
});

const mapStateToProps = (state) => {
    return {
        cupones: state.cupones,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        obtenerLiquidacionMasiva: (callback) => dispatch(obtenerLiquidacionMasiva(callback)),
        cuponAbonarMasivo: (data, callback) => dispatch(cuponAbonarMasivo(data,callback)),
        cuponAbonarTodo: (data, callback) => dispatch(cuponAbonarTodo(data,callback))

    }
}

// LiquidacionMasiva_.propTypes = {
    
// };

export const LiquidacionMasiva = withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(LiquidacionMasiva_));
