import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const baseConocimientoObtener = (data, callback) => {

    return (dispatch, getState) => {
        axios.get(`${Config.BACKEND_ENDPOINT}/knowledge-base/${data.idKnowledgeBase}`).then(resp => {
            
            //guardo en redux
            const conocimiento = resp.data.data;
            dispatch({type: Reducers.CONOCIMIENTO_GUARDAR, data: conocimiento });
            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const baseConocimientoOrdenar = (data,callback) => {

    return (dispatch, getState) => {

        //guardo en redux
        dispatch({ type: Reducers.CONOCIMIENTO_ORDENAR, data: data.new_categorias });

        axios.put(`${Config.BACKEND_ENDPOINT}/knowledge-base/${data.idKnowledgeBase}`, {
            knowledge_base_data: data.new_categorias
        }).then(resp => {

            return callback(false);
            
        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true);
        });
    }

}

const baseConocimientoModificar = (data,callback) => {

    return (dispatch, getState) => {
        axios.put(`${Config.BACKEND_ENDPOINT}/knowledge-base/${data.idKnowledgeBase}`, {
            knowledge_base_data: data.knowledge_base_data
        }).then(resp => {
            //guardo en redux
            const nuevo_conocimiento = resp.data.data;
            dispatch({type: Reducers.CONOCIMIENTO_GUARDAR, data: nuevo_conocimiento });
            return callback(false, resp.data);
            
        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
    
}

const uploadImageEditor = (data,callback) => {

    return (dispatch, getState) => {

        let body = new FormData();
        body.append('up_imagen', data.file);

        axios.post(Config.BACKEND_ENDPOINT+'/upload/editor', body, {
            headers: { 'Content-Type' : 'multipart/form-data' }
        }).then(resp => {
            
            return callback(false, resp.data);
            
        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });

    }
    
}

export { 
    baseConocimientoObtener,
    baseConocimientoModificar, 
    baseConocimientoOrdenar,
    uploadImageEditor

};