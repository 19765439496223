import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardContent, Grid, withStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import { 
    PrivateLayout, 
    AlertUI,
    ModuleTitleUI,
    ButtonUI
} from '../common';
import { 
    obtenerEstadisticasHistoricas,
    obtenerEstadisticasTienda,
    obtenerEstadisticasLtvChurn,
    obtenerEstadisticasMarketplace
} from '../../store/actions';
import { Validate, Config } from '../../other';
import { ModalPeriodo } from './subcomponents';

let init_date = moment().set('second', 0);

class Estadisticas_ extends Component 
{
    state={
        loading: {
            estadisticas: true,
            tiendas: false,
            ltv: false,
            marketplace: false,
            error: false
        },
        alert: {
            open: false,
            type: 'error',
            message: ''
        },
        filter: {
            select_estadisticas: ''
        },
        seleccionar_periodo: {
            periodo_desde: {
                validate: false,
                momentobj: init_date.clone().subtract(1, 'days'),
                label: 'Desde',
                name: 'periodo_desde',
                change_param: 'seleccionar_periodo'
            },
            periodo_hasta: {
                validate: false,
                momentobj: init_date.clone(),
                label: 'Hasta',
                name: 'periodo_hasta',
                change_param: 'seleccionar_periodo'
            },
        },
        //modals
        modals: {
            seleccionar_periodo: false,
        },
        resp_historic: {
            shops: 0,
            shops_online: 0
        },
        tiendas_periodo: {
            shops_active: 0, 
            shops_created: 0, 
            shops_recurrent: 0, 
            shops_down: 0, 
            shops_referral: 0, 
            income: 0 
        },
        resp_ltv: {
            shops_ltv: 0,
            shops_churn: 0
        },
        resp_marketplace: {
            shops_orders_gnv: 0,
            shops_orders_qty: 0,
            shops_orders_avg_amount: 0
        }
    };

    cargarEstadisticasHistoricas = () => {
        this.setState({
            loading: {
                ...this.state.loading,
                estadisticas: true
            },
        });
        this.props.obtenerEstadisticasHistoricas((err,resp) => {
            this.setState({
                loading: {
                    ...this.state.loading,
                    estadisticas: false,
                    error: err ? true : false
                },
                resp_historic: resp.data
            });
        });
    }

    cargarEstadisticas = () => {
    
        let desde = Validate.moment_local2utc(this.state.seleccionar_periodo.periodo_desde.momentobj.clone()).format(Config.DATETIME_FORMAT);
        let hasta = Validate.moment_local2utc(this.state.seleccionar_periodo.periodo_hasta.momentobj.clone().set('second', 59)).format(Config.DATETIME_FORMAT);

        switch(this.state.filter.select_estadisticas)
        {
            case 'tienda':
                this.setState({
                    loading: {
                        ...this.state.loading,
                        tiendas: true
                    },
                });

                this.props.obtenerEstadisticasTienda({
                    from: desde,
                    until: hasta
                }, (err,resp) => {
                    this.setState({
                        loading: {
                            ...this.state.loading,
                            tiendas: false,
                            error: err ? true : false
                        },
                        tiendas_periodo: resp.data
                    });
                });
            break;

            case 'ltv-churn':
                this.setState({
                    loading: {
                        ...this.state.loading,
                        ltv: true
                    },
                });

                this.props.obtenerEstadisticasLtvChurn({
                    from: desde,
                    until: hasta
                }, (err,resp) => {
                    this.setState({
                        loading: {
                            ...this.state.loading,
                            ltv: false,
                            error: err ? true : false
                        },
                        resp_ltv: {
                            ...resp.data
                        }
                    });
                });

            break;

            case 'marketplace':
                this.setState({
                    loading: {
                        ...this.state.loading,
                        marketplace: true
                    },
                });

                this.props.obtenerEstadisticasMarketplace({
                    from: desde,
                    until: hasta
                }, (err,resp) => {
                    this.setState({
                        loading: {
                            ...this.state.loading,
                            marketplace: false,
                            error: err ? true : false
                        },
                        resp_marketplace: {
                            ...resp.data
                        }
                    });
                });
            break;
        
            default:
            break;
        }
    }

    componentDidMount()
    {
        this.props.auth.rol===0 ? this.cargarEstadisticasHistoricas() : this.props.history.push('/');
    }

    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }

    modalHandler = (open,id, filter) => {
        switch(id)
        {
            case 'seleccionar_periodo':
                if(open)
                {
                    this.setState({
                        filter: {
                            ...this.state.filter,
                            select_estadisticas: filter
                        },
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
                else
                {
                    this.setState({
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
            break;

            default:
                this.setState({
                    modals: {
                        ...this.state.modals,
                        [id]: open
                    }
                });
            break;
        }
    }

    handleSubmitSeleccionarPeriodo = (e) => {
        e.preventDefault();

        let fecha_desde = this.state.seleccionar_periodo.periodo_desde.momentobj.clone().set('milliseconds', 0);
        let fecha_hasta = this.state.seleccionar_periodo.periodo_hasta.momentobj.clone().set('milliseconds', 0);
        
        let resta_fechas = moment.duration(fecha_hasta.diff(fecha_desde));
        
        fecha_desde = (Validate.moment_local2utc_format(fecha_desde));
        fecha_hasta = (Validate.moment_local2utc_format(fecha_hasta));

        if(!Validate.moment_comparar(fecha_desde,fecha_hasta) && fecha_desde!==fecha_hasta)
        {
            this.setState({
                alert: {
                    ...this.state.alert,
                    open: true,
                    type: 'error',
                    message: 'La fecha desde no puede ser mayor a la fecha hasta'
                }
            });

        } 
        else if(
            resta_fechas.years()    < 1 && 
            resta_fechas.months()   < 1 && 
            resta_fechas.days()     < 1 && 
            resta_fechas.hours()    < 1 && 
            resta_fechas.minutes()  >= 0 && 
            resta_fechas.seconds()  >= 0
        )
        {
            this.setState({
                alert: {
                    ...this.state.alert,
                    open: true,
                    type: 'error',
                    message: 'Debe seleccionar al menos una hora de diferencia'
                }
            });
        }
        else
        {   
            this.setState({
                modals: {
                    ...this.state.modals,
                    seleccionar_periodo: false
                }
            }, () => this.cargarEstadisticas());
        }
    }

    handleChangeDate = (momentobj,name,aditional) => {
        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    momentobj: momentobj
                }
            }
        });
    }

    render() 
    {
        let { classes } = this.props;

        let { 
            shops,
            shops_online
        } = this.state.resp_historic;

        let { 
            shops_active, 
            shops_created, 
            shops_recurrent, 
            shops_down, 
            shops_referral, 
            income
        } = this.state.tiendas_periodo;
        
        let { 
            shops_ltv,
            shops_churn
        } = this.state.resp_ltv;

        let { 
            shops_orders_gnv,
            shops_orders_qty,
            shops_orders_avg_amount
        } = this.state.resp_marketplace;

        return (
            <PrivateLayout
                loading={this.state.loading.estadisticas}
                error={this.state.loading.error}
                url={this.props.match.url}
            >
                <AlertUI
                    open={this.state.alert.open}
                    message={this.state.alert.message}
                    type={this.state.alert.type}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <ModalPeriodo
                    handleSubmit={this.handleSubmitSeleccionarPeriodo}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.seleccionar_periodo}
                    handleChangeDate={this.handleChangeDate}
                    periodo_desde={this.state.seleccionar_periodo.periodo_desde}
                    periodo_hasta={this.state.seleccionar_periodo.periodo_hasta}
                />
                <Grid container spacing={24}>
                    <Grid item xs={12}>   
                        <ModuleTitleUI
                            title='Estadísticas Históricas'
                        />
                        <Grid container spacing={24} classes={{container: classes.stat_container}} >
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Card className={classes.card}>
                                    <CardContent classes={{root: classes.card_content_title}}>
                                        <Typography className={classes.stat_title}>
                                            TIENDAS TOTALES CREADAS
                                        </Typography>
                                        <Typography className={classes.stat_subtitle}>
                                            {shops}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Card className={classes.card}>
                                    <CardContent classes={{root: classes.card_content_title}}>
                                        <Typography className={classes.stat_title}>
                                            TIENDAS FUNCIONANDO
                                        </Typography>
                                        <Typography className={classes.stat_subtitle}>
                                            {shops_online}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <ModuleTitleUI
                            title='Estadísticas de tiendas por período'
                            button={
                                <ButtonUI
                                    type='callf'
                                    onClickFunc={() => this.modalHandler(true, 'seleccionar_periodo', 'tienda')}
                                    label='Filtrar'
                                    fullWidth={false}
                                    isLoading={this.state.loading.tiendas}
                                />
                            }
                        />
                        <Grid container spacing={24} classes={{container: classes.stat_container}} >
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Card className={classes.card}>
                                    <CardContent classes={{root: classes.card_content_title}}>
                                        <Typography className={classes.stat_title}>
                                            TIENDAS ACTIVAS
                                        </Typography>
                                        <Typography className={classes.stat_subtitle}>
                                            {shops_active}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Card className={classes.card}>
                                    <CardContent classes={{root: classes.card_content_title}}>
                                        <Typography className={classes.stat_title}>
                                            TIENDAS TOT. CREADAS (TRIAL)
                                        </Typography>
                                        <Typography className={classes.stat_subtitle}>
                                            {shops_created}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Card className={classes.card}>
                                    <CardContent classes={{root: classes.card_content_title}}>
                                        <Typography className={classes.stat_title}>
                                            TIENDAS PAGARON
                                        </Typography>
                                        <Typography className={classes.stat_subtitle}>
                                            {shops_recurrent}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Card className={classes.card}>
                                    <CardContent classes={{root: classes.card_content_title}}>
                                        <Typography className={classes.stat_title}>
                                            TIENDAS DE BAJA
                                        </Typography>
                                        <Typography className={classes.stat_subtitle}>
                                            {shops_down}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Card className={classes.card}>
                                    <CardContent classes={{root: classes.card_content_title}}>
                                        <Typography className={classes.stat_title}>
                                            TIENDAS REFERIDAS
                                        </Typography>
                                        <Typography className={classes.stat_subtitle}>
                                            {shops_referral}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Card className={classes.card}>
                                    <CardContent classes={{root: classes.card_content_title}}>
                                        <Typography className={classes.stat_title}>
                                            FACTURACION
                                        </Typography>
                                        <Typography className={classes.stat_subtitle}>
                                            ${Validate.number_format(income)}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <ModuleTitleUI
                            title='Life-time value y Churn por período'
                            button={
                                <ButtonUI
                                    type='callf'
                                    onClickFunc={() => this.modalHandler(true, 'seleccionar_periodo', 'ltv-churn')}
                                    label='Filtrar'
                                    fullWidth={false}
                                    isLoading={this.state.loading.ltv}
                                />
                            }
                        />
                        <Grid container spacing={24} classes={{container: classes.stat_container}} >
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Card className={classes.card}>
                                    <CardContent classes={{root: classes.card_content_title}}>
                                        <Typography className={classes.stat_title}>
                                            LTV
                                        </Typography>
                                        <Typography className={classes.stat_subtitle}>
                                            {shops_ltv.toFixed(2)}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Card className={classes.card}>
                                    <CardContent classes={{root: classes.card_content_title}}>
                                        <Typography className={classes.stat_title}>
                                            CHURN
                                        </Typography>
                                        <Typography className={classes.stat_subtitle}>
                                            {shops_churn.toFixed(2)}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <ModuleTitleUI
                            title='Estadísticas Marketplace'
                            button={
                                <ButtonUI
                                    type='callf'
                                    onClickFunc={() => this.modalHandler(true, 'seleccionar_periodo', 'marketplace')}
                                    label='Filtrar'
                                    fullWidth={false}
                                    isLoading={this.state.loading.marketplace}
                                />
                            }
                        />
                        <Grid container spacing={24} classes={{container: classes.stat_container}} >
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Card className={classes.card}>
                                    <CardContent classes={{root: classes.card_content_title}}>
                                        <Typography className={classes.stat_title}>
                                            GNV
                                        </Typography>
                                        <Typography className={classes.stat_subtitle}>
                                            ${Validate.number_format(shops_orders_gnv)}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Card className={classes.card}>
                                    <CardContent classes={{root: classes.card_content_title}}>
                                        <Typography className={classes.stat_title}>
                                            TOTAL DE VENTAS
                                        </Typography>
                                        <Typography className={classes.stat_subtitle}>
                                            {shops_orders_qty}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Card className={classes.card}>
                                    <CardContent classes={{root: classes.card_content_title}}>
                                        <Typography className={classes.stat_title}>
                                            TICKET PROMEDIO
                                        </Typography>
                                        <Typography className={classes.stat_subtitle}>
                                            ${Validate.number_format(shops_orders_avg_amount)}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </PrivateLayout>
        );
    }
}

const styles = theme => ({
    card: theme.card.card,
    card_below: theme.card.card_below,
    card_header: theme.card.card_header,
    card_header_title: theme.card.card_header_title,
    card_content: theme.card.card_content,
    card_actions: theme.card.card_actions_public,
    card_subheader: theme.card.card_subheader,
    card_subheader_icon: theme.card.card_subheader_icon,
    card_content_title: {
        ...theme.card.card_content,
        padding: '10px !important'
    },
    stat_container: {
        marginTop: '20px',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '40px'
        }
    },
    stat_title: {
        padding: 0,
        margin: 0,
        color: '#9e9e9e',
        fontSize: '11px',
        textDecoration: 'none',
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.8rem',
        }
    },
    stat_subtitle: {
        padding: 0,
        margin: 0,
        color: '#263238',
        fontSize: '18px',
        textDecoration: 'none',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem',
        }
    },
});

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        obtenerEstadisticasHistoricas: (callback) => dispatch(obtenerEstadisticasHistoricas(callback)),
        obtenerEstadisticasTienda: (data,callback) => dispatch(obtenerEstadisticasTienda(data,callback)),
        obtenerEstadisticasLtvChurn: (data,callback) => dispatch(obtenerEstadisticasLtvChurn(data,callback)),
        obtenerEstadisticasMarketplace: (data,callback) => dispatch(obtenerEstadisticasMarketplace(data,callback)),
    }
}

// Estadisticas.propTypes = {

// };

export const Estadisticas = withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(Estadisticas_));