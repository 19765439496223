class Config
{
    static BACKEND_ENDPOINT = `${process.env.REACT_APP_BACKEND_ENDPOINT}/soporte-interno`;
    static EMPREPACK_ENDPOINT = process.env.REACT_APP_EMPREPACK_ENDPOINT;
    static RECAPTCHA_PUBLIC_KEY = '6LfXRycUAAAAAFQF69mKvgHerNR9oDrDYw1BM_Kw';
    static DATE_FORMAT = 'DD/MM/YY H:mm';
    static DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
    static SHORTDATE_FORMAT = 'DD/MM/YY';
    static SHORTDATE_FULLFORMAT = 'DD/MM/YYYY';
    static CLOUDFRONT_DESCIMAGES_CDN = 'https://d22fxaf9t8d39k.cloudfront.net/descripciones/'; 
    static REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
    static ENVIRONMENTS = ['development', 'stage', 'local', 'production'];
} 
export { Config };