import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    Grid,
    Icon,
    withStyles, 
    Card, 
    CardHeader, 
    CardContent, 
    Divider,
    LinearProgress
 } from '@material-ui/core';
 import { 
    cuponObtenerCuponesAlianza, 
    cuponAlianzaAgregar,
    cuponAlianzaEditar,
    cuponAlianzaBorrar,
 } from '../../store/actions';
import { 
    PrivateLayout, 
    AlertUI, 
    ModuleTitleUI,
    ButtonUI, 
    InputUI
} from '../common';
import { 
    ModalAgregar, 
    ModalEditar, 
    ModalBorrar, 
    CuponAlianzaList 
} from './subcomponents';
import { Validate, Regex, RegexExtra } from '../../other';
 
class CuponAlianza_ extends Component 
{
    state={

        filter: {
            search: {
                auth: false,
                validate: false,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Buscar',
                id: 'search',
                name: 'search',
                change_param: 'filter',
                regex: Regex.ANYTHING,
                value: '',
                default_value: '',
                placeholder: 'Código de cupón...',
                messages: {
                    error: '',
                    error_extra: RegexExtra.ANYTHING
                }
            },
        },
        selects : {
            ca_tipo: [
                {
                    value: 1,
                    label: "Cupón de días extra"
                },
                {
                    value: 2,
                    label: "Cupón de monto fijo"
                },
            ],
        },
        //campos para modal agregar
        agregar: {
            idAlianzas: {
                validate: false,
                value: this.props.match.params.idAlianzas,
            },
            ca_codigo: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Código',
                id: 'agregar_ca_codigo',
                name: 'ca_codigo',
                change_param: 'agregar',
                regex: Regex.CUPON_DESCUENTO,
                value: '',
                default_value: '',
                placeholder: 'Código Cupón',
                messages: {
                    error: 'Por favor, ingrese un código de cupón válido',
                    error_extra: RegexExtra.CUPON_DESCUENTO
                }
            },
            ca_tipo: {
                auth: true,
                validate: false,
                required: true,
                error: false,
                label: 'Tipo de cupón',
                id: 'ca_tipo',
                name: 'ca_tipo',
                change_param: 'agregar',
                value: 1,
                default_value: 1,
                messages: {
                    error: ''
                },
            },
            ca_dias: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'number',
                label: 'Días',
                id: 'agregar_ca_dias',
                name: 'ca_dias',
                change_param: 'agregar',
                regex: Regex.NUMERO_ENTERO_POSITIVO,
                value: '',
                default_value: '',
                placeholder: 'Días de Cupón',
                messages: {
                    error: 'Por favor, ingrese un número de días válido',
                    error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO
                }
            },
            ca_monto: {
                auth: false,
                validate: false,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Monto',
                id: 'agregar_ca_monto',
                name: 'ca_monto',
                change_param: 'agregar',
                regex: Regex.PRECIO,
                value: '',
                default_value: '',
                placeholder: 'Monto Cupón',
                messages: {
                    error: 'Por favor, ingrese un monto de cupón válido',
                    error_extra: RegexExtra.PRECIO
                }
            },
        },
        //campos para modal editar
        editar: {
            idCuponAlianza: {
                validate: false,
                value: 0
            },
            ca_codigo: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Código',
                id: 'editar_ca_codigo',
                name: 'ca_codigo',
                change_param: 'editar',
                regex: Regex.CUPON_DESCUENTO,
                value: '',
                default_value: '',
                placeholder: 'Código Cupón',
                messages: {
                    error: 'Por favor, ingrese un código de cupón válido',
                    error_extra: RegexExtra.CUPON_DESCUENTO
                }
            },
            ca_tipo: {
                auth: true,
                validate: false,
                required: true,
                error: false,
                label: 'Tipo de cupón',
                id: 'ca_tipo',
                name: 'ca_tipo',
                change_param: 'editar',
                value: 1,
                messages: {
                    error: ''
                },
            },
            ca_dias: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'number',
                label: 'Días',
                id: 'editar_ca_dias',
                name: 'ca_dias',
                change_param: 'editar',
                regex: Regex.NUMERO_ENTERO,
                value: '',
                default_value: '',
                placeholder: 'Días de Cupón',
                messages: {
                    error: 'Por favor, ingrese un número de días válido',
                    error_extra: RegexExtra.NUMERO_ENTERO
                }
            },
            ca_monto: {
                auth: false,
                validate: false,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Monto',
                id: 'editar_ca_monto',
                name: 'ca_monto',
                change_param: 'editar',
                regex: Regex.PRECIO,
                value: '',
                default_value: '',
                placeholder: 'Monto Cupón',
                messages: {
                    error: 'Por favor, ingrese un monto de cupón válido',
                    error_extra: RegexExtra.PRECIO
                }
            },
        },
        //datos para el modal borrar
        borrar: {
            idCuponAlianza: 0,
            ca_codigo: ''
        },
        loading: {
            init_cupones: true,
            cupones: true,
            error: false
        },
        //modales
        modals: {
            agregar: false,
            editar: false,
            borrar: false,
        },
        forms: {
            agregar: false,
            editar: false,
            borrar: false,
        },
        alert: {
            open: false,
            type: 'error',
            message: ''
        },
    };

    componentDidMount()
    {
        let idAlianzas = this.props.match.params.idAlianzas;
        this.props.cuponObtenerCuponesAlianza(idAlianzas, (err,resp) => {
            this.setState({
                loading: {
                    ...this.state.loading,
                    cupones: false,
                    error: (this.state.loading.error || err) ? true : false
                }
            });
        });
    }

    handleChange = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            }
        },() => {
            Validate.validate_input(this.state[aditional][name],blur,(input) => {
                this.setState({
                    [aditional]: {
                        ...this.state[aditional],
                        [name]: input
                    }
                });
            });
        });
    }

    handleChangeFilter = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;
    
        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                },
            }
        });
    }

    handleChangeSelect = (e,aditional) => {

        let value = Validate.trim_int(e.target.value);
        let name = e.target.name;
        let enable_arr = value===1 ? ["ca_dias"] : ["ca_monto"];
        let disable_arr = value===1 ? ["ca_monto"] : ["ca_dias"];
        let form_state = Validate.bulk_enable_disable_input(this.state[aditional], enable_arr, disable_arr, [], true);
        
        this.setState({
            [aditional]: {
                ...form_state,
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                },
            }
        });

    }

    //submits
    handleSubmitAgregar = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                agregar: true
            }
        });
        let form = this.state.agregar;
        let dias = form.ca_tipo.value===1 ? Validate.trim_int(form.ca_dias.value) : 0;
        let monto = form.ca_tipo.value===2 ? Validate.trim_float(form.ca_monto.value) : 0; 
        
        if(Validate.validar_formulario(form))
        {
            this.props.cuponAlianzaAgregar({
                idAlianzas: parseInt(form.idAlianzas.value),
                ca_codigo: form.ca_codigo.value,
                ca_tipo: form.ca_tipo.value,
                ca_dias: dias,
                ca_monto: monto
            },(err,resp) => {
                if(err)
                {
                    this.setState({
                        forms: {
                            ...this.state.forms,
                            agregar: false
                        },
                        alert: {
                            open: true,
                            message: resp.message,
                            type: 'error'
                        }
                    });
                }
                else
                {
                    this.setState({
                        forms: {
                            ...this.state.forms,
                            agregar: false
                        },
                        alert: {
                            open: true,
                            message: resp.message,
                            type: 'success'
                        }
                    }, () => this.modalHandler(false, "agregar"));
                }
            });
        }
        else
        {
            Validate.validar_formulario_actualizar_obj(form,(new_form) => {
                this.setState({
                    agregar: new_form,
                    forms: {
                        ...this.state.forms,
                        agregar: false
                    },
                    alert: {
                        ...this.state.alert,
                        open: true,
                        message: 'Por favor, revisá tus datos, tenés algunos errores',
                        type: 'error'
                    }
                });
            });
        }
    }

    handleSubmitEditar = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                editar: true
            }
        });
        let form = this.state.editar;
        let dias = form.ca_tipo.value===1 ? Validate.trim_int(form.ca_dias.value) : 0;
        let monto = form.ca_tipo.value===2 ? Validate.trim_float(form.ca_monto.value) : 0; 
        
        if(Validate.validar_formulario(form))
        {
            this.props.cuponAlianzaEditar({
                idCuponAlianza: form.idCuponAlianza.value,
                ca_codigo: form.ca_codigo.value,
                ca_tipo: form.ca_tipo.value,
                ca_dias: dias,
                ca_monto: monto
            },(err,resp) => {

                if(err)
                {
                    this.setState({
                        forms: {
                            ...this.state.forms,
                            editar: false
                        },
                        alert: {
                            open: true,
                            message: resp.message,
                            type: 'error'
                        }
                    });
                }
                else
                {
                    this.setState({
                        forms: {
                            ...this.state.forms,
                            editar: false
                        },
                        alert: {
                            open: true,
                            message: resp.message,
                            type: 'success'
                        }
                    }, () => this.modalHandler(false, "editar"));
                }
            });
        }
        else
        {
            Validate.validar_formulario_actualizar_obj(form,(new_form) => {
                this.setState({
                    editar: new_form,
                    forms: {
                        ...this.state.forms,
                        editar: false
                    },
                    alert: {
                        ...this.state.alert,
                        open: true,
                        message: 'Por favor, revisá tus datos, tenés algunos errores',
                        type: 'error'
                    }
                });
            });
        }
    }

    handleSubmitBorrar = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                borrar: true
            }
        });

        let form = this.state.borrar;

        this.props.cuponAlianzaBorrar({idCuponAlianza: form.idCuponAlianza}, (err,resp) => {
            if(err)
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        borrar: false
                    },
                    alert: {
                        open: true,
                        message: resp.message,
                        type: 'error'
                    }
                });
            }
            else
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        borrar: false
                    },
                    alert: {
                        open: true,
                        message: resp.message,
                        type: 'success'
                    },
                    modals: {
                        ...this.state.modals,
                        borrar: false
                    }
                });
            }
        });
    }

    //abre y cierra los modales
    modalHandler = (open,id,aditional) => {
        switch(id) 
        {
            case 'agregar':
                this.setState({
                    modals: {
                        ...this.state.modals,
                        [id]: open
                    }
                }, () => {
                    if(!open){
                        let form_obj = Validate.limpiar_formulario(this.state.agregar, ["idAlianzas"]);
                        this.setState({
                            agregar: form_obj
                        });
                    }
                });  
            break;
            case 'borrar':
                if(open)
                {
                    let cupon = aditional;
                    this.setState({
                        borrar: {
                            ...this.state.borrar,
                            idCuponAlianza: cupon.idCuponAlianza,
                            ca_codigo: cupon.ca_codigo

                        },
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
                else
                {
                    this.setState({
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
            break;
            case 'editar':
                if(open)
                {
                    let cupon = aditional;
                    Validate.cargar_formulario_sin_nulos(cupon, Validate.limpiar_formulario(this.state.editar), (editar_obj) => {
                        this.setState({
                            editar: editar_obj,
                            modals: {
                                ...this.state.modals,
                                [id]: open
                            }
                        });
                    });
                }
                else
                {
                    this.setState({
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
            break;
            default:
            break;
        };
    }

    //cierra las alertas
    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }

    render() 
    {
        const { classes } = this.props;
        return (
            <PrivateLayout 
                error={this.state.loading.error} 
                url={this.props.match.url}
            >
                <ModalAgregar
                    agregar={this.state.agregar}
                    selects={this.state.selects}
                    handleSubmit={this.handleSubmitAgregar}
                    handleChange={this.handleChange}
                    modalHandler={this.modalHandler}
                    handleChangeSelect={this.handleChangeSelect}
                    modal_state={this.state.modals.agregar}
                    form_state={this.state.forms.agregar}
                />
                
                <ModalEditar
                    editar={this.state.editar}
                    handleSubmit={this.handleSubmitEditar}
                    handleChange={this.handleChange}
                    modalHandler={this.modalHandler}
                    handleChangeSelect={this.handleChangeSelect}
                    modal_state={this.state.modals.editar}
                    form_state={this.state.forms.editar}
                    selects={this.state.selects}
                />
                <ModalBorrar
                    borrar={this.state.borrar}
                    handleSubmit={this.handleSubmitBorrar}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.borrar}
                    form_state={this.state.forms.borrar}
                />
                <AlertUI
                    open={this.state.alert.open}
                    message={this.state.alert.message}
                    type={this.state.alert.type}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <ModuleTitleUI 
                    title="Cupones"
                    subtitles={[
                        'Aquí podrás ver todos los cupones'
                    ]}
                />
                <div style={{ paddingBottom: 12 }}>
                    <Grid container spacing={24}>
                        <Grid item md={4} sm={6} xs={12}>
                            <InputUI
                                handleChange={this.handleChangeFilter}
                                input={this.state.filter.search}
                                end_adornment={<Icon>search</Icon>}
                            />
                        </Grid>
                    </Grid>
                </div> 
                <Card className={classes.card_below}>
                    <CardHeader
                        title="Cupones"
                        className={classes.card_header}
                        classes={{action: classes.card_action_button}}
                        action={
                            <ButtonUI
                                type="callf"
                                label="Agregar"
                                onClickFunc={() => this.modalHandler(true,'agregar','agregar')}
                            />
                        }
                    />
                    <Divider/>
                    <CardContent className={classes.card_content}>
                        {(this.state.loading.cupones) ? <LinearProgress/> : <CuponAlianzaList idAlianzas={this.props.match.params.idAlianzas} modalHandler={this.modalHandler} filtro={this.state.filter.search.value} />} 
                    </CardContent>
                </Card>
            </PrivateLayout>
        );
    }
}

const styles = theme => ({
    card: theme.card.card,
    card_header: theme.card.card_header,
    card_header_title: theme.card.card_header_title,
    card_actions: theme.card.card_actions_public,
    card_content: {
        padding: 0,
        '&:last-child': {
            padding: 0
        }
    },
});

const mapStateToProps = (state) => {
    return {
        cupones: state.cupones,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        cuponAlianzaAgregar: (data,callback) => dispatch(cuponAlianzaAgregar(data,callback)),
        cuponAlianzaEditar: (data,callback) => dispatch(cuponAlianzaEditar(data,callback)),
        cuponAlianzaBorrar: (data,callback) => dispatch(cuponAlianzaBorrar(data,callback)),
        cuponObtenerCuponesAlianza: (data, callback) => dispatch(cuponObtenerCuponesAlianza(data, callback)),
    }
}

// CuponAlianza_.propTypes = {

// };

export const CuponAlianza = withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(CuponAlianza_));
