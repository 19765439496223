import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { ModalUI } from '../../../common';

const ModalAprobar = (props) => {

    let { 
        marcar_aprobado,
        handleSubmit,
        modalHandler,
        modal_state,
        form_state
    } = props;
  
    return (
        <ModalUI 
            open={modal_state}
            id="marcar_aprobado"
            title="Marcar como aprobado"
            modalHandler={modalHandler}
            aditional_param="marcar_aprobado"
            handleSubmit={handleSubmit}
            button_label="OK"
            button_disabled={false}
            button_loading={form_state}
        >
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Typography>
                        ¿Estás seguro de que quieres marcar como aprobada la solicitud del cliente {marcar_aprobado.customer_name} {marcar_aprobado.customer_surname}? 
                    </Typography>
                </Grid>
            </Grid>
        </ModalUI>
    );
}

ModalAprobar.propTypes = {

    //The form submit function
    //Called: handleSubmit(event,id)
    handleSubmit: PropTypes.func.isRequired,
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    //true if the modal is open
    modal_state: PropTypes.bool.isRequired,

    //true if the modal is open
    form_state: PropTypes.bool.isRequired,

    //The object initialized in state 
    marcar_aprobado: PropTypes.shape({
        idCustomer:             PropTypes.number.isRequired,
        customer_name:          PropTypes.string.isRequired,
        customer_surname:       PropTypes.string.isRequired,
    })
};

export { ModalAprobar };
