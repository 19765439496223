import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    withStyles,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid
} from '@material-ui/core';
import { 
    desactivarEnvios
} from '../../../store/actions';
import {
    SelectUI,
    ButtonUI,
    AlertUI
} from '../../common';
import { Validate } from '../../../other';

class DesactivarMedioEnvio_ extends Component
{   
    state={
        id_tienda: 0,
        data_response: {
           
        },
        data_select: {
            medios_envios: {
                auth: true,
                validate: false,
                required: true,
                error: false,
                label: 'Medio de envío',
                id: 'medios_envios',
                name: 'medios_envios',
                change_param: 'data_select',
                value: -1,
                default_value: -1,
                messages: {
                    error: ''
                },
            },
        },
        selects: {
            medios_envios: [
                {
                    value: -1,
                    label: 'Seleccione una opción'
                },
                {
                    value: 1,
                    label: 'Mercado envíos'
                },
                {
                    value: 2,
                    label: 'Retiro en local'
                },
                {
                    value: 3,
                    label: 'Acordar'
                },
                {
                    value: 5,
                    label: 'Envíos personalizados'
                },
                {
                    value: 6,
                    label: 'OCA'
                },
                {
                    value: 8,
                    label: 'Correo Argentino'
                },
                {
                    value: 9,
                    label: 'E-pick'
                }
            ]
        },
        loading: {
            tienda: true,
            error: false
        },
        alert: {
            open: false,
            type: 'error',
            message: ''
        },
        button_loading: false,
    };

    componentDidMount() 
    {
        this.handleChangeId()
    }
    componentDidUpdate() 
    {
        this.handleChangeId()
    }

    handleChangeId = () => {
        if(this.state.id_tienda !== this.props.id_tienda)
        {
            this.setState({
                id_tienda: this.props.id_tienda,
                loading: {
                    ...this.state.loading,
                    tienda: true,
                    error: false
                }
            });
        }
    }

    buttonDesactivarEnvios = () => {
        if(this.state.data_select.medios_envios.value!==-1)
        {
            this.setState({
                button_loading: true,
            });
            this.props.desactivarEnvios({
                xIdTienda: this.state.id_tienda,
                metodo_envio: this.state.data_select.medios_envios.value
            }, (err,resp) => {
                this.setState({
                    data_response: resp.data,
                    loading: {
                        ...this.state.loading,
                        tienda: false,
                        error: (this.state.loading.error || err) ? true : false
                    },
                    button_loading: false,
                });
                if(err)    
                {
                    this.setState({
                        alert: {
                            open: true,
                            message: resp.message,
                            type: 'error'
                        }
                    });
                }
                else
                {
                    this.setState({
                        alert: {
                            open: true,
                            message: resp.message,
                            type: 'success'
                        }
                    });
                }
            });
        }
        else
        {
            this.setState({
                forms: {
                    ...this.state.forms,
                    editar: false
                },
                alert: {
                    open: true,
                    message: 'Por favor, seleccione una opción válida',
                    type: 'error'
                }
            });
        }
        
    }

    handleChangeSelect = (e,aditional) => {

        let value = Validate.trim_int(e.target.value);
        let name = e.target.name;
        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            },
        });
    }

    //cierra las alertas
    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }   

    render()
    {
        let {classes} = this.props;
        
        return (
            <Fragment>
                <Card className={classes.card}>
                    <CardHeader
                        title="Desactivar un medio de  envío"
                        className={classes.card_header}
                        classes={{action: classes.card_action_button}}
                    />
                    <Divider/>
                    <AlertUI
                        open={this.state.alert.open}
                        message={this.state.alert.message}
                        type={this.state.alert.type}
                        handleCloseAlert={this.handleCloseAlert}
                    />
                    <CardContent className={classes.card_content_no_padding}>
                        <Grid className={classes.grid} container  alignItems="center" spacing={24}>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <SelectUI
                                    data={this.state.data_select.medios_envios}
                                    options={this.state.selects.medios_envios}
                                    handleChangeSelect={this.handleChangeSelect}
                                />
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <ButtonUI
                                    type="callf"
                                    label="Desactivar"
                                    isLoading={this.state.button_loading}
                                    onClickFunc={() => this.buttonDesactivarEnvios()}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Fragment>
        );
    }
}

const styles = theme => ({
    span: {
        display: 'block',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    card: {
        ...theme.card.card,
        padding: '0px !important',
        marginBottom: '25px'
    },
    card_content: theme.card.card_content,
    card_content_no_padding: {
        ...theme.card.card_content,
        padding: '0px !important',
        // overflowX: 'auto'
    },
    grid:{
        padding: '0px',
        margin: 'auto'
    }
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
    return {
        desactivarEnvios: (data,callback) => dispatch(desactivarEnvios(data,callback)),
    }
}

DesactivarMedioEnvio_.propTypes = {

    //Función que hace la request a la api
    desactivarEnvios: PropTypes.func.isRequired,

    //Parámetro de búsqueda que recibe el componente por props
    id_tienda: PropTypes.string.isRequired

};

export const DesactivarMedioEnvio = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(DesactivarMedioEnvio_));
