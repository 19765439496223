import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    withStyles, 
} from '@material-ui/core';
import { PrivateLayout, AlertUI } from '../common';
import { Validate } from '../../other';

class Inicio_ extends Component 
{
    state = {
        loading_overview: true,
        loading_error: false,
        forms: {
            agregar: false
        },
        modals: {
            agregar: false
        },
        alert: {
            open: false,
            type: 'error',
            message: ''
        }
    }
    
    handleChange = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            }
        },() => {
            Validate.validate_input(this.state[aditional][name],blur,(input) => {
                this.setState({
                    [aditional]: {
                        ...this.state[aditional],
                        [name]: input
                    }
                });
            });
        });
    }

    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }

    modalHandler = (open,id,aditional) => {
        this.setState({
            modals: {
                ...this.state.modals,
                [id]: open
            }
        });
    }

    

    render() 
    {
        return (
            <PrivateLayout 
                error={this.state.loading_error} 
            >
                <AlertUI
                    open={this.state.alert.open}
                    message={this.state.alert.message}
                    type={this.state.alert.type}
                    handleCloseAlert={this.handleCloseAlert}
                />
            </PrivateLayout>
        );
    }
}

const styles = theme => ({
    card: {
        ...theme.card.card,
        cursor: 'pointer',
    },
    card_content: {
        ...theme.card.card_content,
        padding: '10px !important'
    },
    ilustracion: {
        width: '95%',
        height: 'auto',
        marginTop: '20px',
        marginBottom: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display:'block',
        [theme.breakpoints.up('sm')]: {
            marginTop: '50px',
            width: '85%'
        }
    },
    hello_title: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '1.4rem',
        marginBottom: '10px',
        marginTop: '30px',
        textAlign:'center',
        [theme.breakpoints.up('md')]: {
            fontSize: '2rem',
        }
    },
    hello_title_red: {
        color: '#f50057',
        fontSize: '1.4rem',
        marginBottom: '10px',
        marginTop: '30px',
        textAlign:'center',
        [theme.breakpoints.up('md')]: {
            fontSize: '2rem',
        }
    },
    hello_subtitle: {
        padding: 0,
        margin: '5px 0px 0px 0px',
        color: '#9e9e9e',
        textAlign:'center',
        fontSize: '1rem'
    },
    hello_buttons: {
        width: '100%',
        display: 'flex',
        justifyContent:'center',
        marginTop: '20px'
    },
    stat_container: {
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '40px'
        }
    },
});




export const Inicio = withStyles(styles)(connect()(Inicio_));
