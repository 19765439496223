import React,{Fragment} from 'react';
import {ToggleUI} from './';
import { Typography, Grid, withStyles } from '@material-ui/core';

const ModuleCardTitleUI_ = (props) => {

    const {
        classes,
        title = '',
        subtitles = [],
        with_grid = true
    } = props;

    let subtitles_html = subtitles.map((subtitle,index) => {
        return (
            <Typography className={classes.module_subtitle} key={index}>
                {subtitle}
            </Typography>
        );
    });

    if(with_grid)
    {
        return (
            <Grid item xs={12}>
                <ToggleUI show={title!==''}>
                    <Typography className={classes.module_title}>
                        {title}
                    </Typography>
                </ToggleUI>
                {subtitles_html}
            </Grid>
        );
    }
    else
    {
        return (
            <Fragment>
                <ToggleUI show={title!==''}>
                    <Typography className={classes.module_title}>
                        {title}
                    </Typography>
                </ToggleUI>
                {subtitles_html}
            </Fragment>
        );
    }
}

const styles = theme => ({
    module_title: {
        color: theme.texts.card_title,
        fontWeight: '400',
        fontSize: '1.1rem',
    },
    module_subtitle: {
        padding: 0,
        margin: 0,
        color: theme.texts.card_subtitle,
        fontSize: '0.8rem',
    },
    module_subtitle_icon: {
        fontSize: 'inherit',
        lineHeight: 'inherit'
    },
});

export const ModuleCardTitleUI = withStyles(styles)(ModuleCardTitleUI_);
