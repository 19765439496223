import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ModalUI, InputUI, SelectUI } from '../../../common';

const ModalEditar = (props) => {

    let { 
        editar, 
        handleSubmit,
        handleChange, 
        modalHandler,
        modal_state,
        form_state,
        handleChangeSelect,
        selects,
    } = props;
  
    return (
        <ModalUI 
            open={modal_state}
            id="editar"
            title="Editar vencimiento"
            modalHandler={modalHandler}
            aditional_param="editar"
            handleSubmit={handleSubmit}
            button_label="Editar"
            button_disabled={false}
            button_loading={form_state}
        >
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <InputUI
                        handleChange={handleChange}
                        input={editar.dias}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SelectUI
                        data={editar.tipo_editar}
                        options={selects.tipo_editar}
                        handleChangeSelect={handleChangeSelect}
                    />
                </Grid>
            </Grid>
        </ModalUI>
    );
}

ModalEditar.propTypes = {

    //The form submit function
    //Called: handleSubmit(event,id)
    handleSubmit: PropTypes.func.isRequired,

    //Called: handleChange(event,id)
    handleChange: PropTypes.func.isRequired,

    handleChangeSelect: PropTypes.func.isRequired,
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    //true if the modal is open
    modal_state: PropTypes.bool.isRequired,

    //true if the modal is open
    form_state: PropTypes.bool.isRequired,

    selects: PropTypes.object.isRequired,

    //The object initialized in state 
    editar: PropTypes.shape({
        dias: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            disabled:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            type:           PropTypes.string.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            regex:          PropTypes.instanceOf(RegExp).isRequired,
            value:          PropTypes.string.isRequired,
            default_value:  PropTypes.string.isRequired,
            placeholder:    PropTypes.any,  
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
                error_extra: PropTypes.string.isRequired
            })
        }),
        tipo_editar: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            value:          PropTypes.number.isRequired,
            default_value:  PropTypes.number.isRequired,
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
            })
        })
    })
};

export {ModalEditar};
