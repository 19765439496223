import axios from 'axios';
import { Config, Validate } from '../../other';
import Reducers from '../reducers';
import { adminLogout } from '.';

const obtenerSolicitudes = (data, callback) => {
    return (dispatch, getState) => {
        axios.get(`${Config.EMPREPACK_ENDPOINT}/private/customer`, { 
            params: data 
        })
        .then(resp => {
            //guardo en redux
            const solicitudes = resp.data.data;
            dispatch({type: Reducers.SOLICITUDES_GUARDAR, data: solicitudes });
            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const modificarStatus = (data, callback) => {
    return (dispatch, getState) => {
        axios.put(`${Config.EMPREPACK_ENDPOINT}/private/customerstatus/${data.idCustomer}`, {customer_status: data.customer_status})
        .then(resp => {
            //guardo en redux
            const status_modificado = resp.data.data;
            dispatch({type: Reducers.STATUS_MODIFICADO, data: status_modificado });
            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const identificarCliente = (data, callback) => {
    return (dispatch, getState) => {
        axios.get(`${Config.EMPREPACK_ENDPOINT}/private/customer`, { 
            params: data
        }).then(resp => {
            
                return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const editarCliente = (data, callback) => {
    return (dispatch, getState) => {
        axios.put(`${Config.EMPREPACK_ENDPOINT}/private/customerdata/${data.idCustomer}`, data)
        .then(resp => {
                return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const clienteObtenerEnvios = (data, callback) => {
    return (dispatch, getState) => {
        axios.get(`${Config.EMPREPACK_ENDPOINT}/private/shipment`, { 
            headers: { 
                "x-idCustomer": data.xIdCustomer 
            }})
        .then(resp => {
                return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const clienteSeguirEnvio = (data, callback) => {
    return (dispatch, getState) => {
        axios.get(`${Config.EMPREPACK_ENDPOINT}/private/shipment/${data.idShipment}/track`, 
        { 
            params: { shipment_tracking_number: data.shipment_tracking_number },
            headers: { 
                "x-idCustomer": data.xIdCustomer 
            }
        }
        )
        .then(resp => {
                return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const clienteGenerarReenvio = (data, callback) => {
    return (dispatch, getState) => {
        axios.post(`${Config.EMPREPACK_ENDPOINT}/private/shipment/${data.idShipment}/resend`, data, { 
            headers: { 
                "x-idCustomer": data.xIdCustomer 
            }})
        .then(resp => {

            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const clienteDescargarArchivoVerificacion = (data, callback) => {
    return (dispatch, getState) => {
        axios.get(`${Config.EMPREPACK_ENDPOINT}/private/customer/${data.idCustomer}/verification/${data.idVerification}`,{
            responseType: 'blob',
        })
        .then(resp => {

            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

export {
    obtenerSolicitudes,
    modificarStatus,
    identificarCliente,
    editarCliente,
    clienteObtenerEnvios,
    clienteSeguirEnvio,
    clienteGenerarReenvio,
    clienteDescargarArchivoVerificacion
};