/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const buscarTiendaReducer = (state = initState,action) => {
    let tiendas;
    switch(action.type)
    {
        case Reducers.TIENDAS_GUARDAR:

            tiendas = action.data;     
            return tiendas;

        break;

        case Reducers.TIENDAS_GUARDAR_NO_CLEAN:

            tiendas = action.data; 

            return [
                ...state,
                ...tiendas
            ];

        break;

        
        case Reducers.AUTH_UNSET:

            return initState;

        break;

        default: 

            return state;

        break;
    }
}

export { buscarTiendaReducer };