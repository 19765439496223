import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Regex, RegexExtra, Validate } from '../../other';
import { 
    baseConocimientoObtener,
    baseConocimientoOrdenar,
    baseConocimientoModificar
} from '../../store/actions';
import { Grid } from '@material-ui/core';
import { 
    PrivateLayout, 
    AlertUI,
    ModuleTitleUI,
    ButtonUI
} from '../common';
import { ConocimientoList, ModalAgregar, ModalEditar, ModalBorrar } from './subcomponents';

class BaseConocimiento_ extends Component 
{
    state={
        depth: null,
        agregar: {
            title: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Nombre',
                id: 'agregar_title',
                name: 'title',
                change_param: 'agregar',
                regex: Regex.DESCRIPCION_0_200,
                value: '',
                default_value: '',
                placeholder: 'Categoría 1',
                messages: {
                    error: 'Ingrese un nombre de categoría válida',
                    error_extra: RegexExtra.DESCRIPCION_0_200
                }
            },
            icon: {
                auth: true,
                validate: true,
                required: false,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Icono',
                id: 'agregar_icon',
                name: 'icon',
                change_param: 'agregar',
                regex: Regex.DESCRIPCION_0_200,
                value: '',
                default_value: '',
                placeholder: 'Icon',
                messages: {
                    error: 'Ingrese un ícono válido',
                    error_extra: RegexExtra.DESCRIPCION_0_200
                }
            },
            c_padre: {
                validate: false,
                title: '',
                slug: '',
            }
        },
        agregar_art: {
            title: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Nombre',
                id: 'agregar_art_title',
                name: 'title',
                change_param: 'agregar_art',
                regex: Regex.DESCRIPCION_0_200,
                value: '',
                default_value: '',
                placeholder: 'Categoría 1',
                messages: {
                    error: 'Ingrese un nombre de categoría válida',
                    error_extra: RegexExtra.DESCRIPCION_0_200
                }
            },
            icon: {
                auth: true,
                validate: true,
                required: false,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Icono',
                id: 'agregar_art_icon',
                name: 'icon',
                change_param: 'agregar_art',
                regex: Regex.DESCRIPCION_0_200,
                value: '',
                default_value: '',
                placeholder: 'Icon',
                messages: {
                    error: 'Ingrese un ícono válido',
                    error_extra: RegexExtra.DESCRIPCION_0_200
                }
            },
            type: {
                validate: false,
                required: true,
                error: false,
                label: 'Tipo',
                id: 'agregar_art_type_content',
                name: 'type',
                change_param: 'agregar_art',
                value: -1,
                default_value: -1,
                messages: {
                    error: ''
                },
            },
            content_html: {
                validate: false,
                value: '',
            },
            external_link: {
                auth: true,
                validate: false,
                required: false,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Link',
                id: 'agregar_art_external_link',
                name: 'external_link',
                change_param: 'agregar_art',
                regex: Regex.LINK,
                value: '',
                default_value: '',
                placeholder: 'Link',
                messages: {
                    error: 'Ingrese un link válido',
                    error_extra: RegexExtra.LINK
                }
            },
            c_padre: {
                validate: false,
                title: '',
                slug: '',
            }
        },
        editar: {
            title: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Nombre',
                id: 'editar_title',
                name: 'title',
                change_param: 'editar',
                regex: Regex.DESCRIPCION_0_200,
                value: '',
                default_value: '',
                placeholder: 'Categoría 1',
                messages: {
                    error: 'Ingrese un nombre de categoría válida',
                    error_extra: RegexExtra.DESCRIPCION_0_200
                }
            },
            icon: {
                auth: true,
                validate: true,
                required: false,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Icono',
                id: 'editar_icon',
                name: 'icon',
                change_param: 'editar',
                regex: Regex.DESCRIPCION_0_200,
                value: '',
                default_value: '',
                placeholder: 'Icon',
                messages: {
                    error: 'Ingrese un ícono válido',
                    error_extra: RegexExtra.DESCRIPCION_0_200
                }
            },
            c_item: {
                validate: false,
                title: '',
                slug: '',
            }
        },
        editar_art: {
            title: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Nombre',
                id: 'editar_art_title',
                name: 'title',
                change_param: 'editar_art',
                regex: Regex.DESCRIPCION_0_200,
                value: '',
                default_value: '',
                placeholder: 'Categoría 1',
                messages: {
                    error: 'Ingrese un nombre de categoría válida',
                    error_extra: RegexExtra.DESCRIPCION_0_200
                }
            },
            icon: {
                auth: true,
                validate: true,
                required: false,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Icono',
                id: 'editar_art_icon',
                name: 'icon',
                change_param: 'editar_art',
                regex: Regex.DESCRIPCION_0_200,
                value: '',
                default_value: '',
                placeholder: 'Icon',
                messages: {
                    error: 'Ingrese un ícono válido',
                    error_extra: RegexExtra.DESCRIPCION_0_200
                }
            },
            type: {
                validate: false,
                required: true,
                error: false,
                label: 'Tipo',
                id: 'editar_art_type',
                name: 'type',
                change_param: 'editar_art',
                value: -1,
                default_value: -1,
                messages: {
                    error: ''
                },
            },
            content_html: {
                validate: false,
                value: '',
            },
            external_link: {
                auth: true,
                validate: false,
                required: false,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Link',
                id: 'editar_art_external_link',
                name: 'external_link',
                change_param: 'editar_art',
                regex: Regex.LINK,
                value: '',
                default_value: '',
                placeholder: 'Link',
                messages: {
                    error: 'Ingrese un link válido',
                    error_extra: RegexExtra.LINK
                }
            },
            c_item: {
                validate: false,
                title: '',
                slug: '',
            }
        },
        selects: {
            type_content: [
                {
                    value: -1,
                    label: 'Seleccione una opción'
                },
                {
                    value: 0,
                    label: 'Contenido HTML'
                },
                {
                    value: 1,
                    label: 'Link externo'
                },
            ]
        },
        borrar: {
            c_item: {
                validate: false,
                title: '',
                slug: '',
            },
        },
        loading: {
            conocimiento: true,
            error: false
        },
        forms: {
            agregar: false,
            agregar_art: false,
            editar: false,
            editar_art: false,
            borrar: false,
        },
        modals: {
            agregar: false,
            editar: false,
            borrar: false,
        },
        alert: {
            open: false,
            type: 'error',
            message: ''
        },
        idKnowledgeBase: this.props.match.params.idKnowledgeBase
    };

    componentDidMount()
    {
        let idKnowledgeBase = this.props.match.params.idKnowledgeBase;

        this.props.baseConocimientoObtener({ idKnowledgeBase }, (err,resp) => {
            this.setState({
                loading: {
                    ...this.state.loading,
                    conocimiento: false,
                    error: err ? true : false
                },
                idKnowledgeBase: idKnowledgeBase
            });
        });
    }

    componentDidUpdate()
    {
        if(this.props.match.params.idKnowledgeBase!==this.state.idKnowledgeBase)
        {
            window.location.href=`/base-conocimiento/${this.props.match.params.idKnowledgeBase}`
        }
    }

    handleChange = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            }
        },() => {
            Validate.validate_input(this.state[aditional][name],blur,(input) => {
                this.setState({
                    [aditional]: {
                        ...this.state[aditional],
                        [name]: input
                    }
                });
            });
        });
    }

    handleChangeQuill = (value) => {
        this.setState({
            agregar_art: {
                ...this.state.agregar_art,
                content_html: {
                    ...this.state.agregar_art.content_html,
                    value: value
                }
            },
            editar_art: {
                ...this.state.editar_art,
                content_html: {
                    ...this.state.editar_art.content_html,
                    value: value
                }
            }
        });
    }

    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }

    modalHandler = (open,id,aditional) => {
        switch(id) 
        {
            case 'agregar':
                let label = aditional.depth===1 ? 'agregar_art' : 'agregar';
                let state = open ? {
                    modals: {
                        ...this.state.modals,
                        [id]: open
                    },
                    [label]: {
                        ...this.state[label],
                        c_padre: {
                            validate: false,
                            title: aditional.item.title,
                            slug: aditional.item.slug,
                        }
                    },
                    depth: aditional.depth
                } : {
                    modals: {
                        ...this.state.modals,
                        [id]: open
                    }
                };

                if(!open)
                {
                    //limpio el formulario del modal agregar
                    let form_obj = Validate.limpiar_formulario(this.state.agregar);
                    let form_obj2 = Validate.limpiar_formulario(this.state.agregar_art);

                    state = {
                        ...state,
                        agregar: form_obj,
                        agregar_art: form_obj2
                    };
                }

                this.setState(state);
            break;
            case 'editar':
                let label_editar = aditional.depth===2 ? 'editar_art' : 'editar';
                if(open)
                {
                    let item = aditional.item;
                    let content;
                    if(aditional.depth===2 && item.type===0)
                    {
                        content = { content_html: item.content };
                    }
                    else
                    {
                        content = { external_link: item.content };
                    }
                    item = {
                        ...item,
                        ...content
                    }

                    Validate.cargar_formulario_sin_nulos(item, this.state[label_editar], (editar_obj) => {
                        this.setState({
                            [label_editar]: {
                                ...editar_obj,
                                c_item: {
                                    validate: false,
                                    title: aditional.item.title,
                                    slug: aditional.item.slug
                                }
                            },
                            depth: aditional.depth,
                            modals: {
                                ...this.state.modals,
                                [id]: open
                            }
                        });
                    });
                }
                else
                {
                    this.setState({
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
            break;
            case 'borrar':
                if(open)
                {
                    let item = aditional.item;
                    Validate.cargar_formulario_sin_nulos(item, this.state.borrar, (borrar_obj) => {
                        this.setState({
                            borrar: {
                                c_item: {
                                    ...this.state.borrar.c_item,
                                    validate: false,
                                    title: item.title,
                                    slug: item.slug
                                }
                            },
                            depth: aditional.depth,
                            modals: {
                                ...this.state.modals,
                                [id]: open
                            }
                        });
                    });
                }
                else
                {
                    this.setState({
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
            break;
            default:
            break;  
        }
    }

    handleSubmitAgregar = (e) => {
        e.preventDefault();

        let label = this.state.depth===1 ? 'agregar_art' : 'agregar';

        this.setState({
            forms: {
                ...this.state.forms,
                [label]: true
            }
        });

        let form = this.state[label];

        let knowledge_base_data = [...this.props.conocimiento.knowledge_base_data];
        let c_padre = this.state[label].c_padre.slug;

        if(this.state.depth===0)
        {
            let index = knowledge_base_data.findIndex(element => element.slug===c_padre);
            knowledge_base_data[index].subcategories.push(
                {   
                    title: form.title.value,
                    icon: form.icon.value,
                    articles: []
                }
            )
        }
        else if(this.state.depth===1)
        {
            knowledge_base_data.forEach(element => {
                element.subcategories.forEach(subcategory => {
                    if(subcategory.slug===c_padre)
                    {
                        subcategory.articles.push(
                            {
                                title: form.title.value,
                                icon: form.icon.value,
                                type: form.type.value,
                                content: form.type.value===0 ? form.content_html.value : form.external_link.value
                            }
                        )
                    }
                })
            })
        }
        else
        {
            knowledge_base_data= [
                ...this.props.conocimiento.knowledge_base_data,
                {
                    title: form.title.value,
                    icon: form.icon.value,
                    subcategories: []
                }
            ]
        }

        if(Validate.validar_formulario(form))
        {
            this.props.baseConocimientoModificar({
                idKnowledgeBase: this.props.match.params.idKnowledgeBase,
                knowledge_base_data
            },(err,resp) => {

                if(err)
                {
                    this.setState({
                        forms: {
                            ...this.state.forms,
                            [label]: false
                        },
                        alert: {
                            ...this.state.alert,
                            open: true,
                            message: resp.message,
                            type: 'error'
                        }
                    });
                }
                else
                {

                    //limpio el formulario del modal agregar
                    let form_obj = Validate.limpiar_formulario(this.state.agregar);
                    let form_obj2 = Validate.limpiar_formulario(this.state.agregar_art);
                    
                    this.setState({
                        agregar: form_obj,
                        agregar_art: form_obj2,
                        forms: {
                            ...this.state.forms,
                            [label]: false
                        },
                        alert: {
                            ...this.state.alert,
                            open: true,
                            message: resp.message,
                            type: 'success'
                        },
                        modals: {
                            ...this.state.modals,
                            agregar: false
                        }
                    });
                }
                
            });
        }
        else
        {
            Validate.validar_formulario_actualizar_obj(form, (new_form) => {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        [label]: false
                    },
                    [label]: new_form
                });
            });
        }
    }

    handleSubmitEditar = (e) => {
        e.preventDefault();
        
        let label = this.state.depth===2 ? 'editar_art' : 'editar';

        this.setState({
            forms: {
                ...this.state.forms,
                [label]: true
            }
        }, () => {
            let form = this.state[label];

            let knowledge_base_data = [...this.props.conocimiento.knowledge_base_data];
            let c_slug = this.state[label].c_item.slug;
    
            if(this.state.depth===1)
            {
                knowledge_base_data.forEach(element => {
                    let index = element.subcategories.findIndex(subcategory => subcategory.slug===c_slug);
                    if(index!==-1)
                    {
                        element.subcategories[index] = {
                            title: form.title.value,
                            icon: form.icon.value,
                            articles: [...element.subcategories[index].articles]
                        }  
                    }
                })
                
            }
            else if(this.state.depth===2)
            {
                knowledge_base_data.forEach(element => {
                    element.subcategories.forEach(subcategory => {
                        let index = subcategory.articles.findIndex(article => article.slug===c_slug);
                        if(index!==-1)
                        {
                            subcategory.articles[index] = {
                                title: form.title.value,
                                icon: form.icon.value,
                                type: form.type.value,
                                content: form.type.value===0 ? form.content_html.value : form.external_link.value
                            }
                        }
                    })
                })
            }
            else
            {
                let index = knowledge_base_data.findIndex(element => element.slug===c_slug);
                if(index!==-1)
                {
                    knowledge_base_data[index] = {   
                        title: form.title.value,
                        icon: form.icon.value,
                        subcategories: [...knowledge_base_data[index].subcategories]
                    }  
                }
                
            }
            
            if(Validate.validar_formulario(form))
            {
                this.props.baseConocimientoModificar({
                    idKnowledgeBase: this.props.match.params.idKnowledgeBase,
                    knowledge_base_data
                },(err,resp) => {
    
                    if(err)
                    {
                        this.setState({
                            forms: {
                                ...this.state.forms,
                                [label]: false
                            },
                            alert: {
                                ...this.state.alert,
                                open: true,
                                message: resp.message,
                                type: 'error'
                            }
                        });
                    }
                    else
                    {
                        this.setState({
                            forms: {
                                ...this.state.forms,
                                [label]: false
                            },
                            alert: {
                                ...this.state.alert,
                                open: true,
                                message: resp.message,
                                type: 'success'
                            },
                            modals: {
                                ...this.state.modals,
                                editar: false
                            }
                        });
                    }
                    
                });
            }
            else
            { 
                Validate.validar_formulario_actualizar_obj(form, (new_form) => {
                    this.setState({
                        forms: {
                            ...this.state.forms,
                            [label]: false
                        },
                        [label]: new_form
                    });
                });
            }
        });
    }

    handleSubmitBorrar = (e) => {
        e.preventDefault();
        
        this.setState({
            forms: {
                ...this.state.forms,
                borrar: true
            }
        });

        let knowledge_base_data = [...this.props.conocimiento.knowledge_base_data];
        let c_slug = this.state.borrar.c_item.slug;

        if(this.state.depth===1)
        {
            knowledge_base_data.forEach(element => {
                let index = element.subcategories.findIndex(subcategory => subcategory.slug===c_slug);
                if(index!==-1)
                {
                    element.subcategories.splice(index, 1);
                }
            })
            
        }
        else if(this.state.depth===2)
        {
            knowledge_base_data.forEach(element => {
                element.subcategories.forEach(subcategory => {
                    let index = subcategory.articles.findIndex(article => article.slug===c_slug);
                    if(index!==-1)
                    {
                        subcategory.articles.splice(index, 1);
                    }
                })
            })
        }
        else
        {
            let index = knowledge_base_data.findIndex(element => element.slug===c_slug);
            if(index!==-1)
            {
                knowledge_base_data.splice(index, 1);
            }
            
        }
        
        this.props.baseConocimientoModificar({
            idKnowledgeBase: this.props.match.params.idKnowledgeBase,
            knowledge_base_data
        },(err,resp) => {

            if(err)
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        borrar: false
                    },
                    alert: {
                        ...this.state.alert,
                        open: true,
                        message: resp.message,
                        type: 'error'
                    }
                });
            }
            else
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        borrar: false
                    },
                    alert: {
                        ...this.state.alert,
                        open: true,
                        message: resp.message,
                        type: 'success'
                    },
                    modals: {
                        ...this.state.modals,
                        borrar: false
                    }
                });
            }
            
        });
    }

    handleOrdenar = (new_categorias, nivel, parent) => {

        this.props.baseConocimientoOrdenar({
            new_categorias: new_categorias,
            nivel: nivel,
            parent: parent,
            idKnowledgeBase: this.props.match.params.idKnowledgeBase
        },(err) => {
            if(err)
            {
                this.setState({
                    loading: {
                        error: true
                    }
                });
            }
        });

    }

    handleChangeSelect = (e,aditional) => {

        let value = Validate.trim_int(e.target.value);
        let name = e.target.name;
        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            },
        });

    }

    render() 
    {
        return (
            <PrivateLayout
                loading={this.state.loading.conocimiento}
                error={this.state.loading.error}
                url={this.props.match.url}
            >
                <AlertUI
                    open={this.state.alert.open}
                    message={this.state.alert.message}
                    type={this.state.alert.type}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <ModalAgregar
                    agregar={this.state.depth !== 1 ? this.state.agregar : this.state.agregar_art}
                    depth={this.state.depth}
                    handleSubmit={this.handleSubmitAgregar}
                    handleChange={this.handleChange}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.agregar}
                    form_state={this.state.depth !== 1 ? this.state.forms.agregar : this.state.forms.agregar_art}
                    selects={this.state.selects}
                    handleChangeSelect={this.handleChangeSelect}
                    handleChangeQuill={this.handleChangeQuill}
                />
                <ModalEditar
                    editar={this.state.depth !== 2 ? this.state.editar : this.state.editar_art}
                    depth={this.state.depth}
                    handleSubmit={this.handleSubmitEditar}
                    handleChange={this.handleChange}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.editar}
                    form_state={this.state.depth !== 2 ? this.state.forms.editar : this.state.forms.editar_art}
                    selects={this.state.selects}
                    handleChangeSelect={this.handleChangeSelect}
                    handleChangeQuill={this.handleChangeQuill}
                />
                <ModalBorrar
                    borrar={this.state.borrar}
                    depth={this.state.depth}
                    handleSubmit={this.handleSubmitBorrar}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.borrar}
                    form_state={this.state.forms.borrar}
                />
                <Grid container spacing={24}>
                    <Grid item xs={12}>   
                        <ModuleTitleUI
                            title={this.props.match.params.idKnowledgeBase==='1' ? 'Tutoriales' : 'Preguntas Frecuentes'}
                            button={
                                <ButtonUI
                                    type="callf"
                                    label="Agregar"
                                    fullWidth={false}
                                    onClickFunc={() => this.modalHandler(true,'agregar', {item: {}, depth: null})}
                                    isLoading={false}
                                />
                            }
                        />
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <ConocimientoList 
                                    modalHandler={this.modalHandler}
                                    handleOrdenar={this.handleOrdenar}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </PrivateLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        conocimiento: state.conocimiento
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        baseConocimientoObtener: (data,callback) => dispatch(baseConocimientoObtener(data,callback)),
        baseConocimientoModificar: (data,callback) => dispatch(baseConocimientoModificar(data,callback)),
        baseConocimientoOrdenar: (data,callback) => dispatch(baseConocimientoOrdenar(data,callback)),
    }
}

export const BaseConocimiento = connect(mapStateToProps,mapDispatchToProps)(BaseConocimiento_);