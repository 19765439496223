import React from 'react';
import { Grid } from '@material-ui/core';
import { ModalUI, InputUI, ToggleUI, SelectUI, QuillUI } from '../../common';

const ModalEditar = (props) => {

    let { 
        editar, 
        depth,
        handleSubmit,
        handleChange, 
        modalHandler,
        modal_state,
        form_state,
        selects,
        handleChangeSelect,
        handleChangeQuill
    } = props;

    const getTitle = (depth) => {
        if(depth===2)
        {
            return 'Editar artículo';
        }
        else if (depth===1)
        {
            return 'Editar subcategoría';
        }
        else
        {
            return 'Editar categoría';
        }
    }
    let label = depth===2 ? 'editar_art' : 'editar';
    return (
        <ModalUI 
            open={modal_state}
            id="editar"
            title={getTitle(depth)}
            modalHandler={modalHandler}
            aditional_param={label}
            handleSubmit={handleSubmit}
            button_label="Editar"
            button_disabled={false}
            button_loading={form_state}
        >
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <InputUI
                        handleChange={handleChange}
                        input={editar.title}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputUI
                        handleChange={handleChange}
                        input={editar.icon}
                    />
                </Grid>
                <ToggleUI show={depth===2}>
                    <Grid item xs={12}>
                        <SelectUI
                            data={editar.type}
                            options={selects.type_content}
                            handleChangeSelect={handleChangeSelect}
                        />
                    </Grid>
                    <ToggleUI show={editar.type!==undefined && editar.type.value===0}>
                        <Grid item xs={12}>
                            <QuillUI
                                contenido={editar.content_html ? editar.content_html.value : ''}
                                placeholder="Escriba aquí..."
                                handleChangeQuill={handleChangeQuill}
                            />
                        </Grid>
                    </ToggleUI>
                    <ToggleUI show={editar.type!==undefined && editar.type.value===1}>
                        <Grid item xs={12}>
                            <InputUI
                                handleChange={handleChange}
                                input={editar.external_link}
                            />
                        </Grid>
                    </ToggleUI>
                </ToggleUI>
            </Grid>
        </ModalUI>
    );

}

export {ModalEditar};