import React from 'react';
import { connect } from 'react-redux';
import ReactExport from "react-export-excel";
import {
    ButtonUI
} from '../../../../common';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const GenerarExcel_ = (props) => {
    let {cupones} = props;
    let data = [];
    const obtenerData = (cupones) => {
        if(cupones.length>0)
        {
            return cupones.map((cupon) => {
                let element = {
                    nombre: cupon.alianza.a_nombre,
                    email: cupon.alianza.a_email,
                    cbu: cupon.alianza.a_cbu,
                    codigo: cupon.ca_codigo,
                    monto: cupon.monto
                }
                data.push(element);
                return data;
            });
        }
        else
        {
            return data;
        }
    }

    return (
        <ExcelFile 
            filename='Liquidacion-referidos'
            element={
                <ButtonUI
                    type="callf"
                    label="Exportar"
                    disabled={cupones.length > 0 ? false : true}
                    onClickFunc={() => obtenerData(cupones)}
                />
            }
        >
            <ExcelSheet data={data} name="Cupones">
                <ExcelColumn label="Nombre" value="nombre"/>
                <ExcelColumn label="Email" value="email"/>
                <ExcelColumn label="CBU" value="cbu"/>
                <ExcelColumn label="Código" value="codigo"/>
                <ExcelColumn label="Monto" value="monto"/>
            </ExcelSheet>   
        </ExcelFile>
    );
}


const mapStateToProps = (state) => {
    return {
        cupones: state.cupones,
    };
}

const mapDispatchToProps = null;

// GenerarExcel_.propTypes = {

// };

export const GenerarExcel = connect(mapStateToProps,mapDispatchToProps)(GenerarExcel_);