/*
    Author: Luciano Ghione  
    Scope: Web
    Version: 1.0.0
    CheckboxUI is a react function component based on Material UI which is used to handle 
    user advanced controlled checkboxes in an easy and wide way.
*/
import React from 'react';
import uuid from 'uuid/v1';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox, withStyles } from '@material-ui/core';

const CheckboxUI_ = (props) => {

    const { 
        checkbox,
        classes,
        handleChangeCheckbox,
        inverse = false
    } = props;

    let {
        label,
        value,
        name,
        id,
        change_param,
    } = checkbox;
    
    let checked;

    if(inverse)
    {
        checked = value ? false : true;
    }
    else
    {
        checked = value ? true : false;
    }

    id = id ? id : (name+'_'+uuid().split('-')[0]);

    return (
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={(e) => handleChangeCheckbox(e,change_param)}
              id={id}
              name={name}
              value={value.toString()}
              color="primary"
            />
          }
          classes={{label: classes.label}}
          label={label}
        />
    )
}

//user-select: none

const styles = theme => ({
    label: {
        userSelect: 'none'
    }
});

CheckboxUI_.propTypes = {

    //Function called on checkbox event
    handleChangeCheckbox: PropTypes.func.isRequired,

    //Reverts the option check (1 unchecked, 0 checked)
    //Default: false (0 unchecked, 1 checked)
    inverse: PropTypes.bool,

    //The object initialized in state
    checkbox: PropTypes.shape({
        label:              PropTypes.string.isRequired,
        value:              PropTypes.any,
        name:               PropTypes.any,
        id:                 PropTypes.any,
        change_param:       PropTypes.any.isRequired, 
    })
};

export const CheckboxUI = withStyles(styles)(CheckboxUI_);
