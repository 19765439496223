import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { ModalUI } from '../../../common';

const ModalBorrar = (props) => {

    let {  
        handleSubmit,
        modalHandler,
        modal_state,
        form_state,
    } = props;
  
    return (
        <ModalUI 
            open={modal_state}
            id="borrar"
            title="Borrar dominio propio"
            modalHandler={modalHandler}
            aditional_param="borrar"
            handleSubmit={handleSubmit}
            button_label="Borrar"
            button_disabled={false}
            button_loading={form_state}
        >
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Typography>
                        Estás seguro que deseas borrar el dominio propio del cliente? Recuerda consultarle previamente y también que éste proceso de borrado puede demorar de 10 a 70 minutos aproximadamente.
                    </Typography>
                </Grid>
            </Grid>
        </ModalUI>
    );
}

ModalBorrar.propTypes = {

    //The form submit function
    //Called: handleSubmit(event,id)
    handleSubmit: PropTypes.func.isRequired,
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    //true if the modal is open
    modal_state: PropTypes.bool.isRequired,

    //true if the modal is open
    form_state: PropTypes.bool.isRequired,

};

export {ModalBorrar};
