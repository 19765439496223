import axios from 'axios';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const disposableBuscarEmail = (data, callback) => {
    return (dispatch, getState) => {
        axios.get(`${Config.BACKEND_ENDPOINT}/disposable/email/search`, { 
            params: data
        }).then(resp => {

            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const disposableBorrarEmail = (data, callback) => {
    return (dispatch, getState) => {
        axios.delete(`${Config.BACKEND_ENDPOINT}/disposable/email`, {data: data}).then(resp => {

            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}


export { 
    disposableBuscarEmail,
    disposableBorrarEmail
};