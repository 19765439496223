import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { ButtonUI } from './';

const ErrorLayout_ = (props) => {

    let {classes,url} = props;

    return (
        <Grid container direction="row" justify="center" alignItems="center" spacing={24}>
            <Grid item xs={12}>
                <Typography variant="h3" align="center" className={classes.errorColor} gutterBottom>
                    OOPS :(
                </Typography>
                <Typography variant="h4" align="center" className={classes.errorColor} gutterBottom>
                    Ha ocurrido un error inesperado
                </Typography>
                <Typography variant="h5" align="center" className={classes.errorColor} gutterBottom>
                    Intente nuevamente, si el problema persiste, intente más tarde.
                </Typography>
            </Grid>
            <Grid item lg={3} xs={6}>
                <ButtonUI 
                    label="Reintentar"
                    type="link"
                    link={url}
                    external_link
                />
            </Grid>
        </Grid>
    );
}

const styles = theme => ({
    errorColor: {
        color: '#999'
    }
});

export const ErrorLayout = withStyles(styles, { withTheme: true })(ErrorLayout_);
