import React from 'react';
import { Grid } from '@material-ui/core';
import { ModalUI, InputUI, SelectUI, ToggleUI, QuillUI } from '../../common';

const ModalAgregar = (props) => {

    let { 
        agregar, 
        depth,
        handleSubmit,
        handleChange, 
        modalHandler,
        modal_state,
        form_state,
        selects,
        handleChangeSelect,
        handleChangeQuill
    } = props;
    
    const getTitle = (depth) => {
        if(depth===1)
        {
            return 'Agregar artículo';
        }
        else if (depth===0)
        {
            return 'Agregar subcategoría';
        }
        else
        {
            return 'Agregar categoría';
        }
    }
    let label = depth===1 ? 'agregar_art' : 'agregar';
    return (
        <ModalUI 
            open={modal_state}
            id="agregar"
            title={getTitle(depth)}
            subtitle={agregar.c_padre.title ? 'Dentro de '+agregar.c_padre.title : ''}
            modalHandler={modalHandler}
            aditional_param={label}
            handleSubmit={handleSubmit}
            button_label="Agregar"
            button_disabled={false}
            button_loading={form_state}
        >
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <InputUI
                        handleChange={handleChange}
                        input={agregar.title}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputUI
                        handleChange={handleChange}
                        input={agregar.icon}
                    />
                </Grid>
                <ToggleUI show={depth===1}>
                    <Grid item xs={12}>
                        <SelectUI
                            data={agregar.type}
                            options={selects.type_content}
                            handleChangeSelect={handleChangeSelect}
                        />
                    </Grid>
                    <ToggleUI show={agregar.type!==undefined && agregar.type.value===0}>
                        <Grid item xs={12}>
                            <QuillUI
                                contenido={agregar.content_html ? agregar.content_html.value : ''}
                                placeholder="Escriba aquí..."
                                handleChangeQuill={handleChangeQuill}
                            />
                        </Grid>
                    </ToggleUI>
                    <ToggleUI show={agregar.type!==undefined && agregar.type.value===1}>
                        <Grid item xs={12}>
                            <InputUI
                                handleChange={handleChange}
                                input={agregar.external_link}
                            />
                        </Grid>
                    </ToggleUI>
                </ToggleUI>
            </Grid>
        </ModalUI>
    );
}

export {ModalAgregar};