import axios from 'axios';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';
import Reducers from '../reducers';

const identificarTienda = (data, callback) => {
    return (dispatch, getState) => {
        axios.get(`${Config.BACKEND_ENDPOINT}/buscar/identificar-tienda`, { 
            params: { 
                parametro_busqueda: data.parametro_busqueda 
                
            } }).then(resp => {

                return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const buscarUltimasTiendas = (data, callback) => {
    return (dispatch, getState) => {
        axios.get(`${Config.BACKEND_ENDPOINT}/buscar/tienda/recientes`, {
            params: data
        }).then(resp => {
            
            //guardo en redux
            const tiendas = resp.data.data;
            dispatch({type: data.page ? Reducers.TIENDAS_GUARDAR_NO_CLEAN : Reducers.TIENDAS_GUARDAR, data: tiendas });
            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}


export { 
    identificarTienda,
    buscarUltimasTiendas
};