import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    withStyles,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    CardHeader,
    Divider,
    Typography
} from '@material-ui/core';
import { 
    obtenerSinDimensiones
} from '../../../store/actions';
import {
    ToggleUI
} from '../../common';

class ProductosSinDimensiones_ extends Component
{   
    state={
        id_tienda: null,
        data_response: {
           
        },
        loading: {
            tienda: true,
            error: false
        },
    };

    componentDidMount() 
    {
        this.handleChangeId();
    }

    componentDidUpdate() 
    {
        this.handleChangeId();
    }


    handleChangeId = () => {
        if(this.state.id_tienda !== this.props.id_tienda)
        {
            this.setState({
                id_tienda: this.props.id_tienda,
                loading: {
                    ...this.state.loading,
                    tienda: true,
                    error: false
                }
            }, () => {
                this.props.isLoading(true);
                this.props.obtenerSinDimensiones({
                    xIdTienda: this.state.id_tienda,
                }, (err,resp) => {
                    this.props.isLoading(false);
                    this.setState({
                        data_response: resp.data,
                        loading: {
                            ...this.state.loading,
                            tienda: false,
                            error: (this.state.loading.error || err) ? true : false
                        }
                    });
                });
            });
        }
    }
 
    render()
    {
        let {classes} = this.props;
        let product_list= [];
        if(this.state.data_response.length) 
        {
            for(const producto in this.state.data_response)
            {
                product_list.push(this.state.data_response[producto].p_nombre);
            };
        };

        return(
            <Fragment>
                <ToggleUI show={this.state.loading.error===false && this.state.loading.tienda===false}>
                    <Card className={classes.card}>
                        <CardHeader
                            title="Tienda"
                            className={classes.card_header}
                            classes={{action: classes.card_action_button}}
                        />
                        <Divider/>
                        <CardContent className={classes.card_content_no_padding}>
                            <List>
                                <ListItem>
                                    <ToggleUI show={this.state.data_response.length > 0}>
                                        <Typography>
                                            Productos sin dimensiones: {product_list.join(', ')}
                                        </Typography>
                                    </ToggleUI>
                                    <ToggleUI show={this.state.data_response.length === 0}>
                                        <Typography>
                                            No se encontraron productos sin dimensión en la tienda seleccionada
                                        </Typography>
                                    </ToggleUI>
                                </ListItem> 
                            </List>
                        </CardContent>
                    </Card>
                </ToggleUI>
                <ToggleUI show={this.state.loading.error}>
                    <Card className={classes.card}>
                        <CardHeader
                            title="Tienda"
                            className={classes.card_header}
                            classes={{action: classes.card_action_button}}
                        />
                        <Divider/>
                        <CardContent className={classes.card_content_no_padding}>
                            <List>
                                <ListItem>
                                    <ListItemText 
                                        primary="No se han encontrado datos"
                                        secondary="Por favor, ingrese un ID Tienda y un filtro"
                                    />
                                </ListItem> 
                            </List>
                        </CardContent>
                    </Card>
                </ToggleUI>
            </Fragment>
        );
    }
}

const styles = theme => ({
    span: {
        display: 'block',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    list: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '0',
        '&:last-child': {
            borderBottom: '0px solid transparent'
        }
    },
    list_text: {
        paddingRight: '100px'
    },
    table: {
        minWidth: '100%',
    },    
    card: {
        ...theme.card.card,
        padding: '0px !important'
    },
    card_content: theme.card.card_content,
    card_content_no_padding: {
        ...theme.card.card_content,
        padding: '0px !important',
        overflowX: 'auto'
    }
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
    return {
        obtenerSinDimensiones: (data,callback) => dispatch(obtenerSinDimensiones(data,callback)),
    }
}

ProductosSinDimensiones_.propTypes = {

    //Función que hace la request a la api
    obtenerSinDimensiones: PropTypes.func.isRequired,

    isLoading: PropTypes.func.isRequired,

    //Parámetro de búsqueda que recibe el componente por props
    id_tienda: PropTypes.string.isRequired

};

export const ProductosSinDimensiones = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(ProductosSinDimensiones_));
