import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    IconButton,
    Icon,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    withStyles
} from '@material-ui/core';
import { Validate } from '../../../other';

const CuponAlianzaList_ = (props) => {
    let { idAlianzas, cupones, classes, modalHandler, filtro} = props;
    const renderizarCupones = (cupones,modalHandler,classes) => {
        if(cupones.length>0)
        {
            return cupones.filter( cupones => {
                let {ca_codigo, ca_tipo} = cupones;
                let tipo1 = 'Cupón de días extra';
                let tipo2 = 'Cupón de monto fijo';
                let busqueda = filtro.toLowerCase();
                if(
                    (ca_codigo !== null && ca_codigo.toLowerCase().includes(busqueda)) 
                    || (ca_tipo !== null && (tipo1.toLowerCase().includes(busqueda) || tipo2.toLowerCase().includes(busqueda)) ) 
                )
                {
                    return true
                }
                else
                {
                    return false
                }
            }).map((cupon,index) => {
                    return  (
                        <ListItem divider={index<(cupones.length-1)} key={cupon.idCuponAlianza} className={classes.list_item} >
                            <ListItemText
                                primary={cupon.ca_codigo}
                                classes={{root: classes.list_text}}
                                secondary={
                                    <span className={classes.span}>
                                        <span className={classes.span}>Tipo: {(cupon.ca_tipo===1 ? 'Cupón de días extra' : 'Cupón de monto fijo')}</span>
                                        <span className={classes.span}>{(cupon.ca_tipo===1 ? `Días: ${cupon.ca_dias}`: `Monto: $${Validate.number_format(cupon.ca_monto)}`)}</span>
                                    </span>
                                }
                            />
                            <ListItemSecondaryAction>
                                <IconButton aria-label="Leads" component={(props) => <Link to={`/cupon-alianza/${idAlianzas}/${cupon.idCuponAlianza}`} {...props}/>}>
                                    <Icon>timeline</Icon>
                                </IconButton>
                                <IconButton aria-label="Link" href={"https://www.empretienda.com/partner/"+cupon.ca_codigo.toLowerCase()} target="_blank">
                                    <Icon>share</Icon>
                                </IconButton>
                                <IconButton aria-label="Editar" onClick={(e) => modalHandler(true,'editar',cupon)}>
                                    <Icon>edit</Icon>
                                </IconButton>
                                <IconButton aria-label="Borrar" onClick={(e) => modalHandler(true,'borrar',cupon)}>
                                    <Icon>delete</Icon>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
            });
        }
        else
        {
            return (
                <ListItem>
                    <ListItemText 
                        primary="No se han encontrado cupones activos"
                        secondary="Puedes agregar uno haciendo click en Agregar"
                    />
                </ListItem>
            );
        }
    }

    return (
        <List classes={{root: classes.list}}>
            {renderizarCupones(cupones,modalHandler,classes)}
        </List>
    );
}

const styles = theme => ({
    span: {
        display: 'block',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    list: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '0',
        '&:last-child': {
            borderBottom: '0px solid transparent'
        }
    },
    list_text: {
        paddingRight: '100px'
    }
});

const mapStateToProps = (state) => {
    return {
        cupones: state.cupones,
        auth: state.auth,
    }
}

const mapDispatchToProps = null;

CuponAlianzaList_.propTypes = {
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    filtro: PropTypes.string.isRequired

};

export const CuponAlianzaList = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(CuponAlianzaList_));
