import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { ModalUI } from '../../../common';

const ModalDesbloquear = (props) => {

    let {  
        handleSubmit,
        modalHandler,
        modal_state,
        form_state,
    } = props;
  
    return (
        <ModalUI 
            open={modal_state}
            id="desbloquear"
            title="Desbloquear tienda"
            modalHandler={modalHandler}
            aditional_param="desbloquear"
            handleSubmit={handleSubmit}
            button_label="Desbloquear"
            button_disabled={false}
            button_loading={form_state}
        >
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Typography>
                        Estás seguro que deseas desbloquear la tienda? El cliente podrá acceder al panel y la tienda quedará habilitada.
                    </Typography>
                </Grid>
            </Grid>
        </ModalUI>
    );
}

ModalDesbloquear.propTypes = {

    //The form submit function
    //Called: handleSubmit(event,id)
    handleSubmit: PropTypes.func.isRequired,
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    //true if the modal is open
    modal_state: PropTypes.bool.isRequired,

    //true if the modal is open
    form_state: PropTypes.bool.isRequired,

};

export {ModalDesbloquear};
