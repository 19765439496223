import axios from 'axios';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const obtenerEstadisticasHistoricas = (callback) => {

    return (dispatch, getState) => {
        axios.get(Config.BACKEND_ENDPOINT+'/statistic/historic').then(resp => {

            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const obtenerEstadisticasTienda = (data, callback) => {

    return (dispatch, getState) => {
        axios.get(Config.BACKEND_ENDPOINT+'/statistic/periodic',{
            params: data
        }).then(resp => {

            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const obtenerEstadisticasLtvChurn = (data, callback) => {

    return (dispatch, getState) => {
        axios.get(Config.BACKEND_ENDPOINT+'/statistic/ltv-churn',{
            params: data
        }).then(resp => {

            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const obtenerEstadisticasMarketplace = (data, callback) => {

    return (dispatch, getState) => {
        axios.get(Config.BACKEND_ENDPOINT+'/statistic/marketplace',{
            params: data
        }).then(resp => {

            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

export { 
    obtenerEstadisticasHistoricas,
    obtenerEstadisticasTienda,
    obtenerEstadisticasLtvChurn,
    obtenerEstadisticasMarketplace
};