/*
    Author: Luciano Ghione  
    Scope: Web
    Version: 1.0.0
    InputUI is a react function component based on Material UI which is used to handle 
    user advanced controlled inputs in an easy way.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Input, FormHelperText, InputAdornment, withStyles } from '@material-ui/core';
import Search from '@material-ui/icons/Search';

const InputSearchUI_ = (props) => {

    let {
        classes, 
        handleChange, 
        input 
    } = props;

    let {
        disabled, 
        type, 
        value, 
        label, 
        id, 
        help,
        change_param = null,
        placeholder = null
    } = input;

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor={id} shrink={(placeholder ? true : false)}>{label}</InputLabel>
            <Input
                disabled={disabled}
                type={type}
                fullWidth
                id={id}
                value={value}
                onChange={(e) => handleChange(e,change_param)}
                onBlur={(e) => handleChange(e,change_param)}
                aria-describedby={id+'-text'}
                endAdornment={
                    <InputAdornment position="end">
                        <Search classes={{root: classes.search}}/>
                    </InputAdornment>
                }
                placeholder={placeholder}
                classes={{root: classes.root, input: classes.input}}
            />
            {
                help ? (
                    <FormHelperText id={id+'-text'}>{help}</FormHelperText>
                ) : ''
            }
        </FormControl>
    );

}

const styles = theme => ({
    search: {
        color: 'rgba(0,0,0,0.54)'
    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset"
        }
    }
});

InputSearchUI_.propTypes = {

    //Object used to add some styling with withStyles
    classes: PropTypes.object,

    //Function used to update controlled input
    //handleChange(e,change_param = null)
    handleChange: PropTypes.func.isRequired,

    //The object initialized in state
    input: PropTypes.shape({
        disabled:       PropTypes.bool.isRequired, 
        id:             PropTypes.string.isRequired,
        type:           PropTypes.oneOf(['text','number']).isRequired,
        value:          PropTypes.oneOfType([PropTypes.number,PropTypes.string]).isRequired,
        label:          PropTypes.string.isRequired,
        change_param:   PropTypes.any, //adds a second parameter to handleChange if needed
        placeholder:    PropTypes.any,  
        help:           PropTypes.string,
    })

};

export const InputSearchUI = withStyles(styles)(InputSearchUI_);
