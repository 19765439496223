import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const cuponObtenerLeads = (data, callback) => {
    return (dispatch, getState) => {
        axios.get(`${Config.BACKEND_ENDPOINT}/cupon-alianza/${data.idCuponAlianza}`, { params: { idAlianzas: data.idAlianzas } }).then(resp => {
            
            //guardo en redux
            const leads = [resp.data.data];
            dispatch({type: Reducers.CUPON_LEADS_GUARDAR, data: leads });
            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const cuponAbonarLeads = (data, callback) => {
    return (dispatch, getState) => {
        axios.put(`${Config.BACKEND_ENDPOINT}/cupon-alianza/${data.idCuponAlianza}/pago/${data.idTienda}`).then(resp => {
            
            //guardo en redux
            const lead_abonado = {Tienda_idTienda: data.idTienda}
            dispatch({type: Reducers.CUPON_LEADS_ABONADO, data: lead_abonado });
            return callback(false, resp.data);
            
        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

export { 
    cuponObtenerLeads,
    cuponAbonarLeads
};