import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ModalUI, DateTimeUI } from '../../common';

const ModalPeriodo = (props) => {

    let { 
        handleSubmit,
        modalHandler,
        modal_state,
        handleChangeDate,
        periodo_desde,
        periodo_hasta
    } = props;
  
    return (
        <ModalUI 
            open={modal_state}
            id="seleccionar_periodo"
            title="Seleccionar período"
            modalHandler={modalHandler}
            aditional_param="seleccionar_periodo"
            handleSubmit={handleSubmit}
            button_label="Aplicar"
            button_disabled={false}
            button_loading={false}
        >
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <DateTimeUI
                        handleChangeDate={handleChangeDate}
                        inputdate={periodo_desde}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <DateTimeUI
                        handleChangeDate={handleChangeDate}
                        inputdate={periodo_hasta}
                    />
                </Grid>
            </Grid>
        </ModalUI>
    );
}

ModalPeriodo.propTypes = {
    
    //The form submit function
    //Called: handleSubmit(event,id)
    handleSubmit: PropTypes.func.isRequired,

    //Called: handleChange(event,id)
    handleChangeDate: PropTypes.func.isRequired,
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    //true if the modal is open
    modal_state: PropTypes.bool.isRequired,

    periodo_desde: PropTypes.object.isRequired,

    periodo_hasta: PropTypes.object.isRequired,
}

export {ModalPeriodo};