import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    Grid,
    Icon,
    withStyles, 
    Card, 
    CardHeader, 
    CardContent, 
    Divider,
    LinearProgress
 } from '@material-ui/core';
 import { 
    alianzaObtenerAlianzas, 
    alianzaAgregar,
    alianzaEditar,
    alianzaBorrar
 } from '../../store/actions';
import { 
    PrivateLayout, 
    AlertUI, 
    ModuleTitleUI,
    ButtonUI, 
    InputUI
} from '../common';
import { 
    ModalAgregar, 
    ModalEditar, 
    ModalBorrar, 
    AlianzasList 
} from './subcomponents';
import { Validate, Regex, RegexExtra } from '../../other';
 
class Alianzas_ extends Component 
{
    state={

        filter: {
            search: {
                auth: false,
                validate: false,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Buscar',
                id: 'search',
                name: 'search',
                change_param: 'filter',
                regex: Regex.ANYTHING,
                value: '',
                default_value: '',
                placeholder: 'Nombre de alianza ...',
                messages: {
                    error: '',
                    error_extra: RegexExtra.ANYTHING
                }
            },
        },
        //campos para modal agregar
        agregar: {
            a_nombre: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Nombre',
                id: 'agregar_a_nombre',
                name: 'a_nombre',
                change_param: 'agregar',
                regex: Regex.STRING_GENERICO_5_90,
                value: '',
                default_value: '',
                placeholder: 'Nombre Alianza',
                messages: {
                    error: 'Por favor, ingrese un nombre de alianza válido',
                    error_extra: RegexExtra.STRING_GENERICO_5_90
                }
            },
            a_email: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'email',
                label: 'Email',
                id: 'agregar_a_email',
                name: 'a_email',
                change_param: 'agregar',
                regex: Regex.EMAIL,
                value: '',
                default_value: '',
                placeholder: 'Email Alianza',
                messages: {
                    error: 'Por favor, ingrese un email de alianza válido',
                    error_extra: RegexExtra.EMAIL
                }
            },
            a_razon_social: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Razón social',
                id: 'agregar_a_razon_social',
                name: 'a_razon_social',
                change_param: 'agregar',
                regex: Regex.STRING_GENERICO_5_90,
                value: '',
                default_value: '',
                placeholder: 'Razón social',
                messages: {
                    error: 'Por favor, ingrese una razón social válida',
                    error_extra: RegexExtra.STRING_GENERICO_5_90
                }
            },
            a_documento: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Documento',
                id: 'agregar_a_documento',
                name: 'a_documento',
                change_param: 'agregar',
                regex: Regex.DNI,
                value: '',
                default_value: '',
                placeholder: 'Documento',
                messages: {
                    error: 'Por favor, ingrese un documento válido',
                    error_extra: RegexExtra.DNI
                }
            },
            a_direccion: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Dirección',
                id: 'agregar_a_direccion',
                name: 'a_direccion',
                change_param: 'agregar',
                regex: Regex.DIRECCION_4_65,
                value: '',
                default_value: '',
                placeholder: 'Dirección',
                messages: {
                    error: 'Por favor, ingrese una dirección válida',
                    error_extra: RegexExtra.DIRECCION_4_65
                }
            },
            a_telefono: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Teléfono',
                id: 'agregar_a_telefono',
                name: 'a_telefono',
                change_param: 'agregar',
                regex: Regex.TELEFONO,
                value: '',
                default_value: '',
                placeholder: 'Teléfono',
                messages: {
                    error: 'Por favor, ingrese un teléfono válido',
                    error_extra: RegexExtra.TELEFONO
                }
            },
            a_cbu: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'CBU',
                id: 'agregar_a_cbu',
                name: 'a_cbu',
                change_param: 'agregar',
                regex: Regex.CBU,
                value: '',
                default_value: '',
                placeholder: 'CBU',
                messages: {
                    error: 'Por favor, ingrese un CBU válido',
                    error_extra: RegexExtra.CBU
                }
            },
        },
        //campos para modal editar
        editar: {
            idAlianzas: {
                validate: false,
                value: 0
            },
            a_nombre: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Nombre',
                id: 'editar_a_nombre',
                name: 'a_nombre',
                change_param: 'editar',
                regex: Regex.STRING_GENERICO_5_90,
                value: '',
                default_value: '',
                placeholder: 'Nombre Alianza',
                messages: {
                    error: 'Por favor, ingrese un nombre de alianza válido',
                    error_extra: RegexExtra.STRING_GENERICO_5_90
                }
            },
            a_email: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'email',
                label: 'Email',
                id: 'editar_a_email',
                name: 'a_email',
                change_param: 'editar',
                regex: Regex.EMAIL,
                value: '',
                default_value: '',
                placeholder: 'Email Alianza',
                messages: {
                    error: 'Por favor, ingrese un email de alianza válido',
                    error_extra: RegexExtra.EMAIL
                }
            },
            a_razon_social: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Razón social',
                id: 'editar_a_razon_social',
                name: 'a_razon_social',
                change_param: 'editar',
                regex: Regex.STRING_GENERICO_5_90,
                value: '',
                default_value: '',
                placeholder: 'Razón social',
                messages: {
                    error: 'Por favor, ingrese una razón social válida',
                    error_extra: RegexExtra.STRING_GENERICO_5_90
                }
            },
            a_documento: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Documento',
                id: 'editar_a_documento',
                name: 'a_documento',
                change_param: 'editar',
                regex: Regex.DNI,
                value: '',
                default_value: '',
                placeholder: 'Documento',
                messages: {
                    error: 'Por favor, ingrese un documento válido',
                    error_extra: RegexExtra.DNI
                }
            },
            a_direccion: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Dirección',
                id: 'editar_a_direccion',
                name: 'a_direccion',
                change_param: 'editar',
                regex: Regex.DIRECCION_4_65,
                value: '',
                default_value: '',
                placeholder: 'Dirección',
                messages: {
                    error: 'Por favor, ingrese una dirección válida',
                    error_extra: RegexExtra.DIRECCION_4_65
                }
            },
            a_telefono: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Teléfono',
                id: 'editar_a_telefono',
                name: 'a_telefono',
                change_param: 'editar',
                regex: Regex.TELEFONO,
                value: '',
                default_value: '',
                placeholder: 'Teléfono',
                messages: {
                    error: 'Por favor, ingrese un teléfono válido',
                    error_extra: RegexExtra.TELEFONO
                }
            },
            a_cbu: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'CBU',
                id: 'editar_a_cbu',
                name: 'a_cbu',
                change_param: 'editar',
                regex: Regex.CBU,
                value: '',
                default_value: '',
                placeholder: 'CBU',
                messages: {
                    error: 'Por favor, ingrese un CBU válido',
                    error_extra: RegexExtra.CBU
                }
            },
        },
        //datos para el modal borrar
        borrar: {
            idAlianzas: 0,
            a_nombre: ''
        },
        loading: {
            init_alianzas: true,
            alianzas: true,
            error: false
        },
        //modales
        modals: {
            agregar: false,
            editar: false,
            borrar: false,
        },
        forms: {
            agregar: false,
            editar: false,
            borrar: false,
        },
        alert: {
            open: false,
            type: 'error',
            message: ''
        },
    };

    componentDidMount()
    {
        this.props.alianzaObtenerAlianzas((err,resp) => {
            this.setState({
                loading: {
                    ...this.state.loading,
                    alianzas: false,
                    error: (this.state.loading.error || err) ? true : false
                }
            });
        });
    }

    handleChange = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            }
        },() => {
            Validate.validate_input(this.state[aditional][name],blur,(input) => {
                this.setState({
                    [aditional]: {
                        ...this.state[aditional],
                        [name]: input
                    }
                });
            });
        });
    }

    handleChangeFilter = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;
    
        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                },
            }
        });
    }

    //submits
    handleSubmitAgregar = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                agregar: true
            }
        });
        let form = this.state.agregar;

        if(Validate.validar_formulario(form))
        {
            this.props.alianzaAgregar({
                a_nombre: form.a_nombre.value,
                a_email: form.a_email.value,
                a_razon_social: form.a_razon_social.value,
                a_documento: form.a_documento.value,
                a_direccion: form.a_direccion.value,
                a_telefono: form.a_telefono.value,
                a_cbu: form.a_cbu.value
            },(err,resp) => {
                if(err)
                {
                    this.setState({
                        forms: {
                            ...this.state.forms,
                            agregar: false
                        },
                        alert: {
                            open: true,
                            message: resp.message,
                            type: 'error'
                        }
                    });
                }
                else
                {
                    this.setState({
                        forms: {
                            ...this.state.forms,
                            agregar: false
                        },
                        alert: {
                            open: true,
                            message: resp.message,
                            type: 'success'
                        },
                        modals: {
                            ...this.state.modals,
                            agregar: false
                        }
                    },() => {
                        let form_obj = Validate.limpiar_formulario(this.state.agregar);
                        this.setState({
                            agregar: form_obj
                        });
                    });
                };
            });
        }
        else
        {
            Validate.validar_formulario_actualizar_obj(form,(new_form) => {
                this.setState({
                    agregar: new_form,
                    forms: {
                        ...this.state.forms,
                        agregar: false
                    },
                    alert: {
                        ...this.state.alert,
                        open: true,
                        message: 'Por favor, revisá tus datos, tenés algunos errores',
                        type: 'error'
                    }
                });
            });
        }
    }

    handleSubmitEditar = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                editar: true
            }
        });
        let form = this.state.editar;

        if(Validate.validar_formulario(form))
        {
            this.props.alianzaEditar({
                idAlianzas: form.idAlianzas.value,
                a_nombre: form.a_nombre.value,
                a_email: form.a_email.value,
                a_razon_social: form.a_razon_social.value,
                a_documento: form.a_documento.value,
                a_direccion: form.a_direccion.value,
                a_telefono: form.a_telefono.value,
                a_cbu: form.a_cbu.value
            },(err,resp) => {

                if(err)
                {
                    this.setState({
                        forms: {
                            ...this.state.forms,
                            editar: false
                        },
                        alert: {
                            open: true,
                            message: resp.message,
                            type: 'error'
                        }
                    });
                }
                else
                {
                    this.setState({
                        forms: {
                            ...this.state.forms,
                            editar: false
                        },
                        alert: {
                            open: true,
                            message: resp.message,
                            type: 'success'
                        },
                        modals: {
                            ...this.state.modals,
                            editar: false
                        }
                    },() => {
                        let form_obj = Validate.limpiar_formulario(this.state.editar);
                        this.setState({
                            editar: form_obj
                        });
                    });
                }
            });
        }
        else
        {
            Validate.validar_formulario_actualizar_obj(form,(new_form) => {
                this.setState({
                    editar: new_form,
                    forms: {
                        ...this.state.forms,
                        editar: false
                    },
                    alert: {
                        ...this.state.alert,
                        open: true,
                        message: 'Por favor, revisá tus datos, tenés algunos errores',
                        type: 'error'
                    }
                });
            });
        }
    }

    handleSubmitBorrar = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                borrar: true
            }
        });

        let form = this.state.borrar;

        this.props.alianzaBorrar({idAlianzas: form.idAlianzas}, (err,resp) => {
            if(err)
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        borrar: false
                    },
                    alert: {
                        open: true,
                        message: resp.message,
                        type: 'error'
                    }
                });
            }
            else
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        borrar: false
                    },
                    alert: {
                        open: true,
                        message: resp.message,
                        type: 'success'
                    },
                    modals: {
                        ...this.state.modals,
                        borrar: false
                    }
                });
            }
        });
    }

    //abre y cierra los modales
    modalHandler = (open,id,aditional) => {
        switch(id) 
        {
            case 'agregar':
                this.setState({
                    modals: {
                        ...this.state.modals,
                        [id]: open
                    }
                }, () => {
                    if(!open)
                    {
                        //limpio el formulario del modal agregar
                        let form_obj = Validate.limpiar_formulario(this.state.agregar);
                        this.setState({
                            agregar: form_obj
                        });
                    }
                });  
            break;
            case 'borrar':
                if(open)
                {
                    let alianza = aditional;
                    this.setState({
                        borrar: {
                            ...this.state.borrar,
                            idAlianzas: alianza.idAlianzas,
                            a_nombre: alianza.a_nombre

                        },
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
                else
                {
                    this.setState({
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
            break;
            case 'editar':
                if(open)
                {
                    let alianza = aditional;
                    Validate.cargar_formulario_sin_nulos(alianza, Validate.limpiar_formulario(this.state.editar), (editar_obj) => {
                        this.setState({
                            editar: editar_obj,
                            modals: {
                                ...this.state.modals,
                                [id]: open
                            }
                        });
                    });
                }
                else
                {
                    this.setState({
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
            break;
            default:
            break;
        }
    }

    //cierra las alertas
    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }

    render() 
    {
        const { classes } = this.props;
        return (
            <PrivateLayout 
                error={this.state.loading.error} 
                url={this.props.match.url}
            >
                <ModalAgregar
                    agregar={this.state.agregar}
                    handleSubmit={this.handleSubmitAgregar}
                    handleChange={this.handleChange}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.agregar}
                    form_state={this.state.forms.agregar}
                />
                <ModalEditar
                    editar={this.state.editar}
                    handleSubmit={this.handleSubmitEditar}
                    handleChange={this.handleChange}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.editar}
                    form_state={this.state.forms.editar}
                />
                <ModalBorrar
                    borrar={this.state.borrar}
                    handleSubmit={this.handleSubmitBorrar}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.borrar}
                    form_state={this.state.forms.borrar}
                />
                <AlertUI
                    open={this.state.alert.open}
                    message={this.state.alert.message}
                    type={this.state.alert.type}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <ModuleTitleUI 
                    title="Alianzas"
                    button={
                        <ButtonUI
                            type="link"
                            label="Liquidación masiva"
                            fullWidth={false}
                            link='/cupon-alianza/liquidacion-masiva'
                        />
                    }
                    subtitles={[
                        'Aquí podrás ver todas las alianzas existentes'
                    ]}
                />
                <div style={{ paddingBottom: 12 }}>
                    <Grid container spacing={24}>
                        <Grid item md={4} sm={6} xs={12}>
                            <InputUI
                                handleChange={this.handleChangeFilter}
                                input={this.state.filter.search}
                                end_adornment={<Icon>search</Icon>}
                            />
                        </Grid>
                    </Grid>
                </div> 
                <Card className={classes.card_below}>
                    <CardHeader
                        title="Alianzas"
                        className={classes.card_header}
                        classes={{action: classes.card_action_button}}
                        action={
                            <ButtonUI
                                type="callf"
                                label="Agregar"
                                onClickFunc={() => this.modalHandler(true,'agregar','agregar')}
                            />
                        }
                    />
                    <Divider/>
                    <CardContent className={classes.card_content}>
                        {(this.state.loading.alianzas) ? <LinearProgress/> : <AlianzasList modalHandler={this.modalHandler} filtro={this.state.filter.search.value} />} 
                    </CardContent>
                </Card>
            </PrivateLayout>
        );
    }
}

const styles = theme => ({
    card: theme.card.card,
    card_header: theme.card.card_header,
    card_header_title: theme.card.card_header_title,
    card_actions: theme.card.card_actions_public,
    card_content: {
        padding: 0,
        '&:last-child': {
            padding: 0
        }
    },
});

const mapStateToProps = (state) => {
    return {
        alianzas: state.alianza,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        alianzaAgregar: (data,callback) => dispatch(alianzaAgregar(data,callback)),
        alianzaEditar: (data,callback) => dispatch(alianzaEditar(data,callback)),
        alianzaBorrar: (data,callback) => dispatch(alianzaBorrar(data,callback)),
        alianzaObtenerAlianzas: (callback) => dispatch(alianzaObtenerAlianzas(callback)),
    }
}

// Alianzas_.propTypes = {
    
// };

export const Alianzas = withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(Alianzas_));
