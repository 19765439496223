/*
    Author: Luciano Ghione  
    Scope: Web
    Version: 1.0.0
    ButtionUI is a react function component based on Material UI which is used to handle 
    user advanced controlled buttons in an easy and wide way.
*/
/* eslint-disable no-unreachable */

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, CircularProgress, withStyles } from '@material-ui/core';

const size_circular_loading = (sizeBtn) => {
    switch(sizeBtn)
    {
        case 'small':
            return 22;
        case 'medium':
            return 24;
        case 'large':
            return 25;
        default: 
            return 0;
    }
}

const ButtonUI_ = (props) => {

    let {
        type, 
        onClickFunc = null,
        aditionalParamFunc = null,
        link = null, 
        external_link = false,
        open_new_tab = false,
        size = "medium",
        variant = "contained",
        border_radius = false,
        label,
        color = "primary",
        fullWidth = true,
        disabled = false,
        isLoading = false,
        classes
    } = props;

    //si esta cargando, deshabilito el boton
    disabled = isLoading ? true : disabled;

    //calculo el size del circulo cuando carga
    let circular_size = size_circular_loading(size);

    //si esta cargando, muestro el loading
    let label_btn = isLoading ? (
        <CircularProgress size={circular_size} thickness={4.5} classes={{root: classes.progress}}/>
    ) : label;

    switch(type)
    {
        //submit form
        case 'submit':
            return (
                <Button 
                    type="submit"
                    variant={variant} 
                    size={size} 
                    color={color}
                    disabled={disabled}
                    fullWidth={fullWidth}
                    classes={{
                        root: border_radius ? classes.root_radius : classes.root, 
                        label: border_radius ? classes.label_radius : classes.label
                    }}
                >
                    {label_btn}
                </Button>
            );
        break;
        
        //call some function 
        case 'callf':
            return (
                <Button 
                    variant={variant} 
                    size={size} 
                    color={color}
                    disabled={disabled}
                    onClick={(e) => onClickFunc(e,aditionalParamFunc)}
                    fullWidth={fullWidth}
                    classes={{
                        root: border_radius ? classes.root_radius : classes.root, 
                        label: border_radius ? classes.label_radius : classes.label
                    }}
                >
                    {label_btn}
                </Button>
            );
        break;

        //go to specific link
        default:
            if(external_link)
            {
                let target = open_new_tab ? '_blank' : '_self';
                return (
                    <Button 
                        variant={variant} 
                        size={size} 
                        color={color}
                        disabled={disabled}
                        href={link}
                        target={target}
                        fullWidth={fullWidth}
                        classes={{
                            root: border_radius ? classes.root_radius : classes.root, 
                            label: border_radius ? classes.label_radius : classes.label
                        }}
                    >
                       {label_btn}
                    </Button>
                );
            }
            else
            {
                return (
                    <Button 
                        variant={variant} 
                        size={size} 
                        color={color}
                        disabled={disabled}
                        component={Link}
                        to={link}
                        fullWidth={fullWidth}
                        classes={{
                            root: border_radius ? classes.root_radius : classes.root, 
                            label: border_radius ? classes.label_radius : classes.label
                        }}
                    >
                       {label_btn}
                    </Button>
                );
            }
        break;
    }
}

const styles = theme => ({
    progress: {
        color: '#9f9f9f !important',
    },
    root: {
        boxShadow: 'none',
        borderRadius: '2px',
    },
    root_radius: {
        boxShadow: 'none',
        borderRadius: '40px',
    },
    label: {
        fontWeight: 400,
        letterSpacing: '0.5px',
        fontSize: '1rem',
    },
    label_radius: {
        fontWeight: 500,
        letterSpacing: '0.5px',
        fontSize: '1rem',
        textTransform: 'none',
        padding: '5px 20px'
    }
});

ButtonUI_.propTypes = {
    
    //Object used to add some styling with withStyles
    classes: PropTypes.object,

    //Type of the button and defines its behaviour
    //submit: to use it on a form
    //callf: to use it to call a function like onClickFunc(e,aditionalParamFunc)
    //link: to use it to go to specific link
    type: PropTypes.oneOf(['submit','callf','link']).isRequired,

    //Function used when type is callf
    //Default: null
    onClickFunc: PropTypes.func,

    //Aditional param to be passed on onClickFunc
    //Default: null
    aditionalParamFunc: PropTypes.any,

    //Link to go to when type is link
    //Default: null
    link: PropTypes.string,

    //If true, the link is an external website
    //It works if type=link
    //Default: false
    external_link: PropTypes.bool,

    //The button opens a new tab with the link
    //It works if type=link and external_link=true
    //Default: false
    open_new_tab: PropTypes.bool,

    //Defines if the button has border or not
    //Default: false
    border_radius: PropTypes.bool,

    //Size of the button
    //Default: medium
    size: PropTypes.oneOf(['small', 'medium', 'large']),

    //Format of the button
    //contained: it has background
    //outlined: it has no background, only border and label
    //Default: contained
    variant: PropTypes.oneOf(['contained','outlined','text']),

    //The label of the button
    label: PropTypes.any.isRequired,

    //The color of the button
    //Default: primary
    color: PropTypes.oneOf(['primary','secondary','default']),

    //The button cover all available space of its father
    //Default: true
    fullWidth: PropTypes.bool,

    //The button cannot be clicked if true
    //Default: false
    disabled: PropTypes.bool,

    //The button shows a loading spinner if true
    //Default: false
    isLoading: PropTypes.bool
    
};

export const ButtonUI = withStyles(styles)(ButtonUI_);
