import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const cuponObtenerCuponesAlianza = (data, callback) => {
    return (dispatch, getState) => {
        axios.get(Config.BACKEND_ENDPOINT+'/cupon-alianza', { params: { idAlianzas: data } }).then(resp => {
            
            //guardo en redux
            const cupones = resp.data.data;
            dispatch({type: Reducers.CUPON_ALIANZA_GUARDAR, data: cupones });
            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const cuponAlianzaAgregar = (data,callback) => {
    return (dispatch, getState) => {
        axios.post(Config.BACKEND_ENDPOINT+'/cupon-alianza', data).then(resp => {
            
            //guardo en redux
            const nuevo_cupon = resp.data.data;
            dispatch({type: Reducers.CUPON_ALIANZA_AGREGAR, data: nuevo_cupon });
            return callback(false, resp.data);
            
        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
    
}

const cuponAlianzaEditar = (data,callback) => {

    return (dispatch, getState) => {
        axios.put(`${Config.BACKEND_ENDPOINT}/cupon-alianza/${data.idCuponAlianza}`, data).then(resp => {
            
            //guardo en redux
            const cupon_editado = resp.data.data;
            dispatch({type: Reducers.CUPON_ALIANZA_EDITAR, data: cupon_editado });
            return callback(false, resp.data);
            
        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
    
}

const cuponAlianzaBorrar = (data,callback) => {

    return (dispatch, getState) => {
        axios.delete(`${Config.BACKEND_ENDPOINT}/cupon-alianza/${data.idCuponAlianza}`).then(resp => {
            
            //guardo en redux
            const cupon_borrado = resp.data.data;
            dispatch({type: Reducers.CUPON_ALIANZA_BORRAR, data: cupon_borrado });
            return callback(false, resp.data);
            
        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
    
}

const obtenerLiquidacionMasiva = (callback) => {
    return (dispatch, getState) => {
        axios.get(Config.BACKEND_ENDPOINT+'/cupon-alianza/pago/masivo').then(resp => {
            
            //guardo en redux
            const liquidacion_masiva = resp.data.data;
            dispatch({type: Reducers.CUPON_LIQUIDACION_MASIVA, data: liquidacion_masiva });
            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const cuponAbonarMasivo = (data, callback) => {
    return (dispatch, getState) => {
        axios.put(`${Config.BACKEND_ENDPOINT}/cupon-alianza/${data.idCuponAlianza}/pago/masivo`, data).then(resp => {
            
            //guardo en redux
            const cupon_abonado_masivo = {idCuponAlianza: data.idCuponAlianza}
            dispatch({type: Reducers.CUPON_ABONADO_MASIVO, data: cupon_abonado_masivo });
            return callback(false, resp.data);
            
        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const cuponAbonarTodo = (data, callback) => {
    return (dispatch, getState) => {
        axios.put(`${Config.BACKEND_ENDPOINT}/cupon-alianza/pago/masivo`, data).then(resp => {
            
            dispatch({type: Reducers.CUPON_TODO_ABONADO});
            return callback(false, resp.data);
            
        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

export { 
    cuponObtenerCuponesAlianza, 
    cuponAlianzaAgregar,
    cuponAlianzaEditar,
    cuponAlianzaBorrar,
    obtenerLiquidacionMasiva,
    cuponAbonarMasivo,
    cuponAbonarTodo
};