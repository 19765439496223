import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    withStyles,
    Card,
    CardContent,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    List,
    ListItem,
    ListItemText,
    CardHeader,
    Divider
} from '@material-ui/core';
import { 
    AlertUI
} from '../../common';
import { 
    identificarTienda
} from '../../../store/actions';

class ResultadoTienda_ extends Component
{   
    state={
        data_response: '',
        data_busqueda: '',
        loading: {
            init_alianzas: true,
            tienda: true,
            error: false
        },
        alert: {
            open: false,
            type: 'error',
            message: ''
        }
    };

    componentDidMount() 
    {
        this.obtenerTienda();
    }

    componentDidUpdate() 
    {
        this.obtenerTienda();
    }

    obtenerTienda = () => {
        if(this.state.data_busqueda !== this.props.busqueda)
        {
            this.props.isLoading(true);
            this.setState({
                data_busqueda: this.props.busqueda,
                data_response: null,
                loading: {
                    ...this.state.loading,
                    tienda: true,
                    error: false
                }
            }, () => {
                this.props.identificarTienda({
                    parametro_busqueda: this.props.busqueda,
                }, (err,resp) => {
                    this.props.isLoading(false);
                    this.setState({
                        data_response: resp.data.idTienda,
                        loading: {
                            ...this.state.loading,
                            tienda: false,
                            error: (this.state.loading.error || err) ? true : false
                        }
                    });
                    if(err)
                    {
                        this.setState({
                            alert: {
                                open: true,
                                message: resp.message,
                                type: 'error'
                            }
                        });
                    }
                });
            });
        }
    }

    //cierra las alertas
    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    } 
 
    render()
    {
        let {classes} = this.props;
        if(this.state.loading.tienda || this.state.loading.error)
        {
            return (
                <Card className={classes.card_below}>
                    <CardHeader
                        title="Tienda"
                        className={classes.card_header}
                        classes={{action: classes.card_action_button}}
                    />
                    <Divider/>
                    <AlertUI
                        open={this.state.alert.open}
                        message={this.state.alert.message}
                        type={this.state.alert.type}
                        handleCloseAlert={this.handleCloseAlert}
                    />
                    <CardContent className={classes.card_content}>
                        <List>
                            <ListItem>
                                    <ListItemText primary="No se han encontrado datos" />
                            </ListItem> 
                        </List>
                    </CardContent>
                </Card>
            )
        } 
        else
        {
            return (
                <Card className={classes.card_below}>
                    <CardHeader
                        title="Tienda"
                        className={classes.card_header}
                        classes={{action: classes.card_action_button}}
                    />
                    <Divider/>
                    <AlertUI
                        open={this.state.alert.open}
                        message={this.state.alert.message}
                        type={this.state.alert.type}
                        handleCloseAlert={this.handleCloseAlert}
                    />
                    <CardContent className={classes.card_content}>
                        <Table className={classes.table} padding="dense">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Datos de tienda</TableCell>
                                    <TableCell>ID</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow hover>
                                    <TableCell classes={{root: classes.table_class}}>{ this.state.data_busqueda }</TableCell>
                                    <TableCell classes={{root: classes.table_class}}>{ this.state.data_response }</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            );
        }
    }
}

const styles = theme => ({
    span: {
        display: 'block',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    list: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '0',
        '&:last-child': {
            borderBottom: '0px solid transparent'
        }
    },
    list_text: {
        paddingRight: '100px'
    },
    table: {
        minWidth: '100%',
    },    
    card: theme.card.card,
    card_header: theme.card.card_header,
    card_header_title: theme.card.card_header_title,
    card_actions: theme.card.card_actions_public,
    card_content: {
        padding: 0,
        '&:last-child': {
            padding: 0
        }
    },

});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
    return {
        identificarTienda: (data,callback) => dispatch(identificarTienda(data,callback)),
    }
}

ResultadoTienda_.propTypes = {

    //Función que hace la request a la api
    identificarTienda: PropTypes.func.isRequired,

    isLoading: PropTypes.func.isRequired,

    //Parámetro de búsqueda que recibe el componente por props
    busqueda: PropTypes.string.isRequired

};

export const ResultadoTienda = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(ResultadoTienda_));
