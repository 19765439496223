/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const cuponAlianzaReducer = (state = initState,action) => {
    let cupones;
    switch(action.type)
    {
        case Reducers.CUPON_ALIANZA_GUARDAR:

            cupones = action.data;     
            return cupones;

        break;

        case Reducers.CUPON_ALIANZA_AGREGAR:

            let nuevo_cupon_alianza = action.data;
            cupones = [
                ...state,
                nuevo_cupon_alianza
            ];      
            return cupones;

        break;

        case Reducers.CUPON_ALIANZA_EDITAR:

            let cupon_editado = action.data;
            cupones = state.map((cupon) => {
                if(cupon.idCuponAlianza === cupon_editado.idCuponAlianza)
                {
                    return cupon_editado;
                }
                else
                {
                    return cupon;
                }
            });    
            return cupones;

        break;

        case Reducers.CUPON_ALIANZA_BORRAR: 

            let cupon_borrado = action.data;
            cupones = state.filter((cupon) => {
                return cupon.idCuponAlianza !== cupon_borrado.idCuponAlianza;
            });   
            return cupones;

        break;

        case Reducers.CUPON_LIQUIDACION_MASIVA:

            cupones = action.data;     
            return cupones;

        break;

        case Reducers.CUPON_ABONADO_MASIVO: 

        let cupon_abonado = action.data;
        cupones = state.filter((cupon) => {
            return cupon.idCuponAlianza !== cupon_abonado.idCuponAlianza;
        });   
        return cupones;

        break;
        
        case Reducers.CUPON_TODO_ABONADO: 

        return initState;

        break;

        case Reducers.AUTH_UNSET:

            return initState;

        break;

        default: 

            return state;

        break;
    }
}

export { cuponAlianzaReducer };