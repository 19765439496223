import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    withStyles,
    Card,
    CardContent,
    Table,
    TableCell,
    TableBody,
    TableRow,
    List,
    ListItem,
    ListItemText,
    CardHeader,
    Divider,
    IconButton,
    Grid,
    Icon
} from '@material-ui/core';
import { 
    obtenerTienda,
    editarEmailTienda,
    bloquearTienda,
    desbloquearTienda,
    loginPanelTienda
} from '../../../store/actions';
import { 
    ModalEditar,
    ModalBloquear,
    ModalDesbloquear
} from './subdatosgenerales';
import { 
    AlertUI,
    ToggleUI,
    ButtonUI
} from '../../common';
import { Config, Validate, Regex, RegexExtra } from '../../../other';

class DatosGenerales_ extends Component
{   
    state={
        id_tienda: null,

        data_response: {},

        loading: {
            tienda: true,
            error: false
        },

        //campos para modal editar
        editar: {
            t_email: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Email',
                id: 'editar_t_email',
                name: 't_email',
                change_param: 'editar',
                regex: Regex.EMAIL,
                value: '',
                default_value: '',
                placeholder: 'Email de tienda',
                messages: {
                    error: 'Por favor, ingrese un email válido',
                    error_extra: RegexExtra.EMAIL
                }
            },
        },

        //modales
        modals: {
            editar: false,
            bloquear: false,
            desbloquear: false
        },

        forms: {
            editar: false,
            bloquear: false,
            desbloquear: false,
            login_panel: false
        },

        alert: {
            open: false,
            type: 'error',
            message: ''
        }
    };

    componentDidMount() 
    {
        this.handleChangeId();
    }

    componentDidUpdate() 
    {
        this.handleChangeId();
    }

    handleChangeId = () => {
        if(this.state.id_tienda !== this.props.id_tienda)
        {
            this.setState({
                id_tienda: this.props.id_tienda,
                loading: {
                    ...this.state.loading,
                    tienda: true,
                    error: false
                }
            }, () => {
                this.props.isLoading(true);
                this.props.obtenerTienda({
                    xIdTienda: this.state.id_tienda,
                }, (err,resp) => {
                    this.props.isLoading(false);
                    this.setState({
                        data_response: resp.data,
                        loading: {
                            ...this.state.loading,
                            tienda: false,
                            error: (this.state.loading.error || err) ? true : false
                        }
                    });
                });
            });
        }
    }
    
    refreshData = () => {
        this.setState({
            id_tienda: this.props.id_tienda,
            loading: {
                ...this.state.loading,
                tienda: true,
                error: false
            }
        }, () => {
            this.props.obtenerTienda({
                xIdTienda: this.state.id_tienda,
            }, (err,resp) => {
                this.setState({
                    data_response: resp.data,
                    loading: {
                        ...this.state.loading,
                        tienda: false,
                        error: (this.state.loading.error || err) ? true : false
                    }
                });
            });
        });        
    }

    handleChange = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            }
        },() => {
            Validate.validate_input(this.state[aditional][name],blur,(input) => {
                this.setState({
                    [aditional]: {
                        ...this.state[aditional],
                        [name]: input
                    }
                });
            });
        });
    }

    handleSubmitEditar = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                editar: true
            }
        });
        let form = this.state.editar;

        if(Validate.validar_formulario(form))
        {
            this.props.editarEmailTienda({
                t_email: form.t_email.value,
                xIdTienda: this.state.id_tienda,
            },(err,resp) => {

                if(err)
                {
                    this.setState({
                        forms: {
                            ...this.state.forms,
                            editar: false
                        },
                        alert: {
                            open: true,
                            message: resp.message,
                            type: 'error'
                        }
                    });
                }
                else
                {
                    this.setState({
                        forms: {
                            ...this.state.forms,
                            editar: false
                        },
                        alert: {
                            open: true,
                            message: resp.message,
                            type: 'success'
                        },
                        modals: {
                            ...this.state.modals,
                            editar: false
                        }
                    },() => {
                        let form_obj = Validate.limpiar_formulario(this.state.editar);
                        this.setState({
                            editar: form_obj
                        });
                    });
                    this.refreshData();       
                }
            });
        }
        else
        {
            Validate.validar_formulario_actualizar_obj(form,(new_form) => {
                this.setState({
                    editar: new_form,
                    forms: {
                        ...this.state.forms,
                        editar: false
                    },
                    alert: {
                        ...this.state.alert,
                        open: true,
                        message: 'Por favor, revisá tus datos, tenés algunos errores',
                        type: 'error'
                    }
                });
            });
        }
    }

    handleSubmitBloquear = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                bloquear: true
            }
        });

        this.props.bloquearTienda({
            xIdTienda: this.state.id_tienda
        },(err,resp) => {

            this.setState({
                forms: {
                    ...this.state.forms,
                    bloquear: false
                },
                alert: {
                    open: true,
                    message: resp.message,
                    type: err ? 'error' : 'success'
                },
                modals: {
                    ...this.state.modals,
                    bloquear: err ? true : false
                }
            }, () => this.refreshData()); 

        });
    }

    handleSubmitDesbloquear = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                desbloquear: true
            }
        });

        this.props.desbloquearTienda({
            xIdTienda: this.state.id_tienda
        },(err,resp) => {

            this.setState({
                forms: {
                    ...this.state.forms,
                    desbloquear: false
                },
                alert: {
                    open: true,
                    message: resp.message,
                    type: err ? 'error' : 'success'
                },
                modals: {
                    ...this.state.modals,
                    desbloquear: err ? true : false
                }
            }, () => this.refreshData()); 

        });
    }

    handleSubmitLoginPanel = () => {
        this.setState({
            forms: {
                ...this.state.forms,
                login_panel: true
            }
        });
        this.props.loginPanelTienda({
            xIdTienda: this.state.id_tienda
        },(err,resp) => {

            if(err)
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        login_panel: false
                    },
                    alert: {
                        open: true,
                        message: resp.message,
                        type: 'error'
                    }
                }); 
            }
            else
            {
                window.location.href = 'https://panel.empretienda.com';
            }

        });
    }

    //abre y cierra los modales
    modalHandler = (open,id,aditional) => {
        switch(id) 
        {
            case 'editar':
                if(open)
                {
                    let t_email = aditional;
                    Validate.cargar_formulario_sin_nulos(t_email, Validate.limpiar_formulario(this.state.editar), (editar_obj) => {
                        this.setState({
                            editar: editar_obj,
                            modals: {
                                ...this.state.modals,
                                [id]: open
                            }
                        });
                    });
                }
                else
                {
                    this.setState({
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
            break;
            default:
                this.setState({
                    modals: {
                        ...this.state.modals,
                        [id]: open
                    }
                });
            break;
        }
    }

    //cierra las alertas
    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }    
 
    render()
    {
        let {classes} = this.props;
        let t_metodo_pago;
        let t_offmode;

        //Determina el método de pago
        if(this.state.data_response.t_debito_automatico === 1) 
        {
            t_metodo_pago = 'Débito automático'
        } 
        else 
        { 
            if(this.state.data_response.t_pago_manual === 1) 
            {
                t_metodo_pago = 'Pago manual'
            } 
            else 
            {
                t_metodo_pago = 'N/A'
            }
        }

        //Determina si la tienda está apagada
        switch(this.state.data_response.t_offmode)
        {
            case 0:
                t_offmode = 'No'
            break;
                
            case 1:
                t_offmode = 'Apagada temporalmente'
            break;

            case 2:
                t_offmode = 'Dada de baja'
            break;

            default:
            break;
        }

        return(
            <Fragment>
                <ToggleUI show={this.state.loading.error}>
                    <Card className={classes.card}>
                        <CardHeader
                            title="Tienda"
                            className={classes.card_header}
                            classes={{action: classes.card_action_button}}
                        />
                        <Divider/>
                        <CardContent className={classes.card_content_no_padding}>
                            <List>
                                <ListItem>
                                    <ListItemText 
                                        primary="No se han encontrado datos"
                                        secondary="Por favor, ingrese un ID Tienda y un filtro"
                                    />
                                </ListItem> 
                            </List>
                        </CardContent>
                    </Card>
                </ToggleUI>
                <ToggleUI show={this.state.loading.error===false && this.state.loading.tienda===false}>
                    <Card className={classes.card}>
                        <CardHeader
                            title="Tienda"
                            className={classes.card_header}
                            classes={{action: classes.card_action_button}}
                            action={
                                <Grid container spacing={8}>
                                    <Grid item>
                                        <ButtonUI
                                            type="callf"
                                            label={this.state.data_response.t_activa===1 ? "Bloquear" : "Desbloquear"}
                                            onClickFunc={() => this.modalHandler(true, this.state.data_response.t_activa===1 ? "bloquear" : "desbloquear")}
                                        />
                                    </Grid>
                                    <ToggleUI show={Validate.in_array(this.props.auth.rol,[0,3])}>
                                        <Grid item>
                                            <ButtonUI
                                                type="callf"
                                                label="Ingresar"
                                                isLoading={this.state.forms.login_panel}
                                                onClickFunc={() => this.handleSubmitLoginPanel()}
                                            />
                                        </Grid>
                                    </ToggleUI>
                                </Grid>
                            }
                        />
                        <Divider/>
                        <ModalEditar
                            editar={this.state.editar}
                            handleSubmit={this.handleSubmitEditar}
                            handleChange={this.handleChange}
                            modalHandler={this.modalHandler}
                            modal_state={this.state.modals.editar}
                            form_state={this.state.forms.editar}
                        />
                        <ModalBloquear
                            handleSubmit={this.handleSubmitBloquear}
                            modalHandler={this.modalHandler}
                            modal_state={this.state.modals.bloquear}
                            form_state={this.state.forms.bloquear}
                        />
                        <ModalDesbloquear
                            handleSubmit={this.handleSubmitDesbloquear}
                            modalHandler={this.modalHandler}
                            modal_state={this.state.modals.desbloquear}
                            form_state={this.state.forms.desbloquear}
                        />
                        <AlertUI
                            open={this.state.alert.open}
                            message={this.state.alert.message}
                            type={this.state.alert.type}
                            handleCloseAlert={this.handleCloseAlert}
                        />
                        <CardContent className={classes.card_content_no_padding}>
                            <Table className={classes.table} padding="dense">
                                <TableBody>
                                    <TableRow hover>
                                        <TableCell>Nombre de tienda</TableCell>
                                        <TableCell classes={{root: classes.table_class}}>{ this.state.data_response.t_nombre }</TableCell>
                                    </TableRow>
                                    <TableRow hover>
                                        <TableCell>Email de tienda</TableCell>
                                        <TableCell classes={{root: classes.table_class}}>
                                            { this.state.data_response.t_email }
                                            <IconButton aria-label="Editar" onClick={(e) => this.modalHandler(true,'editar',this.state.data_response)}>
                                                <Icon>edit</Icon>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover>
                                        <TableCell>Número de teléfono</TableCell>
                                        <TableCell classes={{root: classes.table_class}}>{Validate.is_falsy(this.state.data_response.t_telefono) ? 'N/A' : this.state.data_response.t_telefono }</TableCell>
                                    </TableRow>
                                    <TableRow hover>
                                        <TableCell>Fecha de creación</TableCell>
                                        <TableCell classes={{root: classes.table_class}}>{ Validate.utc2local_format(this.state.data_response.t_fecha_creacion,Config.DATE_FORMAT) }</TableCell>
                                    </TableRow>
                                    <TableRow hover>
                                        <TableCell>Tienda en prueba</TableCell>
                                        <TableCell classes={{root: classes.table_class}}>{ this.state.data_response.t_prueba === 1 ? 'Sí' : 'No' }</TableCell>
                                    </TableRow>
                                    <TableRow hover>
                                        <TableCell>Medio de pago de suscripción utilizado</TableCell>
                                        <TableCell classes={{root: classes.table_class}}>{t_metodo_pago}</TableCell>
                                    </TableRow>
                                    <TableRow hover>
                                        <TableCell>Tienda en deuda</TableCell>
                                        <TableCell classes={{root: classes.table_class}}>{ this.state.data_response.t_deuda === 1 ? 'Sí' : 'No' }</TableCell>
                                    </TableRow>
                                    <TableRow hover>
                                        <TableCell>Tienda apagada</TableCell>
                                        <TableCell classes={{root: classes.table_class}}>{t_offmode}</TableCell>
                                    </TableRow>
                                    <TableRow hover>
                                        <TableCell>Tienda bloqueada</TableCell>
                                        <TableCell classes={{root: classes.table_class}}>{this.state.data_response.t_activa ? 'No' : 'Si'}</TableCell>
                                    </TableRow>
                                    <TableRow hover>
                                        <TableCell>Venta mayorista activa</TableCell>
                                        <TableCell classes={{root: classes.table_class}}>{ this.state.data_response.t_mayorista === 1 ? 'Sí' : 'No'}</TableCell>
                                    </TableRow>
                                    <TableRow hover>
                                        <TableCell>Funcion de productos digitales activa</TableCell>
                                        <TableCell classes={{root: classes.table_class}}>{ this.state.data_response.t_productos_digitales === 1 ? 'Sí' : 'No' }</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </ToggleUI>
            </Fragment>
        );
    }
}

const styles = theme => ({
    span: {
        display: 'block',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    list: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '0',
        '&:last-child': {
            borderBottom: '0px solid transparent'
        }
    },
    list_text: {
        paddingRight: '100px'
    },
    table: {
        minWidth: '100%',
    },    
    card: {
        ...theme.card.card,
        padding: '0px !important'
    },
    card_content: theme.card.card_content,
    card_content_no_padding: {
        ...theme.card.card_content,
        padding: '0px !important',
        overflowX: 'auto'
    }
});

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        obtenerTienda: (data,callback) => dispatch(obtenerTienda(data,callback)),
        editarEmailTienda: (data,callback) => dispatch(editarEmailTienda(data,callback)),
        bloquearTienda: (data,callback) => dispatch(bloquearTienda(data,callback)),
        desbloquearTienda: (data,callback) => dispatch(desbloquearTienda(data,callback)),
        loginPanelTienda: (data,callback) => dispatch(loginPanelTienda(data,callback)),
    }
}

DatosGenerales_.propTypes = {

    //Función que hace la request a la api
    obtenerTienda: PropTypes.func.isRequired,

    editarEmailTienda: PropTypes.func.isRequired,

    isLoading: PropTypes.func.isRequired,

    //Parámetro de búsqueda que recibe el componente por props
    id_tienda: PropTypes.string.isRequired

};

export const DatosGenerales = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(DatosGenerales_));
