import axios from 'axios';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const obtenerTienda = (data, callback) => {
    return (dispatch, getState) => {
        axios.get(`${Config.BACKEND_ENDPOINT}/tienda`, { 
            headers: { 
                "x-idTienda": data.xIdTienda 
                
            } }).then(resp => {

                return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const bloquearTienda = (data, callback) => {
    return (dispatch, getState) => {
        axios.put(`${Config.BACKEND_ENDPOINT}/tienda/bloquear`,data,{ 
            headers: { 
                "x-idTienda": data.xIdTienda 
            } 
        }).then(resp => {

                return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const desbloquearTienda = (data, callback) => {
    return (dispatch, getState) => {
        axios.put(`${Config.BACKEND_ENDPOINT}/tienda/desbloquear`,data,{ 
            headers: { 
                "x-idTienda": data.xIdTienda 
            } 
        }).then(resp => {

                return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const obtenerFacturacion = (data, callback) => {
    return (dispatch, getState) => {
        axios.get(`${Config.BACKEND_ENDPOINT}/tienda/plan`, { 
            headers: { 
                "x-idTienda": data.xIdTienda 
                
            } }).then(resp => {

                return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const obtenerSinDimensiones = (data, callback) => {
    return (dispatch, getState) => {
        axios.get(`${Config.BACKEND_ENDPOINT}/producto/sin-dimensiones`, { 
            headers: { 
                "x-idTienda": data.xIdTienda 
                
            } }).then(resp => {

                return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const obtenerDimensionesExcesivas = (data, callback) => {
    return (dispatch, getState) => {
        axios.get(`${Config.BACKEND_ENDPOINT}/producto/dimensiones-excesivas`, { 
            headers: { 
                "x-idTienda": data.xIdTienda 
                
            } }).then(resp => {

                return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const obtenerDominiosTienda = (data, callback) => {
    return (dispatch, getState) => {
        axios.get(`${Config.BACKEND_ENDPOINT}/dominio`, { 
            headers: { 
                "x-idTienda": data.xIdTienda 
                
            } }).then(resp => {

                return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const editarDominiosTienda = (data, callback) => {
    return (dispatch, getState) => {
        axios.put(`${Config.BACKEND_ENDPOINT}/dominio/gratuito`, 
        {
            d_nombre:data.d_nombre
        },
        { 
            headers: { 
                "x-idTienda": data.xIdTienda 
            }
        }).then(resp => {

                return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const borrarDominioPropioTienda = (data, callback) => {
    return (dispatch, getState) => {
        axios.delete(`${Config.BACKEND_ENDPOINT}/dominio/propio`, { 
            headers: { 
                "x-idTienda": data.xIdTienda 
            }
        }).then(resp => {

                return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const editarEmailTienda = (data, callback) => {
    return (dispatch, getState) => {
        axios.put(`${Config.BACKEND_ENDPOINT}/tienda/email`, 
        {
            t_email: data.t_email
        },
        { 
            headers: { 
                "x-idTienda": data.xIdTienda 
            }
        }).then(resp => {

                return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const editarUltimaFactura = (data, callback) => {
    return (dispatch, getState) => {
        axios.put(`${Config.BACKEND_ENDPOINT}/tienda/extender-dias`, 
        {
            dias: data.dias,
            tipo: data.tipo
        },
        { 
            headers: { 
                "x-idTienda": data.xIdTienda 
            }
        }).then(resp => {

                return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const desactivarEnvios = (data, callback) => {
    return (dispatch, getState) => {
        axios.put(`${Config.BACKEND_ENDPOINT}/medios-envio/desactivar`, 
        {
            metodo_envio: data.metodo_envio,
        },
        { 
            headers: { 
                "x-idTienda": data.xIdTienda 
            }
        }).then(resp => {

                return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const recuperarCategoriasEliminadas = (data, callback) => {
    return (dispatch, getState) => {
        axios.put(`${Config.BACKEND_ENDPOINT}/categoria/restaurar`,
        { 
            dummy: 'dummy'
        },
        { 
            headers: { 
                "x-idTienda": data.xIdTienda 
            }
        }).then(resp => {

            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const loginPanelTienda = (data, callback) => {
    return (dispatch, getState) => {
        axios.post(`${Config.BACKEND_ENDPOINT}/tienda/login`,data,{ 
            headers: { 
                "x-idTienda": data.xIdTienda 
            } 
        }).then(resp => {

            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

export { 
    obtenerTienda,
    bloquearTienda,
    desbloquearTienda,
    obtenerFacturacion,
    obtenerSinDimensiones,
    obtenerDimensionesExcesivas,
    obtenerDominiosTienda,
    borrarDominioPropioTienda,
    editarDominiosTienda,
    editarEmailTienda,
    editarUltimaFactura,
    desactivarEnvios,
    recuperarCategoriasEliminadas,
    loginPanelTienda
};