import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    withStyles,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    CardHeader,
    Divider,
    IconButton,
    Icon
} from '@material-ui/core';
import { 
    obtenerDominiosTienda,
    editarDominiosTienda,
    borrarDominioPropioTienda
} from '../../../store/actions';
import { 
    ModalBorrar,
    ModalEditar
} from './subobtenerdominios';
import { 
    AlertUI,
    ToggleUI
} from '../../common';
import { Validate, Regex, RegexExtra } from '../../../other';

class ObtenerDominios_ extends Component
{   
    state={
        id_tienda: null,
        data_response: {
           
        },
        loading: {
            tienda: true,
            error: false
        },

        //campos para modal editar
        editar: {
            d_nombre: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Nombre',
                id: 'editar_d_nombre',
                name: 'd_nombre',
                change_param: 'editar',
                regex: Regex.DOMINIO,
                value: '',
                default_value: '',
                placeholder: 'Nombre dominio',
                messages: {
                    error: 'Por favor, ingrese un nombre de dominio válido',
                    error_extra: RegexExtra.DOMINIO
                }
            },
            dominio: {
                auth: true,
                validate: false,
                required: true,
                disabled: true,
                error: false,
                type: 'text',
                label: 'Dominio',
                id: 'editar_dominio',
                name: 'dominio',
                change_param: 'editar',
                regex: Regex.ANYTHING,
                value: '',
                default_value: '',
                placeholder: '',
                messages: {
                    error: '',
                    error_extra: ''
                }
            },
        },

        //modales
        modals: {
            editar: false,
            borrar: false
        },

        forms: {
            editar: false,
            borrar: false
        },

        alert: {
            open: false,
            type: 'error',
            message: ''
        }
    };

    componentDidMount() 
    {
        this.handleChangeId();
    }

    componentDidUpdate() 
    {
        this.handleChangeId();
    }

    refreshData = () => {
        this.setState({
            id_tienda: this.props.id_tienda,
            loading: {
                ...this.state.loading,
                tienda: true,
                error: false
            }
        }, () => {
            this.props.obtenerDominiosTienda({
                xIdTienda: this.state.id_tienda,
            }, (err,resp) => {
                this.setState({
                    data_response: resp.data,
                    loading: {
                        ...this.state.loading,
                        tienda: false,
                        error: (this.state.loading.error || err) ? true : false
                    }
                });
            });
        });        
    }

    handleChangeId = () => {
        if(this.state.id_tienda !== this.props.id_tienda)
        {
            this.setState({
                id_tienda: this.props.id_tienda,
                loading: {
                    ...this.state.loading,
                    tienda: true,
                    error: false
                }
            }, () => {
                this.props.isLoading(true);
                this.props.obtenerDominiosTienda({
                    xIdTienda: this.state.id_tienda,
                }, (err,resp) => {
                    this.props.isLoading(false);
                    this.setState({
                        data_response: resp.data,
                        loading: {
                            ...this.state.loading,
                            tienda: false,
                            error: (this.state.loading.error || err) ? true : false
                        }
                    });
                });
            });
        }
    }

    handleChange = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            }
        },() => {
            Validate.validate_input(this.state[aditional][name],blur,(input) => {
                this.setState({
                    [aditional]: {
                        ...this.state[aditional],
                        [name]: input
                    }
                });
            });
        });
    }

    handleSubmitEditar = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                editar: true
            }
        });

        const d_nombre_value = `${this.state.editar.d_nombre.value}.${this.state.editar.dominio.value}`
        
        if(Validate.validar_formulario(this.state.editar))
        {
            this.props.editarDominiosTienda({
                d_nombre: d_nombre_value,
                xIdTienda: this.state.id_tienda,
            },(err,resp) => {

                if(err)
                {
                    this.setState({
                        forms: {
                            ...this.state.forms,
                            editar: false
                        },
                        alert: {
                            open: true,
                            message: resp.message,
                            type: 'error'
                        }
                    });
                }
                else
                {
                    this.setState({
                        forms: {
                            ...this.state.forms,
                            editar: false
                        },
                        alert: {
                            open: true,
                            message: resp.message,
                            type: 'success'
                        },
                        modals: {
                            ...this.state.modals,
                            editar: false
                        }
                    },() => {
                        let form_obj = Validate.limpiar_formulario(this.state.editar);
                        this.setState({
                            editar: form_obj
                        });
                    });
                    this.refreshData();       
                }
            });
        }
        else
        {
            Validate.validar_formulario_actualizar_obj(this.state.editar,
                (new_form) => {
                    this.setState({
                        editar: new_form,
                        forms: {
                            ...this.state.forms,
                            editar: false
                        },
                        alert: {
                            ...this.state.alert,
                            open: true,
                            message: 'Por favor, revisá tus datos, tenés algunos errores',
                            type: 'error'
                        }
                    });
                }
            );
        }
    }

    handleSubmitBorrar = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                borrar: true
            }
        });

        this.props.borrarDominioPropioTienda({
            xIdTienda: this.state.id_tienda,
        },(err,resp) => {

            this.setState({
                forms: {
                    ...this.state.forms,
                    borrar: false
                },
                alert: {
                    open: true,
                    message: resp.message,
                    type: err ? 'error' : 'success'
                },
                modals: {
                    ...this.state.modals,
                    borrar: err ? true : false
                }
            },() => this.refreshData()); 
        });
    }

    //abre y cierra los modales
    modalHandler = (open,id,aditional) => {
        switch(id) 
        {
            case 'editar':
                if(open)
                {
                    let d_dominio = aditional;
                    Validate.cargar_formulario_sin_nulos(
                        d_dominio,
                        Validate.limpiar_formulario(this.state.editar),
                        (editar_obj) => {
                            this.setState({
                                editar: editar_obj,
                                modals: {
                                    ...this.state.modals,
                                    [id]: open
                                }
                            });
                        }
                    );
                }
                else
                {
                    this.setState({
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
            break;
            default:
                this.setState({
                    modals: {
                        ...this.state.modals,
                        [id]: open
                    }
                });
            break;
        }
    }

    //cierra las alertas
    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }    
 
    render()
    {
        let {classes} = this.props;
        let t_dominios;

        if(this.state.data_response.length>0) 
        {
            const urlComplete = this.state.data_response[0].d_nombre
            // d_nombre = subdominio
            const d_nombre = urlComplete.split('.')[0]
            const d_dominio = urlComplete.replace(`${d_nombre}.`, '')

            const aditional = {
                ...this.state.data_response[0],
                d_nombre: d_nombre,
                dominio: d_dominio
            }

            t_dominios = this.state.data_response.map((dominio,index) => {
                    return (
                        <ListItem divider={index<(this.state.data_response.length-1)} key={dominio.idDominios} className={classes.list_item} >
                            <ListItemText
                                primary={dominio.d_nombre}
                                classes={{root: classes.list_text}}
                                secondary={
                                    <span className={classes.span}>
                                        <span className={classes.span}>{`Pago: ${dominio.d_pago===1 ? 'Sí' : 'No'}`}</span>
                                        <span className={classes.span}>{`Activo: ${dominio.d_activo===1 ? 'Sí' : 'No'}`}</span>
                                    </span>
                                }
                            />
                                <ListItemSecondaryAction>
                                    <ToggleUI show={this.state.data_response.length===1 && dominio.d_pago ===0 && dominio.d_activo ===1}>
                                        <IconButton aria-label="Editar" onClick={(e) => this.modalHandler(true,'editar',aditional)}>
                                            <Icon>edit</Icon>
                                        </IconButton>
                                    </ToggleUI>
                                    <ToggleUI show={dominio.d_pago===1 && (dominio.d_metadata===null || (dominio.d_metadata!==null && dominio.d_metadata.status<10))}>
                                        <IconButton aria-label="Borrar" onClick={(e) => this.modalHandler(true,'borrar',aditional)}>
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </ToggleUI>
                                </ListItemSecondaryAction>
                        </ListItem>
                    );              
            });
        }

        return(
            <Fragment>
                <ToggleUI show={this.state.loading.error}>
                    <Card className={classes.card}>
                        <CardHeader
                            title="Tienda"
                            className={classes.card_header}
                            classes={{action: classes.card_action_button}}
                        />
                        <Divider/>
                        <CardContent className={classes.card_content_no_padding}>
                            <List>
                                <ListItem>
                                    <ListItemText 
                                        primary="No se han encontrado datos"
                                        secondary="Por favor, ingrese un ID Tienda y un filtro"
                                    />
                                </ListItem> 
                            </List>
                        </CardContent>
                    </Card>
                </ToggleUI>
                <ToggleUI show={this.state.loading.error===false && this.state.loading.tienda===false}>
                    <Card className={classes.card}>
                        <CardHeader
                            title="Tienda"
                            className={classes.card_header}
                            classes={{action: classes.card_action_button}}
                        />
                        <Divider/>
                        <ModalEditar
                            editar={this.state.editar}
                            handleSubmit={this.handleSubmitEditar}
                            handleChange={this.handleChange}
                            modalHandler={this.modalHandler}
                            modal_state={this.state.modals.editar}
                            form_state={this.state.forms.editar}
                        />
                        <ModalBorrar
                            handleSubmit={this.handleSubmitBorrar}
                            modalHandler={this.modalHandler}
                            modal_state={this.state.modals.borrar}
                            form_state={this.state.forms.borrar}
                        />
                        <AlertUI
                            open={this.state.alert.open}
                            message={this.state.alert.message}
                            type={this.state.alert.type}
                            handleCloseAlert={this.handleCloseAlert}
                        />
                        <CardContent className={classes.card_content_no_padding}>
                            <List classes={{root: classes.list}}>
                                {t_dominios}
                            </List>
                        </CardContent>
                    </Card>
                </ToggleUI>
            </Fragment>
        );
    }
}

const styles = theme => ({
    span: {
        display: 'block',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    list: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '0',
        '&:last-child': {
            borderBottom: '0px solid transparent'
        }
    },
    list_text: {
        paddingRight: '100px'
    },
    table: {
        minWidth: '100%',
    },    
    card: {
        ...theme.card.card,
        padding: '0px !important'
    },
    card_content: theme.card.card_content,
    card_content_no_padding: {
        ...theme.card.card_content,
        padding: '0px !important',
        overflowX: 'auto'
    }
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
    return {
        obtenerDominiosTienda: (data,callback) => dispatch(obtenerDominiosTienda(data,callback)),
        editarDominiosTienda: (data,callback) => dispatch(editarDominiosTienda(data,callback)),
        borrarDominioPropioTienda: (data,callback) => dispatch(borrarDominioPropioTienda(data,callback))
    }
}

ObtenerDominios_.propTypes = {

    //Función que hace trae el dominio de la tienda ingresada
    obtenerDominiosTienda: PropTypes.func.isRequired,

    //Función que edita el dominio de la tienda ingresada
    editarDominiosTienda: PropTypes.func.isRequired,

    isLoading: PropTypes.func.isRequired,

    //Parámetro de búsqueda que recibe el componente por props
    id_tienda: PropTypes.string.isRequired

};

export const ObtenerDominios = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(ObtenerDominios_));
