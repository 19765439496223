import React from 'react';
import { connect } from 'react-redux';
import { 
    withStyles,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    ListItemIcon,
    Icon
} from '@material-ui/core';
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

const actualizarCategorias = (categorias,parent,nivel) => {
    let is_article = nivel[0].content ? true : false;
    return categorias.map((categoria) => {
        if(!is_article)
        {
            if(categoria.slug===parent)
            {
                return {
                    ...categoria,
                    subcategories: nivel
                };
            }
            else
            {
                return categoria;
            }
        }
        else
        {
            let subcategories = categoria.subcategories.map(subcategory => {
                if(subcategory.slug===parent)
                {
                    return {
                        ...subcategory,
                        articles: nivel
                    };
                }
                else
                {
                    return subcategory;
                }
            });
            
            return {
                ...categoria,
                subcategories: subcategories
            };
        }
    });
}

const ConocimientoList_ = (props) => {
    
    const { conocimiento, modalHandler, handleOrdenar, classes } = props;
    let categorias = conocimiento.knowledge_base_data ? conocimiento.knowledge_base_data : conocimiento;

    const onSortEnd = ({args,parent,nivel}) => {
        const {oldIndex, newIndex} = args;
        if(oldIndex!==newIndex)
        {
            let nivel_ord = arrayMove(nivel, oldIndex, newIndex);

            let new_item = parent===null ? nivel_ord : actualizarCategorias(categorias,parent,nivel_ord);
            handleOrdenar(new_item, nivel_ord, parent);
        }
    }

    const DragHandle = sortableHandle(() => <Icon classes={{root: classes.drag_icon}}>drag_indicator</Icon>);

    const SortableItem = sortableElement(({item,depth}) => {

        return(
        <div key={item.slug} className={depth ? classes.list_content : classes.list_content_root}>
            <ListItem ContainerComponent="div" classes={{root: depth ? classes.list_item : classes.list_item_root}}>
                <ListItemIcon>
                    <DragHandle/>
                </ListItemIcon>
                <ListItemText classes={{root: classes.list_text}} primary={item.title}/>
                <ListItemSecondaryAction>
                    {
                        depth!==2 && (
                            <IconButton aria-label="Agregar" onClick={() => modalHandler(true,'agregar', {item, depth})}>
                                <Icon>add</Icon>
                            </IconButton>
                        )
                    }
                    <IconButton aria-label="Editar" onClick={() => modalHandler(true,'editar', {item, depth})}>
                        <Icon>create</Icon>
                    </IconButton>
                    <IconButton aria-label="Borrar" onClick={() => modalHandler(true,'borrar', {item, depth})}>
                        <Icon>delete</Icon>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            {
                depth===0 && (
                    <SortableContainer onSortEnd={(args) => onSortEnd({args: args, parent: item.slug, nivel: item.subcategories})} useDragHandle>
                        {renderizarCategorias(item.subcategories,depth+1)}
                    </SortableContainer>
                )
            }

            {
                depth===1 && (
                    <SortableContainer onSortEnd={(args) => onSortEnd({args: args, parent: item.slug, nivel: item.articles})} useDragHandle>
                        {renderizarCategorias(item.articles,depth+1)}
                    </SortableContainer>
                )
            }
    
        </div>
    )});

    const SortableContainer = sortableContainer(({children}) => {
        return <List component="div" classes={{root: classes.list}}>{children}</List>;
    });

    const renderizarCategorias = (categorias,depth) => categorias.map((item, index) => (
        <SortableItem key={item.slug} index={index} item={item} depth={depth} />
    ));

    return (
        <SortableContainer onSortEnd={(args) => onSortEnd({args: args, parent: null, nivel: categorias })} useDragHandle>
           {renderizarCategorias(categorias,0)}
        </SortableContainer>
    )
}

const styles = theme => ({
    list: {
        margin: 0,
        padding: 0,
        listStyle: 'none'
    },
    list_item: {
        backgroundColor: '#fff',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '15px',
        marginBottom: '5px',
        position: 'relative',
        '&:before': {
            position: 'absolute',
            top: '50%',
            left: '-11px',
            content: '""',
            width: '10px',
            [theme.breakpoints.up('md')]: {
                width: '20px',
                left: '-21px',
            },
            height: '1px',
            backgroundColor: 'rgba(0, 0, 0, 0.12)'
        }
    },
    list_item_root: {
        backgroundColor: '#fff',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '15px',
        marginBottom: '5px',
        position: 'relative',
    },
    list_content: {
        marginLeft: '10px',
        [theme.breakpoints.up('md')]: {
            marginLeft: '20px',
        },
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '1px',
            height: 'calc(100% - 28px)',
            backgroundColor: 'rgba(0, 0, 0, 0.12)'
        }
    },
    list_content_root: {
        marginLeft: '0px',
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '1px',
            height: 'calc(100% - 28px)',
            backgroundColor: 'rgba(0, 0, 0, 0.12)'
        }
    },
    drag_icon: {
        cursor: 'move'
    },
    list_text: {
        paddingLeft: '0px',
        paddingRight: '150px',
    }
});

const mapStateToProps = (state) => {
    return {
        conocimiento: state.conocimiento
    }
};

const mapDispatchToProps = null;

export const ConocimientoList = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(ConocimientoList_));
