import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    Grid,
    Icon,
    withStyles, 
    Card, 
    CardHeader, 
    CardContent, 
    Divider,
    LinearProgress,
    Typography
 } from '@material-ui/core';
 import { 
    cuponObtenerLeads,
    alianzaObtenerAlianza,
    cuponAbonarLeads
 } from '../../store/actions';
import { 
    PrivateLayout, 
    AlertUI, 
    ModuleTitleUI,
    InputUI,
    SelectUI
} from '../common';
import { 
    LeadsList,
    ModalAbonar
} from './subcomponents';
import { Validate, Config, Regex, RegexExtra } from '../../other';
 
class Leads_ extends Component 
{                                                            
    state={

        overview: {
            leads: 0,
            leads_pagos: 0,
            total_a_pagar: 0,
            total_pagado: 0
        },
        filter: {
            search: {
                auth: false,
                validate: false,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Buscar',
                id: 'search',
                name: 'search',
                change_param: 'filter',
                regex: Regex.ANYTHING,
                value: '',
                default_value: '',
                placeholder: 'Ingrese nombre, email o dominio...',
                messages: {
                    error: '',
                    error_extra: RegexExtra.ANYTHING
                }
            },
            filtro_abonados: {
                validate: false,
                required: true,
                error: false,
                label: 'Filtrar',
                id: 'filtro_abonados',
                name: 'filtro_abonados',
                change_param: 'filter',
                value: -1,
                messages: {
                    error: ''
                },
            }, 
        },
        //datos para el modal marcar abonado
        marcar_abonado:{
            idCuponAlianza: 0,
            idTienda: '',
            t_nombre: ''
        },
        //modales
        modals: {
            marcar_abonado: false,
        },
        //selects
        selects: {
            filtro_abonados: [
                {
                    value: -1,
                    label: 'Todos'
                },
                {
                    value: 0,
                    label: 'Abonados'
                },
                {
                    value: 1,
                    label: 'No abonados'
                },
                {
                    value: 2,
                    label: 'No convertidos'
                }
            ]
        },
        loading: {
            init_cupones: true,
            cupones: true,
            alianzas: true,
            error: false
        },
        forms: {
            marcar_abonado: false,
        },
        alert: {
            open: false,
            type: 'error',
            message: ''
        },
    };

    componentDidMount()
    {
        let idCuponAlianza = this.props.match.params.idCuponAlianza;
        let idAlianzas = this.props.match.params.idAlianzas;
        this.props.cuponObtenerLeads({
            idAlianzas: idAlianzas,
            idCuponAlianza: idCuponAlianza,
        }, (err,resp) => {
            this.setState({
                loading: {
                    ...this.state.loading,
                    cupones: false,
                    error: (this.state.loading.error || err) ? true : false
                }
            });
        });
        this.props.alianzaObtenerAlianza({
            idAlianzas: idAlianzas,
        }, (err,resp) => {
            this.setState({
                loading: {
                    ...this.state.loading,
                    alianzas: false,
                    error: (this.state.loading.error || err) ? true : false
                }
            });
        });
    }

    handleSubmitAbonar = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                marcar_abonado: true
            }
        });

        let form = this.state.marcar_abonado;

        this.props.cuponAbonarLeads({
            idCuponAlianza: form.idCuponAlianza,
            idTienda: form.idTienda
        }, (err,resp) => {
            if(err)
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        marcar_abonado: false
                    },
                    alert: {
                        open: true,
                        message: resp.message,
                        type: 'error'
                    }
                });
            }
            else
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        marcar_abonado: false
                    },
                    alert: {
                        open: true,
                        message: resp.message,
                        type: 'success'
                    },
                    modals: {
                        ...this.state.modals,
                        marcar_abonado: false
                    }
                });
            }
        });
    }
    //abre y cierra los modales
    modalHandler = (open,id,aditional) => {
        switch(id) 
        {
            case 'marcar_abonado':
                if(open)
                {
                    let lead = aditional;
                    this.setState({
                        marcar_abonado: {
                            ...this.state.marcar_abonado,
                            idCuponAlianza: lead.CuponAlianza_idCuponAlianza,
                            idTienda: lead.Tienda_idTienda,
                            t_nombre: lead.t_nombre

                        },
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
                else
                {
                    this.setState({
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
            break;

            default:
            break;
        }
    }

    handleChange = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            }
        },() => {
            Validate.validate_input(this.state[aditional][name],blur,(input) => {
                this.setState({
                    [aditional]: {
                        ...this.state[aditional],
                        [name]: input
                    }
                });
            });
        });
    }

    handleChangeFilter = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;
    
        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                },
            }
        });
    }

    handleChangeSelect = (e,aditional) => {

        let value = Validate.trim_int(e.target.value);
        let name = e.target.name;

        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            },
        });

    }

    //cierra las alertas
    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }

    handlerDashboard = (cupon, valores) => {

        valores.leads_total = cupon.leads.length;
        cupon.leads.forEach(item => {

            if(cupon.ca_tipo===2)
            {
                if(item.f_paga===1 && item.cat_pago ===1)
                {
                    valores.leads_pagos = valores.leads_pagos + 1;
                }
    
                if(item.f_paga===1 && item.cat_pago ===0)
                {
                    valores.total_a_pagar = valores.total_a_pagar + item.f_monto;
                }
    
                if(item.cat_pago ===1)
                {
                    valores.total_pagado = valores.total_pagado + item.f_monto;
                }
            }
        });
    }

    render() 
    {
        let cupon =  this.props.cupon;
        let valores = {
            leads_total: 0,
            leads_pagos: 0,
            total_a_pagar: 0,
            total_pagado: 0
        }
        this.handlerDashboard(cupon, valores);

        const { classes } = this.props;
        return (
            <PrivateLayout 
                loading={this.state.loading.alianzas || this.state.loading.cupones}
                error={this.state.loading.error} 
                url={this.props.match.url}
            >
                <ModalAbonar
                    marcar_abonado={this.state.marcar_abonado}
                    handleSubmit={this.handleSubmitAbonar}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.marcar_abonado}
                    form_state={this.state.forms.marcar_abonado}
                />
                <AlertUI
                    open={this.state.alert.open}
                    message={this.state.alert.message}
                    type={this.state.alert.type}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <ModuleTitleUI 
                    title={this.props.alianza.a_nombre}
                    subtitles={[
                        this.props.cupon.ca_codigo,
                        this.props.cupon.ca_tipo === 1 ? 'Tipo: Cupón de días extra' : 'Tipo: Cupón de monto fijo',
                        this.props.cupon.ca_tipo === 1 ? `Días: ${this.props.cupon.ca_dias}` : `Monto: $${Validate.number_format(this.props.cupon.ca_monto)}`,
                        'Fecha de creación: ' + Validate.utc2local_format(this.props.cupon.ca_fecha,Config.DATE_FORMAT)
                    ]}
                />
                <Grid container spacing={24} classes={{container: classes.stat_container}}>
                    <Grid item md={3} sm={6} xs={6}>
                        <Card className={classes.card}>
                            <CardContent classes={{root: classes.card_content_title}}>
                                <Typography className={classes.stat_title}>
                                    LEADS
                                </Typography>
                                <Typography className={classes.stat_subtitle}>
                                    {valores.leads_total}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={3} sm={6} xs={6}>
                        <Card className={classes.card}>
                            <CardContent classes={{root: classes.card_content_title}}>
                                <Typography className={classes.stat_title}>
                                    LEADS PAGOS
                                </Typography>
                                <Typography className={classes.stat_subtitle}>
                                    {valores.leads_pagos}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={3} sm={6} xs={6}>
                        <Card className={classes.card}>
                            <CardContent classes={{root: classes.card_content_title}}>
                                <Typography className={classes.stat_title}>
                                    TOTAL A PAGAR
                                </Typography>
                                <Typography className={classes.stat_subtitle}>
                                    ${Validate.number_format(valores.total_a_pagar)}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={3} sm={6} xs={6}>
                        <Card className={classes.card}>
                            <CardContent classes={{root: classes.card_content_title}}>
                                <Typography className={classes.stat_title}>
                                    TOTAL PAGADO
                                </Typography>
                                <Typography className={classes.stat_subtitle}>
                                    ${Validate.number_format(valores.total_pagado)}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <div style={{ paddingBottom: 12 }}>
                    <Grid container justify="space-between" spacing={24}>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputUI
                                handleChange={this.handleChangeFilter}
                                input={this.state.filter.search}
                                end_adornment={<Icon>search</Icon>}
                            />
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <SelectUI
                                data={this.state.filter.filtro_abonados}
                                options={this.state.selects.filtro_abonados}
                                handleChangeSelect={this.handleChangeSelect}
                            />
                        </Grid>
                    </Grid>
                </div> 
                <Card className={classes.card_below}>
                    <CardHeader
                        title="Leads"
                        className={classes.card_header}
                        classes={{action: classes.card_action_button}}
                    />
                    <Divider/>
                    <CardContent className={classes.card_content}>
                        {(this.state.loading.cupones) ? <LinearProgress/> : <LeadsList idAlianzas={this.props.match.params.idAlianzas} idCuponAlianza={this.props.match.params.idCuponAlianza} modalHandler={this.modalHandler} filtro={this.state.filter} />} 
                    </CardContent>
                </Card>
            </PrivateLayout>
        );
    }
}

const styles = theme => ({
    card: theme.card.card,
    card_header: theme.card.card_header,
    card_header_title: theme.card.card_header_title,
    card_actions: theme.card.card_actions_public,
    card_content: {
        padding: 0,
        '&:last-child': {
            padding: 0
        }
    },
    card_content_title: {
        ...theme.card.card_content,
        padding: '10px !important'
    },
    stat_container: {
        marginTop: '20px',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '40px'
        }
    },
    stat_title: {
        padding: 0,
        margin: 0,
        color: '#9e9e9e',
        fontSize: '11px',
        textDecoration: 'none',
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.8rem',
        }
    },
    stat_subtitle: {
        padding: 0,
        margin: 0,
        color: '#263238',
        fontSize: '18px',
        textDecoration: 'none',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem',
        }
    },
});

const mapStateToProps = (state) => {
    return {
        cupon: state.leads.length ? state.leads[0] : {leads: []},
        alianza: state.alianzas.length ? state.alianzas[0] : {},
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        cuponObtenerLeads: (data, callback) => dispatch(cuponObtenerLeads(data, callback)),
        alianzaObtenerAlianza: (data, callback) => dispatch(alianzaObtenerAlianza(data, callback)),
        cuponAbonarLeads: (data, callback) => dispatch(cuponAbonarLeads(data, callback))
    }
}

// Leads_.propTypes = {

// };

export const Leads = withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(Leads_));
