import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    Grid,
    Icon,
    withStyles, 
    Card, 
    CardHeader, 
    CardContent, 
    Divider,
    LinearProgress
 } from '@material-ui/core';
 import { 
    obtenerSolicitudes,
    modificarStatus,
    clienteDescargarArchivoVerificacion
 } from '../../../store/actions';
import { 
    PrivateLayout, 
    AlertUI, 
    ModuleTitleUI,
    InputUI
} from '../../common';
import { 
    ModalAprobar, 
    ModalRechazar,
    SolicitudesList 
} from './subcomponents';
import { Validate, Regex, RegexExtra } from '../../../other';
 
class SolicitudesPendientes_ extends Component 
{
    state={
        filter: {
            search: {
                auth: false,
                validate: false,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Buscar',
                id: 'search',
                name: 'search',
                change_param: 'filter',
                regex: Regex.ANYTHING,
                value: '',
                default_value: '',
                placeholder: 'Nombre de cliente o email...',
                messages: {
                    error: '',
                    error_extra: RegexExtra.ANYTHING
                }
            },
        },
        //datos para el modal marcar aprobado
        marcar_aprobado:{
            idCustomer: 0,
            customer_name: '',
            customer_surname: ''
        },
        //datos para el modal marcar rechazado
        marcar_rechazado:{
            idCustomer: 0,
            customer_name: '',
            customer_surname: ''
        },
        loading: {
            solicitudes: true,
            error: false
        },
        //modales
        modals: {
            marcar_aprobado: false,
            marcar_rechazado: false
        },
        forms: {
            marcar_aprobado: false,
            marcar_rechazado: false
        },
        alert: {
            open: false,
            type: 'error',
            message: ''
        },
    };

    componentDidMount()
    {
        this.props.obtenerSolicitudes({
            customer_status: 0,
            Partner_idPartner: 1 
        },(err,resp) => {
            this.setState({
                loading: {
                    ...this.state.loading,
                    solicitudes: false,
                    error: (this.state.loading.error || err) ? true : false
                }
            });
        });
    }

    handleChange = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            }
        },() => {
            Validate.validate_input(this.state[aditional][name],blur,(input) => {
                this.setState({
                    [aditional]: {
                        ...this.state[aditional],
                        [name]: input
                    }
                });
            });
        });
    }

    handleChangeFilter = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;
    
        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                },
            }
        });
    }

    handleSubmitAprobar = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                marcar_aprobado: true
            }
        });

        let form = this.state.marcar_aprobado;

        this.props.modificarStatus({
            idCustomer: form.idCustomer,
            customer_status: 1
        }, (err,resp) => {
            if(err)
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        marcar_aprobado: false
                    },
                    alert: {
                        open: true,
                        message: 'No se pudo actualizar el estado del cliente',
                        type: 'error'
                    }
                });
            }
            else
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        marcar_aprobado: false
                    },
                    alert: {
                        open: true,
                        message: 'Estado actualizado correctamente',
                        type: 'success'
                    },
                    modals: {
                        ...this.state.modals,
                        marcar_aprobado: false
                    }
                });
            }
        });
    }

    handleSubmitRechazar = (e) => {
        e.preventDefault();
        this.setState({
            forms: {
                ...this.state.forms,
                marcar_rechazado: true
            }
        });

        let form = this.state.marcar_rechazado;

        this.props.modificarStatus({
            idCustomer: form.idCustomer,
            customer_status: 4
        }, (err,resp) => {
            if(err)
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        marcar_rechazado: false
                    },
                    alert: {
                        open: true,
                        message: 'No se pudo actualizar el estado del cliente',
                        type: 'error'
                    }
                });
            }
            else
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        marcar_rechazado: false
                    },
                    alert: {
                        open: true,
                        message: 'Estado actualizado correctamente',
                        type: 'success'
                    },
                    modals: {
                        ...this.state.modals,
                        marcar_rechazado: false
                    }
                });
            }
        });
    }

    handleSubmitDescargarArchivo = (e,doc) => {
        e.preventDefault();
        let idVerification = doc.idVerification;
        let idCustomer = doc.Customer_idCustomer;
        let verification_fileExt = doc.verification_file.split(".")[1];
        let verification_fileMimeType = '';

        switch(verification_fileExt)
        {
            case 'jpg':
                verification_fileMimeType = 'image/jpeg';
            break;
            case 'jpeg':
                verification_fileMimeType = 'image/jpeg';
            break;
            case 'png':
                verification_fileMimeType = 'image/png';
            break;
            case 'pdf':
                verification_fileMimeType = 'application/pdf';
            break;
            default: break;
        }

        this.props.clienteDescargarArchivoVerificacion({
            idVerification: idVerification,
            idCustomer: idCustomer
        },(err,resp) => {
            if(err)
            {
                this.setState({
                    alert: {
                        open: true,
                        message: 'No se pudo descargar el archivo',
                        type: 'error'
                    }
                });
            }
            else
            {
                let blob = new Blob([resp], {type: verification_fileMimeType});
                let objectUrl = URL.createObjectURL(blob);
                window.open(objectUrl);
            }
        });
    }

    //abre y cierra los modales
    modalHandler = (open,id,aditional) => {
        switch(id) 
        {
            case 'marcar_aprobado':
                if(open)
                {
                    let solicitud = aditional;
                    this.setState({
                        marcar_aprobado: {
                            ...this.state.marcar_aprobado,
                            idCustomer: solicitud.idCustomer,
                            customer_name: solicitud.customer_name,
                            customer_surname: solicitud.customer_surname

                        },
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
                else
                {
                    this.setState({
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
            break;

            case 'marcar_rechazado':
                if(open)
                {
                    let solicitud = aditional;
                    this.setState({
                        marcar_rechazado: {
                            ...this.state.marcar_rechazado,
                            idCustomer: solicitud.idCustomer,
                            customer_name: solicitud.customer_name,
                            customer_surname: solicitud.customer_surname

                        },
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
                else
                {
                    this.setState({
                        modals: {
                            ...this.state.modals,
                            [id]: open
                        }
                    });
                }
            break;

            default:
            break;
        }
    }

    //cierra las alertas
    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }

    render() 
    {
        const { classes } = this.props;
        return (
            <PrivateLayout 
                error={this.state.loading.error} 
                url={this.props.match.url}
            >
                <ModalAprobar
                    marcar_aprobado={this.state.marcar_aprobado}
                    handleSubmit={this.handleSubmitAprobar}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.marcar_aprobado}
                    form_state={this.state.forms.marcar_aprobado}
                />
                <ModalRechazar
                    marcar_rechazado={this.state.marcar_rechazado}
                    handleSubmit={this.handleSubmitRechazar}
                    modalHandler={this.modalHandler}
                    modal_state={this.state.modals.marcar_rechazado}
                    form_state={this.state.forms.marcar_rechazado}
                />
                <AlertUI
                    open={this.state.alert.open}
                    message={this.state.alert.message}
                    type={this.state.alert.type}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <ModuleTitleUI 
                    title="Solicitudes de alta pendientes"
                    subtitles={[
                        'Aquí podrás ver todas las solicitudes de alta pendientes'
                    ]}
                />
                <div style={{ paddingBottom: 12 }}>
                    <Grid container spacing={24}>
                        <Grid item md={4} sm={6} xs={12}>
                            <InputUI
                                handleChange={this.handleChangeFilter}
                                input={this.state.filter.search}
                                end_adornment={<Icon>search</Icon>}
                            />
                        </Grid>
                    </Grid>
                </div> 
                <Card className={classes.card_below}>
                    <CardHeader
                        title="Solicitudes pendientes"
                        className={classes.card_header}
                        classes={{action: classes.card_action_button}}
                    />
                    <Divider/>
                    <CardContent className={classes.card_content}>
                        {(this.state.loading.solicitudes) ? <LinearProgress/> : <SolicitudesList modalHandler={this.modalHandler} handleSubmitDescargarArchivo={this.handleSubmitDescargarArchivo} filtro={this.state.filter.search.value} />} 
                    </CardContent>
                </Card>
            </PrivateLayout>
        );
    }
}

const styles = theme => ({
    card: theme.card.card,
    card_header: theme.card.card_header,
    card_header_title: theme.card.card_header_title,
    card_actions: theme.card.card_actions_public,
    card_content: {
        padding: 0,
        '&:last-child': {
            padding: 0
        }
    },
});

const mapStateToProps = (state) => {
    return {
        solicitudes: state.solicitudes,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        obtenerSolicitudes: (data,callback) => dispatch(obtenerSolicitudes(data,callback)),
        modificarStatus: (data,callback) => dispatch(modificarStatus(data,callback)),
        clienteDescargarArchivoVerificacion: (data,callback) => dispatch(clienteDescargarArchivoVerificacion(data,callback)),
    }
}

// SolicitudesPendientes_.propTypes = {
    
// };

export const SolicitudesPendientes = withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(SolicitudesPendientes_));
