import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Icon,
    withStyles
} from '@material-ui/core';
import { CardModuleUI } from '../../common';
import { Validate, Config } from '../../../other';

const TiendasList_ = (props) => {
    
    let { 
        tiendas,
        classes, 
    } = props;

    if(tiendas.length)
    {
        let body = tiendas.map((tienda,idx) => {

            let table_class = (tiendas.length-1)===idx ? classes.table_cell_last : classes.table_cell;
            
            return (
                <TableRow hover key={idx}>
                    <TableCell classes={{root: table_class}}>{ tienda.t_nombre }</TableCell>
                    <TableCell classes={{root: table_class}}>{ tienda.t_email }</TableCell>
                    <TableCell classes={{root: table_class}}>{ Validate.utc2local_format(tienda.t_fecha_creacion, Config.DATE_FORMAT) }</TableCell>
                    <TableCell classes={{root: table_class}}>{ tienda.t_activa === 1 ? 'No' : 'Sí' }</TableCell>
                    <TableCell classes={{root: table_class}}>
                        <a href={'https://'+tienda.dominio_activo.d_nombre} target="_blank" rel="noopener noreferrer" className={classes.link_order}>
                            <IconButton aria-label="Link" >
                                <Icon style={{fontSize:'1rem'}}>open_in_new</Icon>
                            </IconButton>
                        </a>
                    </TableCell>
                </TableRow>
            );
        });

        return (
            <CardModuleUI disable_padding>
                <Table className={classes.table} padding="dense">
                    <TableHead>
                        <TableRow>
                            <TableCell>Tienda</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Fecha de creación</TableCell>
                            <TableCell>Bloqueada</TableCell>
                            <TableCell>Link</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {body}
                    </TableBody>
                </Table>
            </CardModuleUI>
        );
    }
    else
    {
        return (
            <CardModuleUI>
                <Typography className={classes.list_item_title}>
                    No se han encontrado tiendas
                </Typography>
            </CardModuleUI>
        );
    }
}

const styles = theme => ({
    table: {
        minWidth: '100%',
    },    
    list_item_title: {
        fontSize: '1rem',
        margin: 0,
        padding: 0
    },
    table_cell: {
    },
    table_cell_last: {
        borderBottom: '0px solid transparent'
    },
    link_order: {
        fontWeight: '700',
        color: 'inherit'
    }
});

const mapStateToProps = (state) => {
    return {
        tiendas: state.tiendas
    }
};

const mapDispatchToProps = null;

TiendasList_.propTypes = {
    tiendas: PropTypes.array.isRequired
};

export const TiendasList = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(TiendasList_));