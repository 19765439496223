import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    IconButton,
    Icon,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    withStyles
} from '@material-ui/core';
import { Validate } from '../../../../../other';

const LiquidacionList_ = (props) => {
    let {cupones, classes, modalHandler, filtro} = props;
    const renderizarLista = (cupones,modalHandler,classes) => {
        if(cupones.length>0)
        {
            return cupones.filter( cupones => {
                let {a_nombre,a_email,a_cbu} = cupones.alianza;
                let ca_codigo = cupones.ca_codigo;
                let busqueda = filtro.toLowerCase();
                if(
                    (a_nombre !== null && a_nombre.toLowerCase().includes(busqueda))    ||
                    (a_email !== null && a_email.toLowerCase().includes(busqueda))      ||
                    (ca_codigo !== null && ca_codigo.toLowerCase().includes(busqueda))  ||
                    (a_cbu !== null && a_cbu.includes(busqueda))
                )
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }).map((cupon,index) => {
                return (
                    <ListItem divider={index<(cupones.length-1)} key={cupon.idCuponAlianza} className={classes.list_item} >
                        <ListItemText
                            primary={`Alianza: ${cupon.alianza.a_nombre} (${cupon.ca_codigo})`}
                            classes={{root: classes.list_text}}
                            secondary={
                                <span className={classes.span}>
                                    <span className={classes.span}><strong>Email:</strong> {Validate.is_falsy(cupon.alianza.a_email) ? 'N/A' : cupon.alianza.a_email}</span>
                                    <span className={classes.span}><strong>CBU:</strong> {Validate.is_falsy(cupon.alianza.a_cbu) ? 'N/A' : cupon.alianza.a_cbu}</span>
                                    <span className={classes.span}><strong>Razón social:</strong> {Validate.is_falsy(cupon.alianza.a_razon_social) ? 'N/A' : cupon.alianza.a_razon_social}</span>
                                    <span className={classes.span}><strong>Documento:</strong> {Validate.is_falsy(cupon.alianza.a_documento) ? 'N/A' : cupon.alianza.a_documento}</span>
                                    <span className={classes.span}><strong>Cantidad de leads a pagar:</strong> {cupon.ids.length}</span>
                                    <span className={classes.span}><strong>Monto total:</strong> ${Validate.number_format(cupon.monto)}</span>
                                </span>
                            }
                        />
                        <ListItemSecondaryAction>
                            <IconButton aria-label="Marcar abonado" onClick={(e) => modalHandler(true,'marcar_abonado',cupon)}>
                                <Icon>check</Icon>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            });
        }
        else
        {
            return (
                <ListItem>
                    <ListItemText 
                        primary="No se han encontrado cupones"
                    />
                </ListItem>
            );
        }
    }

    return (
        <List classes={{root: classes.list}}>
            {renderizarLista(cupones,modalHandler,classes)}
        </List>
    );
}

const styles = theme => ({
    span: {
        display: 'block',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    list: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '0',
        '&:last-child': {
            borderBottom: '0px solid transparent'
        }
    },
    list_text: {
        paddingRight: '100px'
    }
});

const mapStateToProps = (state) => {
    return {
        cupones: state.cupones,
    };
}

const mapDispatchToProps = null;

LiquidacionList_.propTypes = {
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    filtro: PropTypes.string.isRequired

};

export const LiquidacionList = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(LiquidacionList_));
