/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const clientesEmprepackReducer = (state = initState,action) => {
    let solicitudes;
    switch(action.type)
    {
        case Reducers.SOLICITUDES_GUARDAR:

            solicitudes = action.data;     
            return solicitudes;

        break;

        case Reducers.STATUS_MODIFICADO: 

            let status_modificado = action.data;
            solicitudes = state.filter((solicitud) => {
                return solicitud.idCustomer !== status_modificado.idCustomer;
            });   
        return solicitudes;

        break;

        break;
        
        case Reducers.AUTH_UNSET:

            return initState;

        break;

        default: 

            return state;

        break;
    }
}

export { clientesEmprepackReducer };