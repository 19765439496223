/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const baseConocimientoReducer = (state = initState,action) => {
    let conocimientos;

    switch(action.type)
    {
        case Reducers.CONOCIMIENTO_GUARDAR:

            conocimientos = {
                ...action.data,
                knowledge_base_data: action.data.knowledge_base_data
            };
            
            return conocimientos;

        break;

        case Reducers.CONOCIMIENTO_ORDENAR:

            conocimientos = {
                ...state,
                knowledge_base_data: action.data
            };

            return conocimientos;

        break;
        
        case Reducers.AUTH_UNSET:

            return initState;

        break;

        default: 

            return state;

        break;
    }
}

export { baseConocimientoReducer };