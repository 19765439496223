import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ModalUI, InputUI, SelectUI, ToggleUI } from '../../common';

const ModalAgregar = (props) => {

    let { 
        selects,
        agregar, 
        handleSubmit,
        handleChange, 
        modalHandler,
        handleChangeSelect,
        modal_state,
        form_state
    } = props;
    
    return (
        <ModalUI 
            open={modal_state}
            id="agregar"
            title="Agregar nuevo cupón alianza"
            modalHandler={modalHandler}
            aditional_param="agregar"
            handleSubmit={handleSubmit}
            button_label="Agregar"
            button_disabled={false}
            button_loading={form_state}
        >
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <InputUI
                        handleChange={handleChange}
                        input={agregar.ca_codigo}
                    />
                    <SelectUI
                        data={agregar.ca_tipo}
                        options={selects.ca_tipo}
                        handleChangeSelect={handleChangeSelect}
                    />
                    <ToggleUI show={agregar.ca_tipo.value===1}>
                        <InputUI
                            handleChange={handleChange}
                            input={agregar.ca_dias}
                        />
                    </ToggleUI>
                    <ToggleUI show={agregar.ca_tipo.value===2}>
                        <InputUI
                            handleChange={handleChange}
                            input={agregar.ca_monto}
                        />
                    </ToggleUI>
                </Grid>
            </Grid>
        </ModalUI>
    );
}

ModalAgregar.propTypes = {

    //The form submit function
    //Called: handleSubmit(event,id)
    handleSubmit: PropTypes.func.isRequired,

    //Called: handleChange(event,id)
    handleChange: PropTypes.func.isRequired,
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    //true if the modal is open
    modal_state: PropTypes.bool.isRequired,

    //true if the modal is open
    form_state: PropTypes.bool.isRequired,
    
    handleChangeSelect: PropTypes.func.isRequired,

    selects: PropTypes.shape({
            ca_tipo: PropTypes.arrayOf( 
                PropTypes.shape ({
                    value:          PropTypes.number.isRequired,
                    label:          PropTypes.string.isRequired
                })
            )
    }),

    //The object initialized in state 
    agregar: PropTypes.shape({
        ca_codigo: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            disabled:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            type:           PropTypes.string.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            regex:          PropTypes.instanceOf(RegExp).isRequired,
            value:          PropTypes.string.isRequired,
            default_value:  PropTypes.string.isRequired,
            placeholder:    PropTypes.any,  
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
                error_extra: PropTypes.string.isRequired
            })
        }),
        ca_tipo: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            value:          PropTypes.number.isRequired,
            default_value:  PropTypes.number.isRequired,
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired
            })
        }),
        ca_dias: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            disabled:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            type:           PropTypes.string.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            regex:          PropTypes.instanceOf(RegExp).isRequired,
            value:          PropTypes.any.isRequired,
            default_value:  PropTypes.any.isRequired,
            placeholder:    PropTypes.any,  
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
                error_extra: PropTypes.string.isRequired
            })
        }),
        ca_monto: PropTypes.shape({
            auth:           PropTypes.bool.isRequired,
            validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
            required:       PropTypes.bool.isRequired, 
            disabled:       PropTypes.bool.isRequired, 
            error:          PropTypes.bool.isRequired,
            type:           PropTypes.string.isRequired,
            label:          PropTypes.string.isRequired,
            id:             PropTypes.string.isRequired,
            name:           PropTypes.string.isRequired, 
            change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
            regex:          PropTypes.instanceOf(RegExp).isRequired,
            value:          PropTypes.any.isRequired,
            default_value:  PropTypes.any.isRequired,
            placeholder:    PropTypes.any,  
            messages:       PropTypes.shape({
                error:  PropTypes.string.isRequired,
                error_extra: PropTypes.string.isRequired
            })
        })
    })
};

export {ModalAgregar};
