import React from 'react';
import { Typography, Grid, withStyles } from '@material-ui/core';

const NextActionsUI_ = (props) => {

    let { title, buttons, classes, social_actions=[] } = props;

    let html = '';

    if(social_actions.length)
    {
        html = social_actions.map((social_action,idx) => <Grid item key={idx}>{social_action}</Grid>);
        html = (
            <Grid container justify="center" alignItems="center" spacing={24}>
                {html}
            </Grid>
        );
    }

    return (
        <Grid container direction="row" justify="center" alignItems="center" spacing={24} className={classes.module_box}>
            <Grid item xs={12}>
                <Typography variant="h3" align="center" className={classes.module_title} gutterBottom>
                    {title}
                </Typography>
            </Grid>
            {html}
            <Grid item xs={12} className={classes.button_area}>
                {
                    buttons.map((button,key) => {
                        return (
                            <Grid container direction="row" justify="center" alignItems="center" spacing={24} key={key}>
                                <Grid item md={5} sm={8} xs={12} align="center">
                                    {button}
                                </Grid>
                            </Grid>
                        );
                    })
                }
            </Grid>
        </Grid>
    );
}

const styles = theme => ({
    module_box: {
        marginTop: '30px'
    },
    button_area: {
        marginTop: '20px'
    },
    module_title: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '1.4rem',
        lineHeight: '1.4rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.7rem',
            lineHeight: '1.7rem',
        }
    },
});

export const NextActionsUI = withStyles(styles)(NextActionsUI_);

