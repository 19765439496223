import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    Grid,
    withStyles
 } from '@material-ui/core';
import { 
    PrivateLayout, 
    AlertUI, 
    ModuleTitleUI,
    ButtonUI, 
    InputUI,
    SelectUI
} from '../common';
import { 
    FilterSwitch
} from './subcomponents';
import { Validate, Regex, RegexExtra } from '../../other';
 
class Administracion_ extends Component 
{
    state={
        filter: {
            id_tienda: {
                auth: false,
                disabled: false,
                validate: true,
                required: true,
                error: false,
                type: 'number',
                label: 'ID Tienda',
                id: 'id_tienda',
                name: 'id_tienda',
                change_param: 'filter',
                regex: Regex.NUMERO_ENTERO_POSITIVO,
                value: '',
                default_value: '',
                placeholder: 'Ingrese un ID Tienda...',
                messages: {
                    error: 'Por favor, ingrese un ID válido',
                    error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO
                }
            },
            filtro_tienda: {
                validate: false,
                required: true,
                error: false,
                label: 'Filtrar',
                id: 'filtro_tienda',
                name: 'filtro_tienda',
                change_param: 'filter',
                value: -1,
                messages: {
                    error: ''
                },
            },
        },
        selects: {
            datos_tienda: [
                {
                    value: -1,
                    label: 'Seleccione un filtro'
                },
                {
                    value: 0,
                    label: 'Obtener datos generales de tienda'
                },
                {
                    value: 1,
                    label: 'Obtener facturación'
                },
                {
                    value: 2,
                    label: 'Productos sin dimensiones'
                },
                {
                    value: 3,
                    label: 'Productos con dimensiones excesivas'
                },
                {
                    value: 4,
                    label: 'Obtener dominios'
                },
                {
                    value: 5,
                    label: 'Desactivar un medio de envío'
                },
                {
                    value: 6,
                    label: 'Recuperar categorías eliminadas'
                }
            ]
        },
        loading: {
            init_alianzas: true,
            tienda: true,
            error: false
        },
        //modales
        modals: {
            agregar: false,
            editar: false,
            borrar: false,
        },
        forms: {
            agregar: false,
            editar: false,
            borrar: false,
        },
        alert: {
            open: false,
            type: 'error',
            message: ''
        },
        datos_select: {
            id_tienda: '',
            filtro_tienda: -1
        },
        button_loading: false,
    };

    obtenerDatosFiltro = (id_tienda_value, filtro_tienda_value) => {
        this.setState({
            datos_select:{
                ...this.state.datos_select,
                id_tienda: id_tienda_value,
                filtro_tienda: filtro_tienda_value
            },
        });
    }

    handleChange = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;
        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            }
        },() => {
            Validate.validate_input(this.state[aditional][name],blur,(input) => {
                this.setState({
                    [aditional]: {
                        ...this.state[aditional],
                        [name]: input
                    }
                });
            });
        });
    }

    handleChangeFilter = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;
        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                },
            }
        });
    }
    
    handleChangeSelect = (e,aditional) => {

        let value = Validate.trim_int(e.target.value);
        let name = e.target.name;
        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            },
        });

    }

    //cierra las alertas
    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }

    isLoading = (is_loading) => {
        this.setState({
            button_loading: is_loading,
        });
    }

    render() 
    {
        return (
            <PrivateLayout 
                error={this.state.loading.error} 
                url={this.props.match.url}
            >
                <AlertUI
                    open={this.state.alert.open}
                    message={this.state.alert.message}
                    type={this.state.alert.type}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <ModuleTitleUI 
                    title="Administración"
                    subtitles={[
                        'Aquí podrás administrar las tiendas'
                    ]}
                />
                <div style={{ paddingBottom: 12 }}>
                    <Grid container  alignItems="center" spacing={24}>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputUI
                                handleChange={this.handleChange}
                                input={this.state.filter.id_tienda}
                            />
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <SelectUI
                                data={this.state.filter.filtro_tienda}
                                options={this.state.selects.datos_tienda}
                                handleChangeSelect={this.handleChangeSelect}
                            />
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <ButtonUI
                                type="callf"
                                label="Obtener"
                                size="large"
                                isLoading={this.state.button_loading}
                                onClickFunc={() => this.obtenerDatosFiltro(this.state.filter.id_tienda.value, this.state.filter.filtro_tienda.value)}
                            />
                        </Grid>
                    </Grid>
                </div> 
                <FilterSwitch id_tienda={this.state.datos_select.id_tienda} filtro_tienda={this.state.datos_select.filtro_tienda} isLoading={this.isLoading} button_loading={this.state.button_loading}/>
            </PrivateLayout>
        );
    }
}

const styles = theme => ({
    card: theme.card.card,
    card_header: theme.card.card_header,
    card_header_title: theme.card.card_header_title,
    card_actions: theme.card.card_actions_public,
    card_content: {
        padding: 0,
        '&:last-child': {
            padding: 0
        }
    }
});

const mapStateToProps = null;

const mapDispatchToProps = null;

// Administracion_.propTypes = {

// };

export const Administracion = withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(Administracion_));
