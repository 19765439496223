import { combineReducers } from 'redux';
import { 
    authReducer,
    alianzasReducer,
    cuponAlianzaReducer,
    cuponLeadsReducer,
    clientesEmprepackReducer,
    buscarTiendaReducer,
    baseConocimientoReducer
} from './';

const rootReducer = combineReducers({
    auth: authReducer,
    alianzas: alianzasReducer,
    cupones: cuponAlianzaReducer,
    leads: cuponLeadsReducer,
    solicitudes: clientesEmprepackReducer,
    tiendas: buscarTiendaReducer,
    conocimiento: baseConocimientoReducer
});

export default rootReducer;

